export function companyReducer(state = "",{
    type,
    payload
}){
    
    switch(type){
        case "NEW_COMPANY":            
        case "GET_COMPANY":            
        case "UPDATE_COMPANY":            
            let list = state === "" ? payload : Object.assign(payload, state);
            list[payload.company_id].mainPhoto = list[payload.company_id].company_logo ? "/shop/app/"+ list[payload.company_id].company_logo : ""
            return list
        case "SET_COMPANY_LOGO":
            let company = state[payload.companyID]          
                company.logo = payload.URI
            
            return {
                ...state, 
                [payload.companyID] : company
            }

        default:
            return state;
    }
    
}