export function orderRequest(state = {
    fetching:false,
    fetched: false,
    error:null,
},{
    type,
    payload
}){
    
    switch(type){
        case "CREATE_ORDER_START":           
        case "FETCH_ORDERS_START":                                 
        case "UPDATE_ORDER_START":                                 
            return {
                ...state, 
                fetching:true,
            }        
        case "CREATE_ORDER_FULFILLED":           
        case "FETCH_ORDERS_FULFILLED":                                 
        case "UPDATE_ORDER_FULFILLED":                                 
            return {
                ...state,
                fetching:false, 
                fetched:true, 
                error: null
            }    
        case "CREATE_ORDER_ERROR":
        case "FETCH_ORDERS_ERROR":
        case "UPDATE_ORDER_ERROR":
            return {
                ...state,
                fetching:false, 
                error:payload
            }
        default:
            return state;
    }
    
}