import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';

import Basic from '../../container/layouts/Basic';
import { cleanCart, refreshCart } from '../../store/actions/cartAction'
import { newOrder } from '../../store/actions/orderAction'

import withStyles from '@material-ui/core/styles/withStyles';
import {
	CssBaseline,Paper,Button,Typography,Grid,Card,CardContent,Box
} from "@material-ui/core";

import Review from './Review';
import Notif from '../common/Notif';
import ValidPayment from "./ValidPayment";
import { SuccessPaymentCheck, SuccessPaymentTransfer, SuccessPaymentCB } from "./SuccessPayment";
import { isEmpty } from 'lodash';
import PaymentsMethods from './PaymentsMethods';


const methods = {
	"check": "Chèque",
	"transfer": "Virement",
	"CB": "Carte bleue"
}
const styles = theme => ({
	appBar: {
		position: 'relative',
	},
	layout: {
		width: 'auto',
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
		[theme.breakpoints.up(600 + theme.spacing(4))]: {
		// width: 600,
		marginLeft: 'auto',
		marginRight: 'auto',
		},
	},
	paper: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(3),
		padding: theme.spacing(2),
		[theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
		marginTop: theme.spacing(6),
		marginBottom: theme.spacing(6),
		padding: theme.spacing(3),
		},
	},
	stepper: {
		padding: `${theme.spacing(3)}px 0 ${theme.spacing(1) * 5}px`,
	},
	buttons: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
	button: {
		marginTop: theme.spacing(3),
		marginLeft: theme.spacing(1),
	},
	// card: {
		
	// },
	cardSelected: {
		background: "lightblue"
	},
	group: {
		flexDirection: 'column',
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
	leftCard:{
		background: 'whitesmoke'
	}
});
const maxTimer = (10 * 60 * 1000) // 10 minutes
class Checkout extends React.Component {
state = {
	url : "",
	notif: {
		open: false,
		variant:"",
		message: "",
	},
	disabled:true,
	subTotal:0,
	restToPay:0,
	amount_credit:0,
	article_list:[],
	formatOrder:{},
	paymentType: "",
	timeLeft: maxTimer,
	page: "CHOICE",
	trID: ""
};
componentDidMount = async () => {
	const ts_start = new Date()

	if(isEmpty(this.props.CART) ) {
		this.props.history.push("/cart")
		return
	}

	let infos = await this.getProductList()
	let article_list = infos[0]

	console.log('=============CDM CHECKOUT =============');
	console.log({article_list});
	console.log('====================================');

	let ts_end = ts_start.getTime() + maxTimer

	this.timer = setInterval(() => {
		this.countDown(ts_end)
	}, 1000);
	
}
// // Fait un check final pour vérifier en cas de problème avec le timer
// lastCheckBeforePayment = async () => {
// 	//Request the backend that the payment could be done.
// 	let infos = await this.getProductList()
// 	let article_list = infos[0]

// 	// request to back to get cart projects info
// 	let res = await axios.post("/payments/check/last", article_list)
// 	console.log("CDM CHECKOUT ", {res});

// 	if(res.data.authorized === false || res.error) { // montant < 20€ || ts expired
// 		this.props.history.push("/cart")
// 	}

// 	if(!res.data.validate){
// 		this.props.refreshCart(res.data.cart_list)
// 		// this.props.refreshProjects(res.data.project_list)		
// 		this.notif("info","Votre liste a été modifiée")	
// 	} else {
// 		this.setState({page: "VALID"})
// 	}
	
// }
componentWillUnmount = async () => {
	// await axios.get("/payments/uncheck")
	clearInterval(this.timer) 
	clearTimeout(this.timeout) 
}
countDown(end) {
	// Remove one second, set state so a re-render happens.
	var dt = new Date(end) 
	let timeLeft = dt - Date.now();
	if (isNaN(timeLeft) === false) {
		this.setState({
		timeLeft: timeLeft,
		});
	}
		
		// Check if we're at zero.
		if (timeLeft <= 0) { 
		clearInterval(this.timer);
		this.notif("error", "Votre paiement a expiré")
		this.timeout = setTimeout(() => {
			this.props.history.push("/cart")
		}, 2500)
		}
	}
getProductList = async() => {
	const { CART, USER } = this.props;

	let article_list = [] 
	let formatOrder = { orders: []}
	let total = 0

	Object.keys(CART).forEach( seller => {
		let sellerTotal = 0
		let order = { 
			order_status : "WAITING",
			nature : "order",
			country : this.props.country,
			products: [],
			account_id: seller,
		}
		Object.values(CART[seller]).forEach( item => {
			
			console.log("CHECKOUT is unavailable", item.unavailable, { item } )
			if ( !item.unavailable ){
				article_list.push({
					quantity: item.quantity,
					product_id: item.product.id
				})
				order.products.push({
					quantity: item.quantity,
					product_id: item.product.id
				})
				sellerTotal += item.product.prix_venteTTC * item.quantity
				total += item.product.prix_venteTTC * item.quantity
			}
		})
		order.total_price = sellerTotal 
		formatOrder.orders.push(order)
	})

	console.table(article_list)
	this.setState({
		article_list, subTotal: total, formatOrder
	})
		
	return [ article_list, total ]
}

handleCloseNotif = (event, reason) => {
	if (reason === 'clickaway') {
		return;
	}

	this.setState({ 
		notif: {
			open: false,
			variant:"",
			message: "",
		}
	});
};
notif = (variant, message) => {
	this.setState({
		notif:{
			open: true,
			variant,
			message
		}
	})
}
setPage = (page, trID = "") => {
	console.log('=============SETPAGE=====================');
	console.log({trID});
	console.log('====================================');
	this.setState({
		trID,
		page,
	})
}
submitOrder = async () => {
	let data = Object.assign({}, this.state.formatOrder)
	data.country = this.props.country
	data.total_price = this.state.subTotal

	console.log('====================================');
	console.log({data});
	console.log('====================================');

	
	await this.props.newOrder(data)

	// setTimeout(() => {
		this.setPage("SUCCESS_PAYMENT");
	// }, 1500);

	this.notif("success", "Commande enregistrée")

}

render() {
	console.log('=========CHECKOUT STATE=================');
	console.log(this.state);
	console.log('====================================');

	const { classes } = this.props;
	const { variant, open, message} = this.state.notif
	const minutes = new Date(this.state.timeLeft).getMinutes()
	const secs = new Date(this.state.timeLeft).getSeconds()

	return (
		<Basic noBanner >
			{(open && variant && message) && 
			<Notif
				open={open}
				onClose={this.handleCloseNotif}
				variant={variant}
				message={message}
			/>
			}
			<CssBaseline />
			<main className={classes.layout} style={{marginTop: '80px'}} >
				<Paper className={classes.paper}>
					<Typography component="h1" variant="h4" align="center" gutterBottom>
						Paiement {this.state.page === "SUCCESS_PAYMENT" && "Validé"}
					</Typography>
					<hr/>
					{this.state.page !== "SUCCESS_PAYMENT" &&
					<Box component="div" m={2} gutterBottom align="center">
						Votre panier expirera dans {minutes} minute{minutes > 1 && "s"} {secs < 10 && "0"}{secs} 
					</Box>
					}
					<hr/>
					<br/>
					{this.state.page === "VALID" &&
						<ValidPayment 
							{...this.props} 
							{...this.state} 
							handleClose={ () => this.setState({page : "CHOICE"}) } 
							notif={this.notif}
							getProductList={this.getProductList}
							setPage={this.setPage}
						/>
					}
					{this.state.page === "SUCCESS_PAYMENT" && 
						// (this.state.paymentType === "CB" || this.state.paymentType === "credit") &&
						<SuccessPaymentCB
							{...this.props} 
							{...this.state} 
							handleClose={ () => this.setState({page : "CHOICE"}) } 
							notif={this.notif}
							getProductList={this.getProductList}
							setPage={this.setPage}
						/>
					}
					{/* {this.state.page === "SUCCESS_PAYMENT" && this.state.paymentType === "check" &&
						<SuccessPaymentCheck 
							{...this.props} 
							{...this.state} 
							handleClose={ () => this.setState({page : "CHOICE"}) } 
							notif={this.notif}
							getProductList={this.getProductList}
							setPage={this.setPage}
						/>
					}
					{this.state.page === "SUCCESS_PAYMENT" && this.state.paymentType === "transfer" &&
						<SuccessPaymentTransfer 
							{...this.props} 
							{...this.state} 
							handleClose={ () => this.setState({page : "CHOICE"}) } 
							notif={this.notif}
							getProductList={this.getProductList}
							setPage={this.setPage}
						/>
					} */}
					{this.state.page === "CHOICE" &&
					<Fragment>
						<Grid container spacing={6} m={2}>
							<Grid item xs={12} sm={6} >

								<Review 
									CART={this.props.CART} 
									subTotal={this.state.subTotal}
								/>
								<hr/>

							</Grid>
							<Grid item xs={12} sm={6} className={classes.leftCard}>
								<PaymentsMethods {...this.state} {...this.props} />
							</Grid>
						</Grid>
						<br/>
						<br/>
					
						<div className={classes.buttons}>
							{/* {(this.state.paymentType === "credit" && !this.state.disabled) && 
								<Button
									variant="contained"
									color="primary"
									onClick={() => { this.lastCheckBeforePayment()}}
									className={classes.button}
									>
									<Typography variant="body1" gutterBottom >Valider</Typography>
								</Button>
							} */}
							{/* {(this.state.paymentType && this.state.paymentType !== "credit" && !this.state.disabled) &&  */}
								<Button
									variant="contained"
									color="primary"
									onClick={this.submitOrder}
									className={classes.button}
									>
									<Typography variant="body1" gutterBottom >Payer</Typography>
									{/* <Typography variant="body1" gutterBottom >Régler par {methods[this.state.paymentType]}</Typography> */}
								</Button>
							{/* } */}
							<Button								
								className={classes.button}
								onClick={ () => this.props.history.push("/cart")}
							>
								<Typography variant="body1" gutterBottom >Annuler</Typography>
							</Button>
						</div>
					</Fragment>
					}
					
				</Paper>
			</main>


			
		</Basic>
		);
	}
}

Checkout.propTypes = {
	classes: PropTypes.object.isRequired,
};

function mapStateToProps(state){
	return {
		CART: state.cart,
		USER: state.auth.userInfo 
	}
}

function mapDispatchToProps(dispatch){
return bindActionCreators({
	cleanCart,
	refreshCart,
	newOrder,
}, dispatch)
}
export default connect( mapStateToProps, mapDispatchToProps )(withStyles(styles)(Checkout));