import React, { Component } from 'react';
import { isEmpty, size } from 'lodash';
import {
    Button,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableFooter,
    TablePagination,
    IconButton,
} from '@material-ui/core';

import { MoreVert } from '@material-ui/icons';

import Pagination from '../../../container/MUI/Pagination';
import UserForm from './UserForm';

class UsersList extends Component {
    state = { 
        page: 0,
        rowsPerPage: 10,
        openForm: false,


    }
    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ page: 0, rowsPerPage: event.target.value });
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        if(isEmpty(this.props.USERS)){
            this.props.fetchUsers()
        }
    }

    handleClose = () => {
		this.setState({ 
            openForm: false,
        });
    };    

    getUserList = () => {
        const { USERS, isSuper } = this.props
        const roles = ["", "USER", "ADMIN", "SUPERADMIN"]
        if (!isEmpty(USERS)){
            return Object.values(USERS).map(user => {

                return (
                    <TableRow className="">
                        <TableCell>{new Date(user.user_creation_date).toLocaleDateString("fr-FR") }</TableCell>
                        <TableCell>{user.user_status}</TableCell>
                        <TableCell>{user.email}</TableCell>
                        <TableCell>{user.username}</TableCell>
                        <TableCell>{user.lastname}</TableCell>
                        <TableCell>{roles[user.user_role]}</TableCell>
                        
                        {!isSuper &&
                            <TableCell>

                                <IconButton  component="span" aria-label="Edit" onClick={()=>this.setState({
                                    userID: user.user_id,
                                    openOptions: true
                                })}>
                                    <MoreVert fontSize="small"/>
                                </IconButton>
                            </TableCell>
                        }
                    </TableRow>
                )
            })
        }
    }

    render() {
        const { isSuper, USERS } = this.props;
        const { page, rowsPerPage, openForm } = this.state;

        return (
            <div>
                {openForm &&
                    <UserForm open={openForm} handleClose={this.handleClose} {...this.props} />
                }
                <Typography variant="h6" id="tableTitle">
                    Liste des Utilisateurs
                </Typography>

                <div style={{textAlign: "right"}}>
                    <Button variant="contained" 
                        onClick={() => this.setState({openForm: true})} 
                        color="secondary"
                        title="Nouvel Utilisateur">
                        Créer
                    </Button>
                </div>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Créé le</TableCell>
                            <TableCell>Statut</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Nom</TableCell>
                            <TableCell>Prénom</TableCell>
                            <TableCell>Role</TableCell>
                            {!isSuper &&
                                <TableCell>Options</TableCell>
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.getUserList()}
                    </TableBody>
                    {size(USERS) > 10 &&
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[10, 15]}
                                    colSpan={7}
                                    count={size(USERS)}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                    ActionsComponent={Pagination}
                                />
                            </TableRow>
                        </TableFooter>
                    }
                </Table>
            </div>
        
        );
    }
}

export default UsersList;