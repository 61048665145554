import React, {Component} from 'react';

import { connect } from "react-redux";

import logo from "../../images/logo/logo_solar_africa.png"
import { Box } from '@material-ui/core';


class Footer extends Component {
	state = { 
		selected: ""
	 }

	setValue = (e, newVal) => {
		console.log("Footer bottom nav", {newVal});

		let PAGES = {
			0: "/products/home/",
			1: "/admin/dashboard",
		}

		this.props.history.push(PAGES[newVal])
		this.setState({
			selected: newVal,
		})
		
	}
	render() {

		return (
			<footer className="footer" >
				<div className="footer-credit">
				<Box >
					<img src={logo} className="img-responsive" alt=""/>
				</Box>
					<p>Solar Africa by MyJouleBox © {new Date().getFullYear()}</p>
				</div>
			</footer>
		);
	}
}
function mapStateToProps(state) {
	return {
		auth: state.auth,
	};
}

export default connect( mapStateToProps )(Footer)