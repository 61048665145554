import axios from 'axios'
import  { checkErrorData } from './authAction'
import  { fetchProducts } from './productAction'
import  { fetchStocks } from './stockAction'
import  { formatList } from './format'

export function getCountriesList() {
    return async function(dispatch){
        try{
            dispatch({ type: "FETCH_COUNTRIES_START", payload: "" })

            console.log("fetch countries START")
            const res = await axios.get('/countries/all')
            console.log({res});
            
            if(checkErrorData(res, "FETCH_COUNTRIES_ERROR", dispatch ) ) {
				dispatch({
                    type: "GET_COUNTRIES",
                    payload: res.data.countries
                })
				dispatch({ type: "FETCH_COUNTRIES_FULFILLED", payload: "" })
			}
        } catch(err) {
            console.log("[ERROR] get countries list ", err);
            dispatch({
                type: "FETCH_COUNTRIES_ERROR",
                payload: err
                
            })
        }
    }
}
export function getCountriesAccounts(country) {
    return async function(dispatch, getState){
        try {
            dispatch({ type: "FETCH_ACCOUNTS_START", payload: "" })

            console.log("fetch countries START")
            const res = await axios.post('/countries/accounts', {country})
            console.log({res});
            
            if(checkErrorData(res, "FETCH_ACCOUNTS_ERROR", dispatch ) ) {
				dispatch({
                    type: "GET_ACCOUNTS",
                    payload: res.data.adminID, 
                })
				dispatch({
                    type: "SET_COUNTRY",
                    payload: country,
                })
                dispatch({ type: "FETCH_ACCOUNTS_FULFILLED", payload: "" })
                // fetch products and stocks from these accounts
                dispatch(fetchProducts(res.data.adminID))
                dispatch(fetchStocks(res.data.adminID))
			}
        } catch(err) {
            console.log("[ERROR] get countries list ", err);
            dispatch({
                type: "FETCH_ACCOUNTS_ERROR",
                payload: err
                
            })
        }
    }
}
export function setAdminAccount(accountID) { // to get admin users products 
    return async function(dispatch){
        try {
            dispatch({ type: "SET_ADMIN_ACCOUNT_START", payload: "" })
            
            dispatch({
                type: "SET_ADMIN_ACCOUNT",
                payload: [accountID]
            })
            
            dispatch({ type: "SET_ADMIN_ACCOUNT_FULFILLED", payload: "" })
            // fetch products and stocks from these account
            dispatch(fetchProducts([accountID]))
            dispatch(fetchStocks([accountID]))
			
        } catch(err) {
            console.log("[ERROR] set admin account ", err);
            dispatch({
                type: "SET_ADMIN_ACCOUNT_ERROR",
                payload: err
                
            })
        }
    }
}

export function restoreAccounts(accounts){
    return async function(dispatch){
        try {
            dispatch({ type: "SET_ACCOUNTS_START", payload: "" })
            dispatch({
                type: "SET_ACCOUNTS",
                payload: accounts
            })
                
            dispatch({ type: "SET_ACCOUNTS_FULFILLED", payload: "" })
            dispatch(fetchProducts(accounts))
            dispatch(fetchStocks(accounts))

            
        } catch (error) {
            dispatch({ type: "SET_ACCOUNTS_ERROR", payload: error })
        }
    }
}
