import React from 'react';

import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';

import COUNTRIES from '../../utils/countries'
import DatePicker from '../common/DatePicker';

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { Box, Button, Typography, Divider } from '@material-ui/core';
import UpdateUserForm from './ProfileInfo/UpdateUserForm';

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        width: '100%',
    },
    root: {
        display: 'flex',
    },
    formControl: {
        width: "100%",
    },
    group: {
        flexDirection: 'row',
    },
    selectOption: {
        marginTop: theme.spacing(1),
    },
});

// class UpdatePassword extends Component {

// }
class UpdateUser extends React.Component {
    state = {        

    }
    

    render() {
        console.log("USERFORM PROPS", this.state)
        const { classes } = this.props;

        return (
            
            <Box>    
                <Typography variant="h5">Mes Informations</Typography>
                <Divider />
                <Box p={2} />
                
                <UpdateUserForm {...this.props} />
            </Box>
        );
    }
}

export default withStyles(styles)(UpdateUser)

