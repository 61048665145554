import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { isEmpty } from "lodash";
import axios from "axios"
import { Button, FormControl, InputLabel, Select, OutlinedInput } from '@material-ui/core';
import DialogBox from '../../container/MUI/DialogBox';

class CountryList extends Component {
    state = { 
    }

    selectCountry = async () => {
        this.props.getCountriesAccounts(this.props.country)
    }


    render() {
        const { COUNTRIES } = this.props;
        return (
            <DialogBox 
                open={this.props.open} 
                title="Choisissez un pays" 
                disableBackdropClick={false} 
                close={ this.props.handleClose }
            >
                    
                <FormControl variant="outlined" style={{width: "100%"}}>
                    <InputLabel htmlFor="country">Pays</InputLabel>
                    <Select
                        native
                        onChange={this.props.handleChange}
                        value={this.props.country}
                        name="country"
                        input={<OutlinedInput labelWidth={50} name="country" id="country" />}
                    >
                        <option value="" />
                        {!isEmpty(COUNTRIES) && COUNTRIES.map( country => (
                            <option value={country}>{country}</option>
                        ))}
                    </Select>
                </FormControl>
                <Button 
                    variant="contained" 
                    color="secondary"
                    onClick={this.selectCountry}
                    title="Valider"
                    style={{margin: "0 5px"}}
                >
                    Valider
                    {/* <Add /> Valider */}
                </Button>

            </DialogBox>
        );
    }
}

function mapStateToProps(state){// transform elements from store's state into props in this component
    return {
        COUNTRIES: state.countries.COUNTRIES,
    }
}

export default connect(mapStateToProps)(CountryList);