export const FIELDS = {
    "name": {label:"Nom du Produit",  name:"name", type:"text", required: true, field: "textfield"},
    "marque": {label:"Marque",  name:"marque", type:"text", required: true, field: "textfield"},
    "type": {label:"Type",  name:"type", type:"text", required: true, field: "select", options:[]},
    "prix_venteTTC": {label:"Prix de Vente TTC",  name:"prix_venteTTC", type:"number", required: true, field: "textfield"},
    "reduc_max": {label:"Réduction Maximale",  name:"reduc_max", type:"number", required: false, field: "textfield"},
    "garantie": {label:"Garantie (En années)",  name:"garantie", type:"number", required: true, field: "textfield"},
    "prix_venteHT": {label:"Prix de Vente HT",  name:"prix_venteHT" , type:"number", required: true, field: "textfield"},
    "photos": {label:"Photos",  name:"photos", type:"text", required: true, field: "textfield"},
    "longueur": {label:"Longueur (mm)",  name:"longueur", type:"number", required: true, field: "textfield"},
    "largeur": {label:"Largeur (mm)",  name:"largeur", type:"number", required: true, field: "textfield"},
    "hauteur": {label:"Hauteur (mm)",  name:"hauteur", type:"number", required: true, field: "textfield"},
    "poids": {label:"Poigs (kg)",  name:"poids", type:"number", required: true, field: "textfield"},
    
    "pan_tensionVoc": {label:"Tension (Voc)",  name:"pan_tensionVoc", type:"number", required: true, field: "textfield"}, 
    "pan_courantIoc": {label:"Courant (Ioc)",  name:"pan_courantIoc", type:"number", required: true, field: "textfield"}, 
    "pan_tensionVmpp": {label:"Tension (Vmpp)",  name:"pan_tensionVmpp", type:"number", required: true, field: "textfield"}, 
    "pan_courantImpp": {label:"Courant (Impp)",  name:"pan_courantImpp", type:"number", required: true, field: "textfield"}, 
    "pan_puissance": {label:"Puissance (W)",  name:"pan_puissance", type:"number", required: true, field: "textfield"}, 
    "pan_cell": {label:"Cellules",  name:"pan_cell", type:"text", required: true, field: "select"}, 
    "pan_rendement": {label:"Rendement (%)",  name:"pan_rendement", type:"number", required: true, field: "textfield"},
    
    "bat_type": {label:"Type Batterie",  name:"bat_type", type:"text", required: true, field: "select"}, 
    "bat_tension": {label:"Tension",  name:"bat_tension", type:"number", required: true, field: "checkbox"}, 
    "bat_max_capacity": {label:"Capacité Max (Ah)",  name:"bat_max_capacity", type:"number", required: true, field: "textfield"}, 
    // "bat_nb_cycles": {label:"",  name:"bat_nb_cycles", type:"number", required: true, field: "textfield"},
    "bat_nb_cycles30": {label:"Nombre de Cycles à 30% de DOD", name:"bat_nb_cycles30", type:"number", required: false, field: "textfield"},
    "bat_nb_cycles50": {label:"Nombre de Cycles à 50% de DOD", name:"bat_nb_cycles50", type:"number", required: false, field: "textfield"},
    "bat_nb_cycles80": {label:"Nombre de Cycles à 80% de DOD", name:"bat_nb_cycles80", type:"number", required: false, field: "textfield"},
    "bat_rendement": {label:"Rendement Energétique",  name:"bat_rendement", type:"number", required: true, field: "textfield"},
    "bat_temperature": {label:"Température d'exploitation (°C)",  name:"bat_temperature", type:"number", required: true, field: "textfield"},
    "bat_auto_decharge": {label:"Auto décharge",  name:"bat_auto_decharge", type:"number", required: true, field: "textfield"},
    
    "conv_tension": {label:"Tension Entrée",  name:"conv_tension", type:"number", required: true, field: "checkbox"},
    "conv_puissance": {label:"Puissance Sortie (VA)",  name:"conv_puissance", type:"number", required: true, field: "textfield"},
    "conv_triphase": {label:"Triphasé ?",  name:"conv_triphase", type:"number", required: true, field: "radio"},
    "conv_puissance25": {label:"Puissance à 25°C (W)",  name:"conv_puissance25", type:"number", required: false, field: "textfield"},
    "conv_puissance40": {label:"Puissance à 40°C (W)",  name:"conv_puissance40", type:"number", required: false, field: "textfield"},
    "conv_puissance65": {label:"Puissance à 65°C (W)",  name:"conv_puissance65", type:"number", required: false, field: "textfield"},
    "conv_puissance_crete": {label:"Puissance Crete (W)",  name:"conv_puissance_crete", type:"number", required: true, field: "textfield"},
    "conv_eff_max": {label:"Efficacité maximale (%)",  name:"conv_eff_max", type:"number", required: true, field: "textfield"},
    "conv_signal": {label:"Type Signal",  name:"conv_signal", type:"text", required: true, field: "select"},
    
    "conv_absorption": {label:"Tension de charge 'Absorption' (VCC)",  name:"conv_absorption", type:"number", required: true, field: "textfield"},
    "conv_float": {label:"Tension de charge 'Float' (VCC)",  name:"conv_float", type:"number", required: true, field: "textfield"},
    "conv_courant_service": {label:"Courant de Charge de batterie de service",  name:"conv_courant_service", type:"number", required: true, field: "textfield"},
    "conv_courant_start": {label:"Courant de Charge de batterie démarrage",  name:"conv_courant_start", type:"number", required: true, field: "textfield"},
    
    "conv_tension_sortie": {label:"Tension de sortie nominale (VDC)",  name:"conv_tension_sortie", type:"number", required: true, field: "textfield"},
    "conv_sortie25": {label:"Puissance sortie continue à 25°C (W)",  name:"conv_sortie25", type:"number", required: true, field: "textfield"},
    "conv_sortie40": {label:"Puissance sortie continue à 40° C (W)",  name:"conv_sortie40", type:"number", required: true, field: "textfield"},
    "conv_tension_min": {label:"Tension entrée minimale (VDC)",  name:"conv_tension_min", type:"number", required: true, field: "textfield"},
    "conv_tension_max": {label:"Tension entrée maximale (VDC)",  name:"conv_tension_max", type:"number", required: true, field: "textfield"},
    
    "conv_MPPT_courant_sortie": {label:"Courant de Sortie Max",  name:"conv_MPPT_courant_sortie", type:"number", required: true, field: "textfield"},
    "conv_MPPT_tensionPV_max": {label:"Tension PV Max",  name:"conv_MPPT_tensionPV_max", type:"number", required: true, field: "textfield"},
    "conv_MPPT_puissancePV_max": {label:"Puissance PV Max",  name:"conv_MPPT_puissancePV_max", type:"number", required: true, field: "textfield"},
    
    "reg_tension_max": {label:"Tension Maximale en circuit ouvert",  name:"reg_tension_max", type:"number", required: true, field: "textfield"},
    "reg_puissance_pv12": {label:"Puissance nominale 12V",  name:"reg_puissance_pv12", type:"number", required: true, field: "textfield"},
    "reg_puissance_pv24": {label:"Puissance nominale 24V",  name:"reg_puissance_pv24", type:"number", required: true, field: "textfield"},
    "reg_puissance_pv48": {label:"Puissance nominale 48V",  name:"reg_puissance_pv48", type:"number", required: false, field: "textfield"},
    "reg_type": {label:"Type Regulateur",  name:"reg_type", type:"text", required: true, field: "select"},
    "reg_affichage": {label:"Affichage",  name:"reg_affichage", type:"text", required: true, field: "select"},
    "reg_courant": {label:"Courant de charge nominal",  name:"reg_courant", type:"text", required: true, field: "select"},
    
    "cab_type": {label:"Type de Conduits",  name:"cab_type", type:"text", required: true, field: "select"},
    "cab_nb_conducteurs": {label:"Nombre de conducteurs",  name:"cab_nb_conducteurs", type:"text", required: true, field: "select"},
    "cab_section": {label:"Section des conducteurs (mm2)",  name:"cab_section", type:"text", required: true, field: "textfield"},
    
    "pomp_puissance": {label:"Puissance (W)",  name:"pomp_puissance", type:"text", required: true, field: "textfield"},
    
    "lam_puissance": {label:"Puissance (W)",  name:"lam_puissance", type:"number", required: true, field: "textfield"},
    "lam_flux": {label:"Flux lumineux (lm)",  name:"lam_flux", type:"number", required: true, field: "textfield"},
    "lam_tension": {label:"Tension d'alimentation",  name:"lam_tension", type:"number", required: true, field: "checkbox"},
    
    "men_type": {label:"Type",  name:"men_type", type:"text", required: true, field: "select"},
    "men_infos": {label:"Autres informations",  name:"men_infos", type:"text", required: false, field: "textfield"},
    "desc": {label:"Description",  name:"desc", type:"text", required: false, field: "textfield"},
    "tv_puissance_vide": {label:"Puissance à vide(W)",  name:"tv_puissance_vide", type:"number", required: false, field: "textfield"},
    "tv_puissance_fonc": {label:"Puissance en fonctionnement (W)",  name:"tv_puissance_fonc", type:"number", required: false, field: "textfield"},
    "tv_taille": {label:"Taille (pouce)",  name:"tv_taille", type:"number", required: false, field: "textfield"},
    "tv_tuner": {label:"Tuner inclus",  name:"tv_tuner", type:"text", required: false, field: "radio"},
    "tv_tension_alim": {label:"Tension d'alimentation",  name:"tv_tension_alim", type:"text", required: false, field: "checkbox"},
    "vent_puissance": {label:"Puissance(W)",  name:"vent_puissance", type:"number", required: false, field: "textfield"},
}

export const common = [
    "name",
    "marque",
    "type",
    "prix_venteHT",
    "prix_venteTTC",
    "garantie",
    "poids",
    "longueur",
    "largeur",
    "hauteur", 
    "reduc_max", // not required

    // add photo
]

// REQUIRED FIELD BY TYPE
export const panels = [
        "pan_tensionVoc",
        "pan_courantIoc",
        "pan_tensionVmpp", 
        "pan_courantImpp", 
        "pan_puissance", 
        "pan_cell", 
        "pan_rendement",
    ]
export const bat = [
        "bat_type", 
        "bat_tension", 
        "bat_max_capacity", 
        // "bat_nb_cycles",
        "bat_nb_cycles30",
        "bat_nb_cycles50",
        "bat_nb_cycles80", // seulement pour les prod victron
        "bat_rendement",
        "bat_temperature",
        "bat_auto_decharge",
    ]

export const conv = [
        "conv_tension",
        "conv_puissance",
        "conv_triphase",
        "conv_puissance25",
        "conv_puissance40",
        "conv_puissance65",
        "conv_puissance_crete",
        "conv_eff_max",
        "conv_signal",
    ]
export const conv_ch =[
        "conv_absorption",
        "conv_float",
        "conv_courant_service",
        "conv_courant_start",
    ]
export const conv_MPPT =[
        "conv_MPPT_courant_sortie",
        "conv_MPPT_tensionPV_max",
        "conv_MPPT_puissancePV_max",
    ]
export const cdc=[
        "conv_tension_sortie",
        "conv_sortie25",
        "conv_sortie40",
        "conv_tension_min",
        "conv_tension_max",
        "conv_eff_max",
    ]

    
export const reg = [
        "reg_tension_max",
        "reg_puissance_pv12",
        "reg_puissance_pv24",
        "reg_puissance_pv48", // not required
        "reg_courant",
        "reg_type",
        "reg_affichage",
        "bat_tension", 
    ]
export const cab = [
        "cab_type",
        "cab_nb_conducteurs",
        "cab_section",
    ]
export const lam = [
        "lam_puissance",
        "lam_flux",
        "lam_tension",
    ]
export const menager = [
        "men_type",

        "men_infos",
        "desc",
        "tv_puissance_vide",
        "tv_puissance_fonc",
        "tv_taille",
        "tv_tuner",
        "tv_tension_alim",
        "vent_puissance",
    ]
export const vent = [
       
        "vent_puissance",
    ]
export const tv = [
        "tv_puissance_vide",
        "tv_puissance_fonc",
        "tv_taille",
        "tv_tuner",
        "tv_tension_alim",
    ]
    
export const pompes = [
        "pomp_puissance"
    ]

export const floatFields = [
    "prix_venteTTC",
    "reduc_max",
    "TVA",
    "prix_venteHT",
    "poids",
    "bat_max_capacity",
    "pan_tensionVoc",
    "pan_courantIoc",
    "pan_tensionVmpp", 
    "pan_courantImpp",
    "pan_puissance",
    "pan_rendement",
    "bat_temperature",
    "bat_rendement",
    "bat_auto_decharge",
    "conv_puissance",
    "conv_puissance25",
    "conv_puissance40",
    "conv_puissance65",
    "conv_puissance_crete",
    "conv_eff_max",
    "conv_absorption",
    "conv_float",
    "conv_courant_service",
    "conv_courant_start",
    "conv_tension_sortie",
    "conv_sortie25",
    "conv_sortie40",
    "conv_tension_min",
    "conv_tension_max",
    "conv_MPPT_courant_sortie",
    "conv_MPPT_tensionPV_max",
    "conv_MPPT_puissancePV_max",
    "tv_taille",
    "tv_puissance_fonc",
    "tv_puissance_vide",
    "vent_puissance",
    "pomp_puissance",
    "lam_puissance",
    "lam_tension",
    "lam_flux",
    "cab_section",
    "reg_tension_max",
    "reg_puissance_pv12",
    "reg_puissance_pv24",
    "reg_puissance_pv48",
    "reg_courant",
];

export const intFields = [
    "longueur",
    "largeur",
    "hauteur",
    "garantie",
    "bat_nb_cycles",
    "bat_nb_cycles30",
    "bat_nb_cycles50",
    "bat_nb_cycles80",

];

export const types = {
    "panneaux":"Panneaux Solaires",
    "regulateurs":"Régulateurs",
    "convertisseurs":"Convertisseurs",
    "convertisseurs_chargeurs":"Convertisseur-Chargeurs",
    "convertisseurs_chargeurs_MPPT":"Convertisseur-Chargeurs - MPPT",
    "convertisseurs_dc-dc":"Convertisseur DC-DC",
    "batteries":"Batteries",
    "cables":"Câbles",
    "lampes":"Lampes",
    "supervision":"Supervision",
    "kit":"Kits complet",
    "menager":"Equipement ménager",
    "pompes":"Pompes",
    "picos":"Picos",
    "accesoires":"Accessoires",
    "protection":"Protection",
    "autres":"Autres"
}

export const noUpdate = [
    "type",
    "marque",
    "men_type",
]