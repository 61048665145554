import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

export default function(ComposedComponent) {
	class MustBeAdmin extends Component {
		state = {};
		componentWillMount() {
			if (!this.props.isAdmin && !this.props.isAuthenticated) {
				this.props.history.push("/login");
			} else if (!this.props.isAdmin && this.props.isAuthenticated) {
				this.props.history.push("/");
			}
		}
		componentWillUpdate(nextProps) {
			if (!nextProps.isAdmin && !nextProps.isAuthenticated) {
				this.props.history.push("/login");
			} else if (!nextProps.isAdmin && nextProps.isAuthenticated) {
				this.props.history.push("/");
			}
		}
		render() {
			return <ComposedComponent {...this.props} />;
		}
	}

	MustBeAdmin.propTypes = {
		isAdmin: PropTypes.bool.isRequired,
		isAuthenticated: PropTypes.bool.isRequired
	};

	function mapStateToProps(state) {
		return {
			isAdmin: state.auth.isAdmin,
			isAuthenticated: state.auth.isAuthenticated
		};
	}
	return connect(mapStateToProps)(withRouter(MustBeAdmin));
}
