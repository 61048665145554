import React, { Component, Fragment } from 'react';

import { 
    Grid, 
    TablePagination,
    Box,
    TextField,
    Paper,
    FormHelperText,
    FormControl,
    InputLabel,
    Select,
    OutlinedInput, 
    FormLabel,
    FormGroup,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core';


class FilterProducts extends Component {
    state = {  }
    
    render() {
        const { type } = this.props;
        return (
            <Paper>
                    <Box p={2} >
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <TextField
                                    // size="small"
                                    label="Rechercher nom produit"
                                    variant="outlined"
                                    style={{width:"100%"}}
                                    onChange={this.props.handleChange}
                                    value={this.props.search}
                                    name="search"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <TextField
                                    // size="small"
                                    label="Marque"
                                    variant="outlined"
                                    style={{width:"100%"}}
                                    onChange={this.props.handleChange}
                                    value={this.props.brand}
                                    name="brand"
                                />
                            </Grid>
                            { type === "regulateurs" &&
                            <Fragment>
                                <Grid item xs={12} sm={6} md={3}>
                                    <FormControl size="small" variant="outlined" style={{width: "100%"}}>
                                        <InputLabel htmlFor="reg_type">Type de Regulateur</InputLabel>
                                        <Select
                                            
                                            native
                                            onChange={this.props.handleChange}
                                            value={this.props.reg_type}
                                            name="reg_type"
                                            input={<OutlinedInput labelWidth={130} name="reg_type" id="reg_type" />}
                                        >
                                            <option value="" />
                                            <option value="MPPT">MPPT</option>
                                            <option value="PWM">PWM</option>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>

                                    <FormControl size="small" variant="outlined" style={{width: "100%"}}>
                                        <InputLabel htmlFor="reg_affichage">Type Affichage</InputLabel>
                                        <Select
                                            
                                            native
                                            onChange={this.props.handleChange}
                                            value={this.props.reg_affichage}
                                            name="reg_affichage"
                                            input={<OutlinedInput labelWidth={130} name="reg_affichage" id="reg_affichage" />}
                                        >
                                            <option value="" />
                                            <option value="numerique">Numérique</option>
                                            <option value="led">LED</option>
                                        </Select>
                                    </FormControl>
                                </Grid>

                            </Fragment>
                            }
                            { type === "convertisseurs" &&
                        <Fragment>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl variant="outlined" style={{width: "100%"}}>
                                    <InputLabel htmlFor="conv_type">Type de Convertisseur</InputLabel>
                                    <Select
                                        native
                                        onChange={this.props.handleChange}
                                        value={this.props.conv_type}
                                        name="conv_type"
                                        input={<OutlinedInput labelWidth={160} name="conv_type" id="conv_type" />}
                                    >
                                        <option value="" />
                                        <option value="convertisseurs_chargeurs_MPPT">Convertisseur Chargeur MPPT</option>
                                        <option value="convertisseurs_chargeurs">Convertisseur Chargeur</option>
                                        <option value="convertisseurs_dc-dc">Convertisseur DC-DC</option>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl variant="outlined" style={{width: "100%"}}>
                                    <InputLabel htmlFor="conv_signal">Type de Signal</InputLabel>
                                    <Select
                                        native
                                        onChange={this.props.handleChange}
                                        value={this.props.conv_signal}
                                        name="conv_signal"
                                        input={<OutlinedInput labelWidth={120} name="conv_signal" id="conv_signal" />}
                                    >
                                        <option value="" />
                                        <option value="Sinus Pur">Sinus pur</option>
                                        <option value="Quasi Sinus">Quasi sinus</option>
                                    </Select>
                                </FormControl>                            
                            </Grid>

                        </Fragment>
                        }
                            <Grid item xs={12} sm={6} md={3}>
                                {type === "panneaux" && 
                                    <FormControl variant="outlined" style={{width: "100%"}}>
                                        <InputLabel htmlFor="pan_cell">Type de Panneaux</InputLabel>
                                        <Select
                                            native
                                            onChange={this.props.handleChange}
                                            value={this.props.pan_cell}
                                            name="pan_cell"
                                            input={<OutlinedInput labelWidth={130} name="pan_cell" id="pan_cell" />}
                                        >
                                            <option value="" />
                                            <option value="polycristalline">Polycristalline</option>
                                            <option value="monocristalline">Monocristalline</option>
                                        </Select>
                                    </FormControl>
                                }
                                
                                { type === "batteries" &&
                                    <FormControl variant="outlined" style={{width: "100%"}}>
                                        <InputLabel htmlFor="bat_type">Type de Batterie</InputLabel>
                                        <Select
                                            native
                                            onChange={this.props.handleChange}
                                            value={this.props.bat_type}
                                            name="bat_type"
                                            input={<OutlinedInput labelWidth={130} name="bat_type" id="bat_type" />}
                                        >
                                            <option value="" />
                                            <option value="carbone">Carbone</option>
                                            <option value="GEL">GEL</option>
                                            <option value="lithium">Lithium</option>
                                            <option value="acide">Acide</option>
                                            <option value="AGM">AGM</option>
                                        </Select>
                                    </FormControl>
                                }
                                { type === "cables" &&
                                    <FormControl variant="outlined" style={{width: "100%"}}>
                                        <InputLabel htmlFor="cab_type">Type de Conduits</InputLabel>
                                        <Select
                                            native
                                            onChange={this.props.handleChange}
                                            value={this.props.cab_type}
                                            name="cab_type"
                                            input={<OutlinedInput labelWidth={130} name="cab_type" id="cab_type" />}
                                        >
                                            <option value="" />
                                            <option value="rigide">Rigide</option>
                                            <option value="souple">Souple</option>
                                        </Select>
                                    </FormControl>
                                }
                            </Grid>
                            
                        </Grid>
                        
                    </Box>
                </Paper>
        );
    }
}

export default FilterProducts;