export function productReducer(state = {}, {
    type,
    payload
}){
    
    
    switch(type){
        case "NEW_PRODUCT":
        case "UPDATE_PRODUCT":
            let list = Object.assign({}, state)
            list[payload.id] = payload
            return list

        case "GET_PRODUCTS":
            let list5 = Object.assign({}, state)
            list5 = payload
            Object.values(list5).forEach( product => {
                list5[product.id].mainPhoto = list5[product.id].photos ? "/photos/"+ list5[product.id].photos[0].filename : ""
            })
            return list5

        case "GET_PRODUCTS_DETAILS":
            let list6 = Object.assign({}, state)

            Object.values(payload).forEach( product => {
                list6[product.id] = Object.assign(list6[product.id], product)
            })
            return list6

        case "COMPLETE_PRODUCT":
            let list7 = Object.assign({}, state)
            list7[payload.id] = Object.assign(list7[payload.id], payload)
            return list7

        case "GET_PRODUCTS_TYPE":
            let cat = Object.assign({}, state)
            cat[payload.type] = payload.list
            return cat;

        case "SET_MAIN_PHOTO":
            let list2 = Object.assign({}, state)
            let newProd = list2[payload.prodID]
            newProd.src = []
            newProd.mainPhoto = [payload.URI]
            return list2

        case "SET_ALL_PHOTOS":
            let list3 = Object.assign({}, state)
            payload.map(photo => {
                list3[photo.key].src = []
                list3[photo.key].mainPhoto = photo.value

            })
            return list3
        case "SET_PHOTOS":
            let list4 = Object.assign({}, state)
            let prod = list4[payload.prodID]
           
            if(prod.src){
                prod.src.push(payload.URI)
            } else {
                prod.src = [payload.URI]
            }
            return list4
        default:
            return state;
    }
    
}
