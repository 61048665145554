import React, { Component } from 'react';

import withMobileDialog from '@material-ui/core/withMobileDialog';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
    root: {
      margin: 0,
      padding: theme.spacing(2)
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

class DialogBox extends Component {
    
    render() {
        const { open, close, fullScreen, title, disableBackdropClick, scroll, classes } = this.props;
        return (
            <Dialog
                disableBackdropClick={disableBackdropClick}
                open={open}
                onClose={close}
                aria-labelledby="form-dialog-title"
                maxWidth={this.props.maxWidth || "sm"}
                fullScreen={fullScreen}
                fullWidth
                scroll={scroll || "body"}
                >
                <DialogTitle disableTypography className={classes.root}>
                    <Typography variant="subtitle1">{title}</Typography>
                    {close ? (
                        <IconButton aria-label="Close" className={classes.closeButton} onClick={close}>
                            <CloseIcon />
                        </IconButton>
                    ) : null}
                </DialogTitle>
                    <p className="dialog-banner-footer"></p>
                <DialogContent>
                    {this.props.children[0] || this.props.children }
                </DialogContent>
                {this.props.children[1] &&
                
                <DialogActions>
                   {this.props.children[1]}
                </DialogActions>
                }
            </Dialog>
        );
    }
}
  
export default withMobileDialog()(withStyles(styles)(DialogBox))