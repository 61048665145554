import { isEmpty } from "lodash";

export function authReducer(
	state = {
		isAuthenticated: false,
		user: {},
		userInfo: {},
		stats:{}
	},
	{ type, payload }
) {
	switch (type) {
		case "SET_CURRENT_USER":
			let info = Object.assign({}, state)
			
			info.isAuthenticated = !isEmpty(payload);
			info.isAdmin = !isEmpty(payload) && payload.role > 1;
			info.isSuperAdmin = !isEmpty(payload) && payload.role > 2;
			info.user = payload

			return info
			
		case "GET_USER_INFO":
			let info2 = Object.assign({}, state)
			info2.userInfo = payload
			// info2.isClient = !isEmpty(payload) && payload.isClient && payload.user_role === 1;
			// info2.isAgent = !isEmpty(payload) && payload.isAgent && payload.user_role === 2;

			return info2
		

		case "GET_USER_STATS":
			let info3 = Object.assign({}, state)
			info3.stats = payload
			
			return info3
		case "GET_AGENT_AMOUNT":
			let info4 = Object.assign({}, state)
			info4.user.account_amount = payload

			return info4
		case "UPDATE_AGENT_AMOUNT":
			let info5 = Object.assign({}, state);
			if(info5.user.account_amount) {
				info5.user.account_amount += payload
			} else {
				info5.user.account_amount = payload
			}

			return info5
		case "UPDATE_USER_ACCOUNT":
			let current = state.userInfo.account || 0;

			if (current + payload > 0) {
				current += payload;
			}
			return {
				...state,
				userInfo: {
					...state.userInfo,
					account: current
				}
			};
		default:
			return state;
	}
}
