import React, { Component } from "react";
import { Route, Switch, Router, Redirect } from "react-router-dom";
import history from './history/history';
import { isEmpty } from 'lodash'
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { MuiThemeProvider, createMuiTheme, withStyles } from "@material-ui/core/styles";

import red from "@material-ui/core/colors/red";

import "./App.css";
import "./css/Home.css";
import "./css/Nav.css";
import "./css/Admin.css";
import "./css/Login.css";
import "./css/Page.css";
import "./css/Profile.css";
import "./css/Footer.css";

import RequireAuth from "./components/HOC/RequireAuth";
import RequireAdmin from "./components/HOC/RequireAdmin";
import NoAuth from "./components/HOC/NoAuth";
import ClassesProps from "./components/HOC/ClassesProps";

import HomePage from "./components/HomePage";
import AdminDashboard from "./components/Admin/AdminDashboard";
import LoginPage from "./components/LoginPage";
import HomeProducts from "./components/Products/HomeProducts";
import Cart from "./components/Checkout/Cart";
import Checkout from "./components/Checkout/Checkout";
import Profile from "./components/Account/Profile";


class App extends Component {
	state = {}
	
	render() {
		const theme = createMuiTheme({
			palette: {
				// type: "dark",
				
				primary: {
					main: "#ffd54f"
					// main: "#880e4f"
				},
				secondary: {
					main: "#1a237e"
				},
				// primary: teal,
				// secondary: {
				// 	main: "#ffd54f"
				// },
				inherit: {
					main: "gray"
				},
				error: red,
				background:{
					paper: "#f3f3f3",
					// paper: "#f4f3efc2"
					// default: "#E9EDF4"
				}
			},
			typography: {
				fontSize: 15,
				fontWeight: 500,
			  },
		});

		return (
			<MuiThemeProvider theme={theme}>
				<Router 
					onUpdate={() => window.scrollTo(0, 0)}  
					history={history} 
					{...this.state} 
				>
					
					<Switch>
						<Route exact path="/" component={ClassesProps(HomePage)} />
						<Route exact path="/profile/:page" component={RequireAuth(ClassesProps(Profile))} />
						<Route exact path="/checkout" component={RequireAuth(ClassesProps(Checkout))} />
						<Route exact path="/cart" component={RequireAuth(ClassesProps(Cart))} />
						<Route exact path="/products/:page/:type" component={ClassesProps(HomeProducts)} />
						<Route exact path="/products/:page" component={ClassesProps(HomeProducts)} />
						<Route exact path="/product/:page/:ID" component={ClassesProps(HomeProducts)} />

						<Route exact path="/admin/:page" component={RequireAdmin(ClassesProps(AdminDashboard))}/>
						<Route exact path="/login" component={NoAuth(ClassesProps(LoginPage))}/>

						<Route render={ ()=> <Redirect to={{pathname: "/"}} /> } />

					</Switch>
				</Router>
			</MuiThemeProvider>
		);
	}
}



export default withStyles({ withTheme: true })(App);
