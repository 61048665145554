import React, { Component } from 'react';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Box,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import Transition from '../../../container/MUI/Transistion';
import FormProduct from './Form';
import FormUpdate from './FormUpdate';

const FIELDS = [
    {name: "product_title", rows: 1, label: "Titre", required: true },
    {name: "product_desc", rows: 6, label: "Description", required: true },
]

class ProductForm extends Component {
    state = { 
        product: {
            product_title: "",
            product_desc: "",
        },
        errors: {
            product_title: "",
            product_desc: "",
        },
    }
    
    handleChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        
        this.setState({
            product:{
                ...this.state.product,
                [name]: value
            }
        })
    }

    validate = () => {
        let required = [
            "product_title",
            "product_desc"
        ]
        
        let noError = true
        let errors = {}
       
        required.map(field => {
            if(!this.state.product[field]){
                console.log(`${field} is empty`)
                noError =  false
                errors[field] = "Veuillez remplir ce Champ" 
            } else {
                errors[field] = "" 
            }
        })

        this.setState({
            errors
        })
    
        return noError
    }

    handleSubmit = async () => {
        if(this.validate()){
            let prod = Object.assign({}, this.state.product)
            prod.id = this.props.oldProduct.id
            prod.prix_venteHT = this.props.oldProduct.prix_venteHT
            prod.prix_venteTTC = this.props.oldProduct.prix_venteTTC

            await this.props.completeProduct(prod)  

            if(this.props.STATE.products.error){
                this.props.notif("error",`Un problème est survenu: ${this.props.STATE.products.error}`)
            } else{
                this.props.notif("success","Le produit a été modifié avec succès")
                this.props.handleClose()
            }
        } else {
            this.props.notif("error", "Le formulaire contient des erreurs" )
        }
    }
    render() {
        return (
            <Dialog
                disableBackdropClick
                open={this.props.open}
                onClose={this.props.handleClose}
                aria-labelledby="form-dialog-title"
                fullScreen
                TransitionComponent={Transition}
                scroll="body"
            >
                <AppBar style={{position: 'relative'}} color="secondary">
                    <Toolbar>
                        <IconButton color="inherit" onClick={this.props.handleClose} aria-label="Close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" color="inherit">
                            Modifier le Produit
                        </Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent  style={{overflow: "hidden"}}>
                    <Box >
                        <FormUpdate {...this.props} {...this.state} handleChange={this.handleChange} />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={this.handleSubmit} color="secondary">
                        Créer
                    </Button>
                    <Button onClick={this.props.handleClose} color="default">
                        Annuler
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default ProductForm;