import React, { Fragment } from "react";
import { Link } from "react-router-dom";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { size } from "lodash";
import { logout } from "../../store/actions/authAction";

import logo from "../../images/logo/logo_solar_africa.png"
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import { withStyles } from "@material-ui/core/styles";
import AccountCircle from "@material-ui/icons/AccountCircle";
import EnhancedEncryption from '@material-ui/icons/EnhancedEncryption';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import history from '../../history/history';
import SideBarMenu from "../MUI/SideBarMenu";
import { ShoppingCartRounded } from "@material-ui/icons";
import { Badge, Box, Paper, Divider } from "@material-ui/core";
import { pink } from '@material-ui/core/colors';


const styles = theme => ({
	root: {
		width: "100%",
	},
	sectionDesktop: {
		width: "100%",
		display: "none",
		[theme.breakpoints.up("md")]: {
			display: "flex"
		}
	},
	sectionMobile: {
		display: "flex",
		[theme.breakpoints.up("md")]: {
			display: "none"
		}
	},
	nav: {
		margin: "auto",
		maxWidth: 1400,
		display: 'flex',
		justifyContent: "space-between",
		// backgroundColor: "white"

	},

	menuButton: {
		marginLeft: -12,
		marginRight: 20
	},

	button: {
		margin: theme.spacing(1)
	},
	buttonNav: {
		padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
		height: "100%",
		borderRadius: 0,
	},
	
	navTitle: {
		height: 40,
		margin: "-8px auto",
		width: 140,
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		margin: "auto",
		textAlign: "center",
		maxWidth: 1400,
		height: 40,
		display: 'flex',
		justifyContent: "center",

	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerPaper: {
		width: drawerWidth,
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
	},
	toolbar: theme.mixins.toolbar,
	list: {
		width: 250,
	},
	fullList: {
		width: 'auto',
	},
	primary: {
		color: theme.palette.getContrastText(theme.palette.primary.light),
		backgroundColor: theme.palette.primary.light,
	},
	secondary: {
		color: theme.palette.getContrastText(theme.palette.secondary.light),
		backgroundColor: theme.palette.secondary.light,
	},
	lightPink: {
		color: theme.palette.getContrastText(pink[200]),
		backgroundColor: pink[200],
	},
	black: {
		color: "white",
		backgroundColor: "black",
	},
});
const drawerWidth = 240;

class Header extends React.Component {
	state = {
		anchorEl: null,
		mobileMoreAnchorEl: null
	};
	handleProfileMenuOpen = event => {
		this.setState({ anchorEl: event.currentTarget });
	};

	handleMenuClose = () => {
		this.setState({ anchorEl: null });
		this.handleMobileMenuClose();
	};

	handleMobileMenuOpen = event => {
		this.setState({ mobileMoreAnchorEl: event.currentTarget });
	};

	handleMobileMenuClose = () => {
		this.setState({ mobileMoreAnchorEl: null });
	};
	goToHomePage = () => {
		console.log("GO TO MENU");

		history.push("/");
	}
	render() {
		const { isAuthenticated, isAdmin } = this.props.auth;
		const { anchorEl } = this.state;
		const { classes, CART } = this.props;
		console.log('====================================');
		console.log({page: this.props.page});
		console.log({PROPS: this.props});
		console.log('====================================');
		const isMenuOpen = Boolean(anchorEl);
		// const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
		let commonLinks = [
			// { page: "home", default: "Accueil", to: "/" },
			{ page: "panneaux", default: "Panneaux", to: "/products/type/panneaux" },
			{ page: "batteries", default: "Batteries", to: "/products/type/batteries" },
			{ page: "convertisseurs", default: "Convertisseurs", to: "/products/type/convertisseurs" },
			{ page: "onduleurs", default: "Onduleurs", to: "/products/type/onduleurs" },
			{ page: "regulateurs", default: "Regulateurs", to: "/products/type/regulateurs" },
			{ page: "lampes", default: "Lampes", to: "/products/type/lampes" },
			{ page: "cables", default: "Câbles", to: "/products/type/cables" },
			{ page: "accesoires", default: "Accessoires", to: "/products/type/accesoires" },
		];

		// const types = {
        //     "panneaux":"Panneaux Solaires",
        //     "regulateurs":"Régulateurs",
        //     "convertisseurs":"Convertisseurs",
        //     "convertisseurs_chargeurs":"Convertisseur-Chargeurs",
        //     "convertisseurs_chargeurs_MPPT":"Convertisseur-Chargeurs - MPPT",
        //     "convertisseurs_dc-dc":"Convertisseur DC-DC",
        //     "batteries":"Batteries",
        //     "cables":"Câbles",
        //     "lampes":"Lampes",
        //     "supervision":"Supervision",
        //     "kit":"Kits complet",
        //     "menager":"Equipement ménager",
        //     "pompes":"Pompes",
        //     "picos":"Picos",
        //     "accesoires":"Accessoires",
        //     "protection":"Protection",
        //     "autres":"Autres"
        // }
		const adminLinks = [{ page: "admin", default: "Admin", icon:<EnhancedEncryption />, to: "/admin/dashboard" }];
		const guestLinks = [];
		// const guestLinks = [{ page: "login", default: "Login", icon:<PersonPin/>, to: "/login" }];

		if (!isAuthenticated) {
			commonLinks = commonLinks.concat(guestLinks);
		}
		if (isAdmin) {
			commonLinks = commonLinks.concat(adminLinks);
		}

		const userMenu = (
			<Menu
				anchorEl={anchorEl}
				anchorOrigin={{ vertical: "top", horizontal: "right" }}
				transformOrigin={{ vertical: "top", horizontal: "right" }}
				open={isMenuOpen}
				onClose={this.handleMenuClose}
			>
				{isAuthenticated &&
					<Fragment>
						<Link to={"/profile/dashboard"} >
							<ListItem>
								<ListItemText>
								Mon Compte							
								</ListItemText>
							</ListItem>
						</Link>
						<ListItem
							onClick={() => {
								this.handleMenuClose();
								this.props.logout();
							}}
						>
							<ListItemText >
							Déconnexion
								
							</ListItemText>
						</ListItem>

					</Fragment>
				}			
				{!isAuthenticated &&
				<Link to={"/login"} >
					<ListItem>
						<ListItemText>
						Connexion							
						</ListItemText>
					</ListItem>
				</Link>
				}			
			</Menu>
		);

		let sizeCart = 0
		Object.values(CART).forEach( seller => (sizeCart += size(Object.keys(seller))))

		return (
			<Paper square>
				<Box p={1} variant="h6" color="inherit" className={`${classes.nav}`}>
					<div className={classes.sectionMobile}>
						<SideBarMenu {...this.props} links={commonLinks} />
					</div>

					<Box className={classes.navTitle}>
						<img src={logo} className="img-responsive" alt="" onClick={this.goToHomePage}/>
					</Box>
					
					<Link className="" to="/cart">
						<IconButton>
							<Badge color="secondary" badgeContent={sizeCart}>
								<ShoppingCartRounded />
							</Badge>
						</IconButton>
					</Link>
					<IconButton
						aria-owns={
							isMenuOpen
								? "material-appbar"
								: undefined
						}
						aria-haspopup="true"
						onClick={this.handleProfileMenuOpen}
						color="inherit"
					>
						<AccountCircle />
					</IconButton>
				</Box>
				{/* <Box style={{padding: "0 20px"}} > */}
					<Divider variant="middle" />
				{/* </Box> */}
				
				<Box className={`${classes.sectionDesktop}`} >

					<Box className={classes.appBar} m={1} >
						{commonLinks.map( button => (
							<Link to={button.to}>
								<Button 
									className={`${(this.props.currentPage === button.page) 
									? classes.secondary: ""} ${classes.buttonNav}`}>{button.default}</Button>
							</Link>
						))
						}
					</Box>					
					{userMenu}
				</Box>					
			</Paper>
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth,
		isAuthenticated: state.auth.isAuthenticated,

		// // currentPage: state.page.current,
		USER: state.auth.userInfo,
		CART: state.cart,
	};
}
function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			// changePage: changePage,
			// setLocale: setLocale,
			logout,
		},
		dispatch
	);
}
Header.propTypes = {
	classes: PropTypes.object.isRequired
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(Header));
