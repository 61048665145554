import React, { Component } from 'react';
import { isEmpty, size } from 'lodash';
import { Link } from 'react-router-dom'

import {
    Button,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableFooter,
    TablePagination,
    IconButton,
    Box,
    TextField,
} from '@material-ui/core';

import { MoreVert, EditRounded, InfoRounded } from '@material-ui/icons';

import Pagination from '../../../container/MUI/Pagination';
import ProductForm from './ProductForm';
import { StrippedTable } from '../../../container/MUI/StrippedTable';
class ProductsList extends Component {
    state = { 
        page: 0,
        rowsPerPage: 20,
        openForm: false,
        product: {},
        search: ""
    }

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ page: 0, rowsPerPage: event.target.value });
    };

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    handleChange = (e) => {
        const { name, value } = e.target;
		this.setState({ 
            [name]: value,
        });
    };    
    handleClose = () => {
		this.setState({ 
            openForm: false,
        });
    };    

    filterProducts = () => {
        const { search } = this.state;
        let list = Object.values(this.props.PRODUCTS)
        
        if(!isEmpty(list) && !isEmpty(search)){
            let searching = search.toLowerCase()
            
            list = list.filter( product => {
                let name = product.name ? product.name.toLowerCase() : ""
                let type = product.type ? product.type.toLowerCase() : ""
                let marque = product.marque ? product.marque.toLowerCase() : ""
                let product_title = product.product_title ? product.product_title.toLowerCase() : ""

                return(
                    name.includes(searching) ||
                    product_title.includes(searching) ||
                    type.includes(searching) ||
                    marque.includes(searching)
                )
            })
            console.log('====================================');
            console.log("list:", list.length);
            console.log('====================================');
        }

        return list
    }

    // filterProductsMulti = () => {
    //     const { search } = this.state;
    //     let list = Object.values(this.props.PRODUCTS)
    //     let filteredList = []
        
    //     if(!isEmpty(list) && !isEmpty(search)){
    //         let searchList = search.toLowerCase().split(" ")
            
    //         list.forEach( product => {
    //             let name = product.name ? product.name.toLowerCase() : ""
    //             let type = product.type ? product.type.toLowerCase() : ""
    //             let marque = product.marque ? product.marque.toLowerCase() : ""
    //             let product_title = product.product_title ? product.product_title.toLowerCase() : ""
                
    //             searchList.forEach( searching => {
    //                 if(
    //                     name.includes(searching) ||
    //                     product_title.includes(searching) ||
    //                     type.includes(searching) ||
    //                     marque.includes(searching)
    //                 ) {
    //                     filteredList.push(product)
    //                     return 
    //                 }
    //             })
    //         })
    
                        
    //         console.log('====================================');
    //         console.log("length:", filteredList.length);
    //         console.log("list:", filteredList);
    //         console.log('====================================');
    //     }

    //     return list
    // }
    getProductList = () => {
        const { PRODUCTS, STOCKS } = this.props
        const { page, rowsPerPage } = this.state;
        // const roles = ["", "PRODUCT", "ADMIN", "SUPERADMIN"]
        let list = this.filterProducts()

        if (!isEmpty(PRODUCTS)){
            return list.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(product => {

                return (
                    <StrippedTable className="">
                        {/* <TableCell>{product.ref}</TableCell> */}
                        <TableCell>{product.name}</TableCell>
                        <TableCell>{product.product_title}</TableCell>
                        <TableCell>{product.marque}</TableCell>
                        {/* <TableCell>{product.product_desc}</TableCell> */}
                        <TableCell>{STOCKS[product.id] || 0}</TableCell>
                        <TableCell>
                            <Link to={`/product/details/${product.id}`} >
                                <IconButton aria-label="Info" color="primary" title="Voir">
                                    <InfoRounded fontSize="small" color="primary"/>
                                </IconButton>
                            </Link> 
                        </TableCell>
                        {/* <TableCell>{product.productname}</TableCell>
                        <TableCell>{roles[product.product_role]}</TableCell> */}
                        
                        {/* {isSuper &&
                            <TableCell>

                                <IconButton  component="span" aria-label="Edit" onClick={()=>this.setState({
                                    productID: product.id,
                                    product: product,
                                    openForm: true
                                })}>
                                    <EditRounded fontSize="small" color="primary"/>
                                </IconButton>
                            </TableCell>
                        } */}
                    </StrippedTable>
                )
            })
        }
    }

    render() {
        const { PRODUCTS } = this.props;
        const { page, rowsPerPage, openForm, product } = this.state;

        let list = this.getProductList()
        let nbProd = this.filterProducts()
        return (
            <div>
                {openForm &&
                    <ProductForm open={openForm} oldProduct={product} handleClose={this.handleClose} {...this.props} />
                }
                <Typography variant="h6" id="tableTitle">
                    Liste des Produits
                </Typography>

                <Box p={2}>
                    <TextField 
                        variant="outlined" 
                        margin="normal" 
                        name="search" 
                        id="search" 
                        value={this.state.search} 
                        label="Rechercher" 
                        placeholder="Rechercher" 
                        onChange={this.handleChange}
                        style={{width:"100%"}} 
                    />
                </Box>

                <Table>
                    <TableHead>
                        <TableRow>
                            {/* <TableCell>Référence</TableCell> */}
                            <TableCell>Nom</TableCell>
                            <TableCell>Titre</TableCell>
                            <TableCell>Marque</TableCell>
                            {/* <TableCell>Description</TableCell> */}
                            <TableCell>Quantité</TableCell>
                            <TableCell>Détails</TableCell>
                            
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {list}
                    </TableBody>
                    {size(nbProd) > 10 &&
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[20, 30]}
                                    colSpan={7}
                                    count={size(nbProd)}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                    ActionsComponent={Pagination}
                                />
                            </TableRow>
                        </TableFooter>
                    }
                </Table>
            </div>
        
        );
    }
}

export default ProductsList;