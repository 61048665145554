import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { Link } from "react-router-dom";

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { isEmpty } from 'lodash';
import { deleteFromCart } from '../../store/actions/cartAction'

import Basic from '../../container/layouts/Basic';

import {
    Paper,
    Button,
    Grid,
    Avatar,
    IconButton,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Box,

} from '@material-ui/core';

import { Delete } from '@material-ui/icons';
import CurrencyFormat from 'react-currency-format';
import Currency from '../common/Currency';

const styles = theme => ({
    fab: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    bigAvatar: {
        // margin: theme.spacing(1),
        width: 80,
        height: 80,
      },
    cart: {
        color: "black",
        fontSize: "20px",
        padding: "20px 10px",
        display: "block",
    },
    bold: {
        fontWeight: 600
    },
    root: {
        padding: theme.spacing(3, 2),
    },
});

class EmptyCart extends Component {
    render(){
        return(
            <div className="container">
                <div>
                    <h4>Votre Panier est Vide</h4>
                    {/* <Button variant="contained" color="primary" >Login</Button> */}
                </div>
            </div>
        )
    }
}

class Cart extends Component {
    state = { }

    deleteItem = async(IDProduct, accountID) => {
        await this.props.deleteFromCart(IDProduct, accountID)
        this.setState({
            reload : this.state.reload += 1
        })
    }
    handleClose = () => {
		this.setState({ open: false, openUpdate: false });
	};
    render() {
        const { CART, classes, CLASSES } = this.props;
        
        let total = 0;
        return (
            <Basic page="cart" {...this.props} >
                
                <div>
                    {isEmpty(CART) &&  <EmptyCart />}
                    {!isEmpty(CART) &&
                        <Grid container spacing={2}>
                            <Grid item xs={12}><Typography variant="h5" align="center">Liste de vos articles</Typography></Grid>
                            {/* <hr/> */}
                            <Grid item md={8} sm={12} style={{overflow: 'auto'}}>
                                <Box p={1} elevation={0}>
                                    <Table>
                                        <TableBody>
                                            { Object.keys(CART).map(seller => {
                                                let subTotal = 0
                                                return (
                                                    <Fragment>
                                                        {/* <TableRow>
                                                            <TableCell className={CLASSES.primary} colSpan={2} >Seller Name Here</TableCell>
                                                        </TableRow> */}
                                                        
                                                        {Object.values(CART[seller]).map(article => {
                                                        
                                                            subTotal += (article.product.prix_venteTTC * article.quantity)
                                                            total += (article.product.prix_venteTTC * article.quantity)
                                                            return ( 
                                                                article.product &&
                                                                <Fragment>
                                                                    <TableRow key={article.product.id} className={CLASSES.paper}>
                                                                        <TableCell align="left">
                                                                            <Link to={`/product/${article.product.id}`}>
                                                                                <Avatar 
                                                                                    alt={article.name} 
                                                                                    src={article.product.mainPhoto} 
                                                                                    className={classes.bigAvatar}
                                                                                />
                                                                            </Link>
                                                                        </TableCell>
                                                                        <TableCell >                                                
                                                                            <Grid container>
                                                                                <Grid item xs={8}>
                                                                                    <Link to={`/product/details/${article.product.id}`}>
                                                                                        <Typography 
                                                                                            color="textPrimary" 
                                                                                            className={classes.bold} 
                                                                                        >
                                                                                            {article.product.name}
                                                                                        </Typography>
                                                                                    </Link>
                                                                                </Grid>
                                                                                <Grid item xs={4} align="right">
                                                                                    {/* <IconButton 
                                                                                        color="primary" 
                                                                                        component="span" 
                                                                                        aria-label="Edit" 
                                                                                        onClick={()=>this.setState({
                                                                                            productID: article.product.id,
                                                                                            openUpdate: true
                                                                                        })}
                                                                                    >
                                                                                        <Edit fontSize="small"/>
                                                                                    </IconButton> */}
                                                                                    
                                                                                    <IconButton 
                                                                                        color="secondary" 
                                                                                        aria-label="Delete" 
                                                                                        onClick={()=> this.deleteItem(article.product.id, seller)}
                                                                                    >
                                                                                        <Delete fontSize="small"/>
                                                                                    </IconButton>
                                                                                </Grid>
                                                                                <Grid item xs={12}>
                                                                                    <Typography variant="button">
                                                                                        {article.quantity} X <CurrencyFormat value={article.product.prix_venteTTC} displayType={'text'} thousandSeparator={" "} suffix={' FCFA'} />
                                                                                    </Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                            
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </Fragment>
                                                            )})
                                                        }
                                                        <TableRow className={CLASSES.paper}>
                                                            <TableCell colSpan={2} style={{ textAlign: "right"}} className={classes.bold} >Sous-Total : {<Currency value={subTotal} />} </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell colSpan={2} className={CLASSES.default}></TableCell>
                                                        </TableRow>
                                                    </Fragment>
                                                    )
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                </Box>
                            </Grid>
                            
                            <Grid item md={4} sm={12} style={{width:'100%'}}>
                                <Paper className={classes.root}>
                                    <Table className="table">
                                        <TableBody>
                                            <TableRow>
                                                <TableCell><Typography variant="h6">Total</Typography></TableCell>
                                                <TableCell><Typography variant="h6"><Currency value={total} /></Typography></TableCell>
                                            </TableRow>

                                        </TableBody>
                                    </Table>
                                    <Button 
                                        variant="contained" 
                                        color="secondary" 
                                        style={{width:'100%', color: "white"}}
                                        component={Link} 
                                        to="/checkout"
                                        disabled={total <= 0}
                                    >
                                        Payer
                                    </Button>
                                    
                                </Paper>
                            </Grid>
                            
                            
                        </Grid>
                    }
                </div>
                
            </Basic>
        );
    }
}

Cart.propTypes = {
    classes: PropTypes.object.isRequired,
};
function mapStateToProps(state){
    return {
        CART: state.cart,
        PRODUCTS: state.products,
        USER: state.auth.userInfo,
        
    }
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({
        // updateAccount,
        deleteFromCart,
    }, dispatch)
}
export default connect( mapStateToProps, mapDispatchToProps )(withStyles(styles)(Cart));



