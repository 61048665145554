import React, { Component } from 'react';
import { Box, Typography, Paper } from '@material-ui/core';
import Spinner from '../../common/Spinner';

class SellerBlockEmpty extends Component {
    state = {  }
    render() {
        return (
            <Box p={1}>
                <Paper elevation={0} square>
                    <Box style={{height: "200px"}}> 
                        <Typography variant="h6">Vendu Par ...</Typography>
                    </Box>
                </Paper>
            </Box>
        );
    }
}
export class SellerBlock extends Component {
    state = {  }
    render() {
        return (
            <Box p={1}>
                <Paper elevation={0} square>
                    <Box style={{height: "200px"}}> 
                        <Typography variant="h6">Vendu Par {this.props.company_name}</Typography>
                    </Box>
                </Paper>
            </Box>
        );
    }
}

export default SellerBlockEmpty;