import React, { Component } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { fetchProducts } from '../../store/actions/productAction'
import { fetchStocks } from '../../store/actions/stockAction'
import { getCountriesList, getCountriesAccounts, setAdminAccount } from '../../store/actions/countryAction'

import { isEmpty } from "lodash";
import { Box } from '@material-ui/core';
import CountryList from '../../components/Country/CountryList';

class GeneralContainer extends Component {
    state = { 
        open: true,
        country: ""
    }

    componentDidMount = () => {
        if(isEmpty(this.props.PRODUCTS) || isEmpty(this.props.STOCKS)){
            
            if( this.props.user_role === 2) {
                this.props.setAdminAccount(this.props.user.account_id)
            } else {
                this.props.getCountriesList()
            }

        }

	}	

    selectCountry = async () => {
        if (!isEmpty(this.state.country) ) {
            this.props.getCountriesAccounts(this.state.country)
        }
        //else  country = Benin
    }

    handleChange = e => {
        const { name, value} = e.target;
        this.setState({
            [name]: value
        })
    }
    handleClose = () => {
        this.setState({
            open: false
        })
    }

    render() {
        const { STATE, ACCOUNTS, page, user_role } = this.props;
        return (
            <Box>
                {this.props.children}
                {page !== "login" &&
                STATE.countries.fetched && 
                isEmpty(ACCOUNTS) &&
                user_role !== 2 &&

                    <CountryList 
                        {...this.props} 
                        {...this.state} 
                        handleChange={this.handleChange} 
                        handleClose={this.handleClose} 
                    />
                }
            </Box>  
        );
    }
}

function mapStateToProps(state){// transform elements from store's state into props in this component
    return {
        PRODUCTS: state.products,
        STOCKS: state.stocks,
        COUNTRIES: state.countries.COUNTRIES,
        ACCOUNTS: state.countries.ACCOUNTS,
        COUNTRY: state.countries.COUNTRY,
        STATE: state.request,
        user_role: state.auth.user.role,
        user: state.auth.user,

    }
}
function mapDispatchToProps(dispatch){
    return bindActionCreators({
        fetchProducts,
        fetchStocks,
        setAdminAccount,
        getCountriesList,
        getCountriesAccounts
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneralContainer);