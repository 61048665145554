import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { isEmpty } from "lodash"

import { 
    Typography, 
    Paper,
    Box,
 } from '@material-ui/core';

import noImage from "../../images/no_image.png"

import Currency from '../common/Currency';

class CardProduct extends Component {
    state = {  }
    render() {
        const { product, CLASSES, index } = this.props;
        let product_title = product.product_title || product.name
        let backgrounds = ["#f9fbe7", "#fffde7", "#fff8e1"]
        return (
            <Box > 
            {/* height: "500px" */}
                <Link to={`/product/details/${product.id}`} title={product_title}>
                <Paper variant="outlined" className="card-product" style={{background: "white"}}>
                {/* <Paper variant="outlined" style={{background: "white"}}> */}

                    <Box p={1} >
                        <Box className={CLASSES.media}>
                            <img className="img-miniature" src={(!isEmpty(product.photos) ? product.mainPhoto : noImage)} alt={product_title} title={product_title} />
                        </Box>

                    </Box>
                    <Box p={1}>
                        <Typography variant="subtitle2" noWrap className={CLASSES.cardPrice}>
                            {product_title}
                        </Typography>
                        <Box>
                            <Typography variant="body2" color="textSecondary" component="p">
                                {product.marque}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant="button" component="p">
                                <Currency value={product.prix_venteTTC} />
                            </Typography>
                        </Box>
                    </Box>
                </Paper>
          
                </Link>
            </Box>
        );
    }
}

export default CardProduct;