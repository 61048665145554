import React, { Component, Fragment } from 'react';
import { PropTypes } from "prop-types";
import { withRouter } from 'react-router-dom';

import { withStyles } from "@material-ui/core/styles";

import { 
	deepOrange, 
	deepPurple,
	orange,
	purple,
	indigo,
	lightBlue,
	cyan,
	teal,
	green,
	lightGreen,
	lime,
	yellow,
	amber,
	blueGrey,
	blue,
	pink,
	red,
	// black
} from '@material-ui/core/colors';
import Notif from '../common/Notif';

const colorStyle = theme => ({
	black: {
		color: "white",
		backgroundColor: "black",
	},
	deepOrange: {
		color: theme.palette.getContrastText(deepOrange[500]),
		backgroundColor: deepOrange[500],
	},
	deepPurple: {
		color: theme.palette.getContrastText(deepPurple[500]),
		backgroundColor: deepPurple[500],
	},
	orange: {
		color: theme.palette.getContrastText(orange[500]),
		backgroundColor: orange[500],
	},
	purple: {
		color: theme.palette.getContrastText(purple[500]),
		backgroundColor: purple[500],
	},
	indigo: {
		color: theme.palette.getContrastText(indigo[500]),
		backgroundColor: indigo[500],
	},
	lightBlue: {
		color: theme.palette.getContrastText(lightBlue[100]),
		backgroundColor: lightBlue[100],
	},
	cyan: {
		color: theme.palette.getContrastText(cyan[500]),
		backgroundColor: cyan[500],
	},
	lightCyan: {
		color: theme.palette.getContrastText(cyan[200]),
		backgroundColor: cyan[200],
	},
	teal: {
		color: theme.palette.getContrastText(teal[500]),
		backgroundColor: teal[500],
	},
	green: {
		color: theme.palette.getContrastText(green[500]),
		backgroundColor: green[500],
	},
	lightGreen: {
		color: theme.palette.getContrastText(lightGreen[500]),
		backgroundColor: lightGreen[500],
	},
	lime: {
		color: theme.palette.getContrastText(lime[500]),
		backgroundColor: lime[500],
	},
	yellow: {
		color: theme.palette.getContrastText(yellow[500]),
		backgroundColor: yellow[500],
	},
	lightYellow: {
		color: theme.palette.getContrastText(yellow[50]),
		backgroundColor: yellow[50],
	},
	amber: {
		color: theme.palette.getContrastText(amber[500]),
		backgroundColor: amber[500],
	},
	lightAmber: {
		color: theme.palette.getContrastText(amber[200]),
		backgroundColor: amber[200],
	},
	blueGrey: {
		color: theme.palette.getContrastText(blueGrey[500]),
		backgroundColor: blueGrey[500],
	},
	lightBlueGrey: {
		color: theme.palette.getContrastText(blueGrey[100]),
		backgroundColor: blueGrey[100],
	},
	blue: {
		color: theme.palette.getContrastText(blue[500]),
		backgroundColor: blue[500],
	},
	pink: {
		color: theme.palette.getContrastText(pink[500]),
		backgroundColor: pink[500],
	},
	lightPink: {
		color: theme.palette.getContrastText(pink[50]),
		backgroundColor: pink[50],
	},
	darkPink: {
		color: theme.palette.getContrastText(pink[900]),
		backgroundColor: pink[900],
	},
	red: {
		color: theme.palette.getContrastText(red[500]),
		backgroundColor: red[500],
	},
	primary: {
		color: theme.palette.getContrastText(theme.palette.primary.light),
		backgroundColor: theme.palette.primary.light,
	},
	secondary: {
		color: theme.palette.getContrastText(theme.palette.secondary.light),
		backgroundColor: theme.palette.secondary.light,
	},
	default: {
		color: theme.palette.getContrastText(theme.palette.background.default),
		backgroundColor: theme.palette.background.default,
	},
	paper: {
		color: theme.palette.getContrastText(theme.palette.background.paper),
		backgroundColor: theme.palette.background.paper,
	},

	// BREAKPOINTS
	sectionDesktop: {
		display: "none",
		[theme.breakpoints.up("md")]: {
			display: "flex"
		}
	},
	sectionMobile: {
		[theme.breakpoints.up("md")]: {
			display: "none"
		}
	},
	sectionMobileXs: {
		[theme.breakpoints.up("sm")]: {
			display: "none"
		}
	},

	// HOMEPAGE

	transparentBckg: {
		height: "100%",
		width: "100%",
		background: "rgba(255, 255, 255, 0.5)",
		padding: "15px",
		display: "flex",
		borderRadius: "4px"
	},
	transparentBckgText: {
		margin: "auto",
		background: "rgba(255, 255, 255, 0.7)",
		padding: "5px",
		textAlign: "center"
	},

	// FORM 
    textField: {
		marginTop: theme.spacing(2),
        // marginLeft: theme.spacing(1),
        // marginRight: theme.spacing(1),
        // marginBottom: theme.spacing(1),
        width: '100%',
    },
    wideButton: {
		width: '100%',
		padding: theme.spacing(3)
    },

    formControl: {
        width: "100%",
    },
    group: {
        flexDirection: 'row',
    },
    selectOption: {
        marginTop: theme.spacing(1),
	},
	
	cardButton:{
		height: 140,
		padding: 10,
		textAlign: "center",
		margin: "auto",
	},
	aLike:{
		color: theme.palette.primary.dark,
		fontWeight: 700,
		cursor: "pointer"
	},
	cardCell: {
		height: "70px",
		textAlign: "center",
		// border: "1px solid lightgrey",
		lineHeight: "70px",
		position: "relative",
	},
	cardCellNum: {
		position: "absolute",
		top: "-20px",
		left: "8px",
		fontSize: "small",
	},
	cardCellText: {
		transform: "rotate(-20deg)",
		display: "block",
		padding: "15px",
		fontWeight: "bold",
		fontSize: "18px",
	},
	nested: {
		paddingLeft: theme.spacing(4),
	},

	media: { // card media
		height: 200,
		position: "relative",
	},
	cardPrice: { // card media
		// fontSize: 20,
		fontWeight: 600,
	},
	miniature: { 
		height: 100,
		width: 100,
	},

});

export default function(ComposedComponent) {

    class ClassesProps extends Component {
		state = {        
			notif: {
				openNotif: false,
				variant:"",
				message: "",
			}
		}
		notif = (variant, message) => {
			this.setState({
				notif:{
					openNotif: true,
					variant,
					message
				}
			})
		}
		handleCloseNotif = (event, reason) => {
			this.setState({ 
				notif: {
						openNotif: false,
						variant:"",
						message: "",
				}
			});
		}
        render() {
			const { variant, message, openNotif } = this.state.notif;

			return (
				<Fragment>
					{openNotif && variant && message &&
						<Notif open={true} {...this.state.notif} onClose={this.handleCloseNotif} />
					}
					<ComposedComponent CLASSES={this.props.classes} {...this.props} notif={this.notif} />
				</Fragment>
			);
        }
    }
    
    ClassesProps.propTypes = {
        classes: PropTypes.object.isRequired,
        theme: PropTypes.object.isRequired,    
    }

    return withStyles(colorStyle, { withTheme: true })(withRouter(ClassesProps));
}
