export function countryReducer(state = {
    COUNTRIES: [],
    ACCOUNTS: [],
    country: ""
} ,{
    type,
    payload
}){
    
    switch(type){
        case "GET_COUNTRIES":   
            let list = Object.assign({}, state)
            list.COUNTRIES = payload         
            return list
            
        case "GET_ACCOUNTS":            
            let list2 = Object.assign({}, state)
            list2.ACCOUNTS = payload

            let key = `MJBSALE_accounts`
            localStorage[key] = JSON.stringify(payload)     
            return list2

        case "SET_ACCOUNTS":  
            let list3 = Object.assign({}, state)
            list3.ACCOUNTS = payload      
            return list3
        
        case "SELECT_COUNTRY":            
            let list4 = Object.assign({}, state)
            list4.country = payload         
            return list4

        case "SET_ADMIN_ACCOUNT":            
            let list5 = Object.assign({}, state)
            list5.ACCOUNTS = payload         
            return list5
            
        default:
            return state;
    }
    
}