import React, { Component } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { isEmpty } from "lodash";
import { fetchProducts, fetchProductsType, getAllPhotos } from '../../store/actions/productAction'
import { addInCart } from '../../store/actions/cartAction'

import GridProducts from './GridProducts';
import SingleProduct from './SingleProduct';
import Basic from '../../container/layouts/Basic';
import Notif from '../common/Notif';
import { Box } from '@material-ui/core';

class HomeProducts extends Component {
    state = { 
        notif:{
            variant: "",
            message:"",
            openNotif: false,
        }
    }
    notif = (variant, message) => {
        console.log('==================NOTIF==================');
        console.log(variant, message);
        console.log('====================================');
        this.setState({
            notif:{
                openNotif: true,
                variant,
                message
            }
        })
    }
    handleCloseNotif = (event, reason) => {
        this.setState({ 
            notif: {
                openNotif: false,
                variant:"",
                message: "",
            }
        });
    };
    render() {
        const { page, ID, type } = this.props.match.params;
        const { variant, message, openNotif } = this.state.notif;
        
        console.log('====================================');
        console.log({ page, ID, type });
        console.log({ props: this.props });
        console.log('====================================');
		return (
            <Basic {...this.props} page={type || page} >
                <Box p={2} >
                    {openNotif && variant && message &&
                        <Notif open={true} {...this.state.notif} onClose={this.handleCloseNotif} />
                    }
                    {page === "home" && <GridProducts {...this.props} /> }
                    {page === "details" && !isEmpty(ID) && <SingleProduct {...this.props} productID={ID} notif={this.notif} />}
                    {page === "type" && <GridProducts {...this.props} type={type} /> }
                </Box>
            </Basic>
		);
    }
}

function mapStateToProps(state){// transform elements from store's state into props in this component
    return {
        PRODUCTS: state.products,
        CAT: state.categories,
        STOCKS: state.stocks,
        STATE: state.request,
    }
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({
        fetchProducts,
        fetchProductsType,
        addInCart,
        getAllPhotos,
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeProducts);