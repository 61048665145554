export function countryRequest(state = {
    fetching:false,
    fetched: false,
    error:null,
},{
    type,
    payload
}){
    
    switch(type){
        case "FETCH_COUNTRIES_START":           
        case "FETCH_ACCOUNTS_START":           
        case "SET_ACCOUNTS_START":           
            return {
                ...state, 
                fetching:true,
            }        
        case "FETCH_COUNTRIES_FULFILLED":           
        case "FETCH_ACCOUNTS_FULFILLED":           
        case "SET_ACCOUNTS_FULFILLED":           
            return {
                ...state,
                fetching:false, 
                fetched:true, 
                error: null
            }    
        case "FETCH_COUNTRIES_ERROR":
        case "FETCH_ACCOUNTS_ERROR":
        case "SET_ACCOUNTS_ERROR":
            return {
                ...state,
                fetching:false, 
                error:payload
            }
        default:
            return state;
    }
    
}