import React from 'react'
import Container from '@material-ui/core/Container';
import { Box } from '@material-ui/core';

class Page extends React.Component{

    render(){
        return(
            <Container maxWidth="fixed">
                <div className="min-view container">
                    <Box p={1}>
                        {this.props.children}
                    </Box>
                    
                </div>
            </Container>
            
        )
    }
}

export default Page;


