import React from 'react'
import Header from './Header';
import Footer from './Footer';
import CssBaseline from '@material-ui/core/CssBaseline';
import GeneralContainer from './GeneralContainer';

class FullPage extends React.Component{
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render(){
        const { page } = this.props;
        let bckg = ""
        if(page === "login" || page === "home"){
            bckg = "gradient-purple"
        }
        return(
            <GeneralContainer page={page}>
                <div className={bckg}>
                <CssBaseline />
                    <Header currentPage={page}/>
                        <div className="min-height">
                            {this.props.children}
                        </div>
                    <Footer {...this.props} />
                </div>

            </GeneralContainer>
        )
    }
}

export default FullPage;
