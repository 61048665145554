import { combineReducers } from 'redux';
import { userReducer } from './userReducer';
import { productReducer } from './productReducer';
import { productCatReducer } from './productCatReducer';
import { cartReducer } from './cartReducer';
import { countryReducer } from './countryReducer';
import { stockReducer } from './stockReducer';
import { orderReducer } from './orderReducer';
import { companyReducer } from './companyReducer';
import { authReducer } from './authReducer';
import requestReducer from './requestState/requestReducer';


export const initialState = {

};
export const appReducer = combineReducers({
    users: userReducer,
    products: productReducer,
    categories: productCatReducer,
    cart: cartReducer,
    company: companyReducer,
    countries: countryReducer,
    stocks: stockReducer,
    orders: orderReducer,
    auth: authReducer,
    request: requestReducer

});

export const rootReducer = (state, action) => {
    if (action.type === 'USER_LOGOUT') {
        state = undefined
    }

    return appReducer(state, action)
}

