import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Stepper, Step, StepLabel } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Check from '@material-ui/icons/Check';
import SettingsIcon from '@material-ui/icons/Settings';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import VideoLabelIcon from '@material-ui/icons/VideoLabel';
import StepConnector from '@material-ui/core/StepConnector';
import { LocalShippingOutlined, CheckRounded } from '@material-ui/icons';

const QontoConnector = withStyles({
    alternativeLabel: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    active: {
        '& $line': {
            borderColor: '#ffd54f',
        },
    },
    completed: {
        '& $line': {
            borderColor: '#ffd54f',
        },
    },
    line: {
        borderColor: '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
    },
})(StepConnector);
  
const useQontoStepIconStyles = makeStyles({
    root: {
        color: '#eaeaf0',
        display: 'flex',
        height: 22,
        alignItems: 'center',
    },
    active: {
        color: '#ffd54f',
    },
    circle: {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
    completed: {
        color: '#ffd54f',
        zIndex: 1,
        fontSize: 18,
    },
});
  
function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles();
    const { active, completed } = props;
  
    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
            })}
        >
            {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
        </div>
    );
}
  
  QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
  };
  
class OrderStepper extends Component {
    state = {  }
    render() {
        const { ORDERSTATUS, status} = this.props;
        return (
            <Stepper activeStep={status} alternativeLabel connector={<QontoConnector />}>
                {Object.keys(ORDERSTATUS).map( (label, index) => (
                    <Step key={label}>
                    {index === status ? (
                        <StepLabel StepIconComponent={QontoStepIcon}>{ORDERSTATUS[label].text}</StepLabel>
                    ) : (
                        <StepLabel StepIconComponent={QontoStepIcon}></StepLabel>
                    )
                    }
                    </Step>
                ))}
            </Stepper>
        );
    }
}

export default OrderStepper;