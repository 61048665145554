import React,{ Component } from "react";

import { Button, TextField }from '@material-ui/core';


class Register extends Component{
    state = {
        open:false,
        openRGPD:false,
    }

    render(){
        // let label = (
        //     <Fragment>
        //     J'ai lu et accepte les <b className="a-like" onClick={() => this.setState({open: true})}>Conditions Genérales d'Utilisation (CGU)</b>
        //     </Fragment>
        // )
        // let label2 = (
        //     <Fragment>
        //     J'accepte les <b className="a-like" onClick={()=>this.setState({openRGPD: true})}>Conditions de Gestion de Données (RGPD)</b>
        //     </Fragment>
        // )
        // let label3 = (
        //     <Fragment> Je souhaite m'inscrire à la newsletter </Fragment>
        // )
        console.log('==========PROPS REGISTER================');
        console.log(this.props);
        console.log('====================================');
        return(
            <div>

                <div>
                    <TextField
                        style={{width:"100%"}}
                        required
                        margin="normal"
                        variant="outlined"  
                        name="email" 
                        id="email" 
                        value={this.props.email} 
                        label="Email" 
                        placeholder="Email ici" 
                        error={!!this.props.errors.email} 
                        helperText={this.props.errors.email} 
                        onChange={this.props.handleChange} 
                    />

                    <TextField 
                        style={{width: "100%"}}
                        required
                        margin="normal"
                        variant="outlined"
                        name="lastname" 
                        value={this.props.lastname} 
                        placeholder="Nom ici" 
                        label="Nom" 
                        error={!!this.props.errors.lastname} 
                        helperText={this.props.errors.lastname} 
                        onChange={this.props.handleChange} 
                    />

                    <TextField 
                        style={{width: "100%"}}
                        required
                        margin="normal"
                        variant="outlined"
                        name="username" 
                        value={this.props.username} 
                        placeholder="Prénom ici" 
                        label="Prénom" 
                        error={this.props.errors.username} 
                        helperText={!!this.props.errors.username} 
                        onChange={this.props.handleChange} 
                    />

                    <TextField
                        style={{width:"100%"}}
                        required
                        margin="normal"
                        variant="outlined"  
                        type="password"
                        name="password" 
                        id="password" 
                        value={this.props.password} 
                        label="Mot de Passe" 
                        placeholder="Mot de Passe ici" 
                        error={!!this.props.errors.password} 
                        helperText={this.props.errors.password} 
                        onChange={this.props.handleChange} 
                    />

                    <TextField 
                        style={{width: "100%"}}
                        required
                        margin="normal"
                        variant="outlined"
                        type="password" 
                        name="password2" 
                        id="password2" 
                        value={this.props.password2} 
                        label="Confirmation Mot de Passe" 
                        placeholder="Confirmer le mot de passe" 
                        error={!!this.props.errors.password2} 
                        helperText={this.props.errors.password2} 
                        onChange={this.props.handleChange} 
                    />
                    
                    {/* <InputCheck handleChange={this.props.handleChange} name="accept" value="" checked={this.state.accept} label={label} error={this.props.errors.accept} />
                    <InputCheck handleChange={this.props.handleChange} name="rgpd" value="" checked={this.state.rgpd} label={label2} error={this.props.errors.rgpd} />
                    <InputCheck handleChange={this.props.handleChange} name="newsletter" value="" checked={this.state.newsletter} label={label3} error={this.props.errors.newsletter} /> */}
                </div>
                <div className="text-center" >
                    <Button 
                        color="secondary" 
                        variant="contained" 
                        size="large" 
                        onClick={() => this.props.handleSubmit("register")}
                    >
                        S'inscrire
                    </Button>
                </div>
            </div>
        )
    }
}
export default Register