import axios from 'axios'
import  { checkErrorData } from './authAction'
import  { cleanCart } from './cartAction'
import  { formatList } from './format'

export function newOrder(data) {
    return async function(dispatch){
        try{
            dispatch({ type: "CREATE_ORDER_START", payload: "" })
            console.log("new Order START", {data})
            const res = await axios.post('/orders/new',{data})
            console.log({res});

            if(checkErrorData(res, "CREATE_ORDER_ERROR", dispatch ) ) {
                dispatch({ type: "NEW_ORDER", payload: formatList(res.data.orders, "order_id") })
                dispatch({ type: "CREATE_ORDER_FULFILLED", payload: "" })
                dispatch(cleanCart())
            }
            
        } catch(err) {
            console.log("[ERROR] new order ", err);
            dispatch({
                type: "CREATE_ORDER_ERROR",
                payload: err
            })
        }
    }
}

export function fetchOrders() {
    return async function(dispatch){
        try{
            dispatch({ type: "FETCH_ORDERS_START", payload: "" })

            console.log("fetch Order START")
            const res = await axios.get('/orders/get/all')
            console.log({res});
            
            if(checkErrorData(res, "FETCH_ORDERS_ERROR", dispatch ) ) {
				dispatch({
                    type: "GET_ORDERS",
                    payload: formatList(res.data, "order_id")
                })
				dispatch({ type: "FETCH_ORDERS_FULFILLED", payload: "" })
			}
        } catch(err) {
            console.log("[ERROR] get orders list ", err);
            dispatch({
                type: "FETCH_ORDERS_ERROR",
                payload: err
                
            })
        }
    }
}
export function fetchUserOrders() {
    return async function(dispatch, getState){
        try{
            dispatch({ type: "FETCH_ORDERS_START", payload: "" })
            let userID = getState().auth.user.id 
            console.log("fetch Order START")
            const res = await axios.post(`/orders/user/get/all`, {userID})
            console.log({res});
            
            if(checkErrorData(res, "FETCH_ORDERS_ERROR", dispatch ) ) {
				dispatch({
                    type: "GET_ORDERS",
                    payload: formatList(res.data, "order_id")
                })
				dispatch({ type: "FETCH_ORDERS_FULFILLED", payload: "" })
			}
        } catch(err) {
            console.log("[ERROR] get orders list ", err);
            dispatch({
                type: "FETCH_ORDERS_ERROR",
                payload: err
                
            })
        }
    }
}
