export function orderReducer(state = "" ,{
    type,
    payload
}){
    
    switch(type){
        case "NEW_ORDER":            
        // case "UPDATE_ORDER":
            let list = Object.assign({}, state);
            Object.assign(list, payload) // -> payload may have multiple orders
        
            return list
            
        case "GET_ORDERS":
            return payload
            
        default:
            return state;
    }
    
}