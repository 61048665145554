import axios from 'axios'

export default function setAuthToken(token, id) {
    axios.defaults.headers.common['Content-Type'] = `application/json`
    if(token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
        axios.defaults.headers.common['UserID'] = id
        
    } else {
        delete axios.defaults.headers.common['Authorization']
        
    }
}