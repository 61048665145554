import React, { Component, Fragment } from 'react';
// import Photos from '../../../../commonModules/interface/input-files';
import { isEmpty } from "lodash"
import {
    common,
    panels,
    bat,
    conv,
    conv_ch,
    conv_MPPT,
    cdc,
    reg,
    cab,
    lam,
    menager,
    pompes,
    tv,
    vent, 

    floatFields, 
    intFields,

    noUpdate,
    FIELDS, 

    types

} from "./ProductTypes.jsx"
import { 
    Box, 
    TextField, 
    Grid,
    FormHelperText,
    FormControl,
    InputLabel,
    Select,
    OutlinedInput, 
    FormLabel,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Radio,
    RadioGroup,

} from '@material-ui/core';

import PhotoUpload from '../../common/PhotoUpload';

class FormProduct extends Component {
    state = {
        update: this.props.update,
        sending: false,
        photos:{},

        oldProduct:this.props.oldProduct || {}, // before update
        
        product:this.props.product || {
            name: "",
            marque: "",
            type: "",
            prix_venteTTC: "",
            reduc_max: 0,
            garantie: "",
            prix_venteHT :"",
            photos: [],
            longueur:"",
            largeur:"",
            hauteur:"",
            poids:"",

            pan_tensionVoc:"", 
            pan_courantIoc:"", 
            pan_tensionVmpp:"", 
            pan_courantImpp:"", 
            pan_puissance:"", 
            pan_cell:"", 
            pan_rendement:"",

            bat_type:"", 
            bat_tension:[], 
            bat_max_capacity:"", 
            bat_nb_cycles30:"",
            bat_nb_cycles50:"",
            bat_nb_cycles80:"",
            bat_rendement:"",
            bat_temperature:"",
            bat_auto_decharge:"",

            conv_tension:[],
            conv_puissance:"",
            conv_triphase:"",
            conv_puissance25:"",
            conv_puissance40:"",
            conv_puissance65:"",
            conv_puissance_crete:"",
            conv_eff_max:"",
            conv_signal:"",

            conv_absorption:"",
            conv_float:"",
            conv_courant_service:"",
            conv_courant_start:"",

            conv_tension_sortie:"",
            conv_sortie25:"",
            conv_sortie40:"",
            conv_tension_min:"",
            conv_tension_max:"",
            
            conv_MPPT_courant_sortie:"",
            conv_MPPT_tensionPV_max:"",
            conv_MPPT_puissancePV_max:"",

            reg_tension_max:"",
            reg_puissance_pv12:"",
            reg_puissance_pv24:"",
            reg_puissance_pv48:"",
            reg_type:"",
            reg_affichage:"",
            reg_courant:"",

            cab_type:"",
            cab_nb_conducteurs:"",
            cab_section:"",

            pomp_puissance:"",

            lam_puissance:"",
            lam_flux:"",
            lam_tension:"",

            men_type:"",
            men_infos:"",
            desc:"",
            tv_puissance_vide:"",
            tv_puissance_fonc:"",
            tv_taille:"",
            tv_tuner:"",
            tv_tension_alim: [],
            vent_puissance:"",
        },
        errors:{
            name: "",
            marque: "",
            type: "",
            prix_venteTTC: "",
            reduc_max: "",
            garantie: "",
            prix_venteHT: "",
            pan_tensionVoc:"", 
            pan_courantIoc:"", 
            pan_tensionVmpp:"", 
            pan_courantImpp:"", 
            pan_puissance:"", 
            pan_cell:"", 
            pan_rendement:"",

            bat_type:"", 
            bat_tension:"", 
            bat_max_capacity:"", 
            bat_nb_cycles30:"",
            bat_nb_cycles50:"",
            bat_nb_cycles80:"",
            bat_rendement:"",
            bat_temperature:"",
            poids:"",
            bat_auto_decharge:"",

            conv_tension:"",
            conv_puissance:"",
            conv_triphase:"",
            conv_puissance25:"",
            conv_puissance40:"",
            conv_puissance65:"",
            conv_puissance_crete:"",
            conv_eff_max:"",
            conv_signal:"",

            conv_absorption:"",
            conv_float:"",
            conv_courant_service:"",
            conv_courant_start:"",

            conv_tension_sortie:"",
            conv_sortie25:"",
            conv_sortie40:"",
            conv_tension_min:"",
            conv_tension_max:"",

            conv_MPPT_courant_sortie:"",
            conv_MPPT_tensionPV_max:"",
            conv_MPPT_puissancePV_max:"",

            reg_tension_max:"",
            reg_puissance_pv12:"",
            reg_puissance_pv24:"",
            reg_puissance_pv48:"",
            reg_courant:"",
            reg_type:"",
            reg_affichage:"",

            cab_type:"",
            cab_nb_conducteurs:"",
            cab_section:"",
            
            pomp_puissance:"",

            lam_puissance:"",
            lam_flux:"",
            lam_tension:"",

            men_type:"",
            men_infos:"",
            desc:"",
            tv_puissance_vide:"",
            tv_puissance_fonc:"",
            tv_taille:"",
            tv_tuner:"",
            tv_tension_alim:"",
            vent_puissance:"",
        },

    }

    createTypeList(){ 
        const types = {
            "panneaux":"Panneaux Solaires",
            "regulateurs":"Régulateurs",
            "convertisseurs":"Convertisseurs",
            "convertisseurs_chargeurs":"Convertisseur-Chargeurs",
            "convertisseurs_chargeurs_MPPT":"Convertisseur-Chargeurs - MPPT",
            "convertisseurs_dc-dc":"Convertisseur DC-DC",
            "batteries":"Batteries",
            "cables":"Câbles",
            "lampes":"Lampes",
            "supervision":"Supervision",
            "kit":"Kits complet",
            "menager":"Equipement ménager",
            "pompes":"Pompes",
            "picos":"Picos",
            "accesoires":"Accessoires",
            "protection":"Protection",
            "autres":"Autres"
        }
        return Object.keys(types).map((key) => {
            return (        
                <option key={key} value={key} >{types[key]}</option>
            )
        })
    }

    handleChange = (e) => { 
        const field = e.target.name
        const value = e.target.value
        const regex = /^[+]?([0-9]*(\.[0-9]*)?)$/ // numbers (int & float) only
        const regex2 = /^[0-9\b]+$/ // int only

        if(floatFields.includes(field)){
            if(value ==="" || regex.test(value)){// update value only if its a int || float
                this.setState({
                    ...this.state,
                    product:{
                        ...this.state.product,
                        [field]:value
                    }
                })
            }
        } else if( intFields.includes(field)){
            if(value ==="" || regex2.test(value)){// update value only if its an int
                this.setState({
                    ...this.state,
                    product:{
                        ...this.state.product,
                        [field]:value
                    }
                })
            }
        } else if(this.state.update && noUpdate.includes(field)){
            this.setState({// don't change on update 
                ...this.state,
                product:{
                    ...this.state.product
                }
            })
        } else {
            this.setState({
                ...this.state,
                product:{
                    ...this.state.product,
                    [field]:e.target.value
                }
            })

        }
    }
    setCheckbox = e => {
        let value = parseInt(e.target.value);
        let name = e.target.name;
        console.log('LABEL : ', value);
        const tmpActions = this.state.product[name];
        const idx = tmpActions.indexOf(value);
        console.log('BEFORE ADD/DELETE : ', tmpActions, idx);
        if (idx !== -1) {
            tmpActions.splice(idx, 1);
        } else {
            tmpActions.push(value);
        }
        console.log('AFTER ADD/DELETE : ', tmpActions);
        this.setState({ 
            ...this.state,
            product:{
                ...this.state.product,
                [name]: tmpActions 
            }
        });
    }
    setPhotos = (list) => {
        console.log("[INFO] SETPHOTOS", list);
        
        this.setState({
            ...this.state,
            product:{
                ...this.state.product,
                photos:list
            }
        })
    }

    createRef(){
        const {PRODUCTS} = this.props
        let { marque, type } = this.state.product
        // ref = type_marque_id ex: PAN_VIC_1R77YB8
        marque = marque.normalize('NFD').replace(/[\u0300-\u036f]/g, "") // remove accents

        let ref = (type).substr(0,3) + "_" + (marque).substr(0,3) + "_" + (Math.random().toString(36)).substr(2,7)
        ref = ref.toUpperCase()
        console.log("[INFO] createRef " ,ref)

        if(!isEmpty){
            Object.values(PRODUCTS).map( product => {// checks if ref of product is used already
                if(product.ref === ref){// if exist
                    ref = this.createRef() // create new one
                }
            })
        }
        return ref
    }
    validate = () => {//check form data before submit
        let noErrors = true
        let errors = {}

        Object.keys(this.state.product).map( field => {
            if(this.state.product[field] === "" || this.state.product[field] === []){// input is empty  
                if(this.state.product.type === "panneaux" && panels.includes(field) ){// check pannel desc fields
                    noErrors = false
                    errors[field] = "Veuillez remplir ce champ"

                } else if (this.state.product.type === "convertisseurs" && conv.includes(field)) {
                    noErrors = false
                    errors[field] = "Veuillez remplir ce champ"

                } else if (this.state.product.type === "convertisseurs_chargeurs" && (conv.includes(field) || conv_ch.includes(field) ) ) {
                    noErrors = false
                    errors[field] = "Veuillez remplir ce champ"
                } else if (this.state.product.type === "convertisseurs_chargeurs_MPPT" && (conv.includes(field) || conv_MPPT.includes(field) ) ) {
                    noErrors = false
                    errors[field] = "Veuillez remplir ce champ"

                } else if (this.state.product.type === "convertisseurs_dc-dc" && cdc.includes(field) ) {
                    noErrors = false
                    errors[field] = "Veuillez remplir ce champ"

                } else if (this.state.product.type === "regulateurs" && reg.includes(field) ) {
                    noErrors = false
                    errors[field] = "Veuillez remplir ce champ"

                } else if (this.state.product.type === "cables" && cab.includes(field) ) {
                    noErrors = false
                    errors[field] = "Veuillez remplir ce champ"

                } else if (this.state.product.type === "batteries" && bat.includes(field)) {
                    noErrors = false
                    errors[field] = "Veuillez remplir ce champ"

                } else if (this.state.product.type === "lampes" && lam.includes(field)) {
                    noErrors = false
                    errors[field] = "Veuillez remplir ce champ"

                } else if (this.state.product.type === "menager" &&  menager.includes(field) ) {// common fields
                    noErrors = false
                    errors[field] = "Veuillez remplir ce champ"
                } else if (this.state.product.type === "pompes" &&  pompes.includes(field) ) {// common fields
                    noErrors = false
                    errors[field] = "Veuillez remplir ce champ"

                } else if ( common.includes(field) ) {// common fields
                    noErrors = false
                    errors[field] = "Veuillez remplir ce champ"

                } else {// no error
                    errors[field] = ""   
                }
            } else if(field === "name"){
                const {PRODUCTS} = this.props
                if(Object.keys(PRODUCTS).length > 0){

                    Object.keys(PRODUCTS).map( ID => {// checks if name of product is used already
                        if( ((this.state.product.name).toLowerCase() === (PRODUCTS[ID].name).toLowerCase()) && ID !== this.state.product.id ){
                            noErrors = false
                            errors[field] = "Ce nom de produit existe déja" 
                        }
                    })
                }
            } else {// no error
                errors[field] = ""   
            }
        })
        
        console.log("[INFO] NOERRORS = ",noErrors);
        console.log("[INFO] VALIDATE, ERRORS = ",errors);
        
        this.setState({
            ...this.state,
            errors : errors
        })
        return noErrors
    }

    handleSubmit = async () => {
        if( this.validate()){
            const newProd = Object.assign({}, this.state.product)
            newProd.id = ""

            floatFields.map( field => {
                newProd[field] = parseFloat(newProd[field])
            })
            intFields.map( field => {
                newProd[field] = parseInt(newProd[field])
            })

            newProd.ref = this.createRef()
                
            console.log(newProd);
                
            await this.props.createProduct(newProd)
                
            if(this.props.STATE.products.error){
                console.log("ERROR", this.props.STATE.products.error);
                this.setState({
                    sending: false,
                })
                this.props.notif(true, "danger","Un Problème est survenu")
            } else{
                console.log("fetched");
                //success 
                this.setState({
                    sending: false,
                })
                this.props.notif(true, "info","Le produit a été créé avec succès")
                this.clearForms()
                this.props.getPhotoProd(Object.keys(this.props.PRODUCTS)[0]) // get photo of last created product
                this.props.handleClose()
            }
        } else {
            this.props.notif(true, "danger","Le formulaire contient des erreurs")
            this.setState({
                sending: false,
            })
        }
    }
    handleSubmitUpdate = async () => {
        if( this.validate()){
            const newProd = Object.assign({},this.props.product, this.state.product)
            
            floatFields.map( field => {
                newProd[field] = parseFloat(newProd[field])                
            })
            intFields.map( field => {
                newProd[field] = parseInt(newProd[field])
            })

            await this.props.updateProduct(newProd)

            if(this.props.STATE.products.error){
                console.log("ERROR", this.props.STATE.products.error);
                this.props.notif(true, "danger","Un Problème est survenu")
            } else{
                console.log("Product updated")
                //success 
                this.props.notif(true, "info", "Le produit à été modifié avec succès")
                this.clearForms()
                this.props.handleClose()
            }

        } else {
            this.props.notif(true,  "warning", "le formulaire contient des erreurs", "Erreur" )
        }
    }
    
    render() {
       
        const prodForm = (
            <Grid container spacing={2} >
                <FormControl variant="outlined"  error={!!this.state.errors.type} style={{width: "100%"}}>
                    <InputLabel htmlFor="type">Type de Produit</InputLabel>
                    <Select
                        native
                        onChange={this.handleChange}
                        error={!!this.state.errors.type}
                        value={this.state.product.type}
                        input={<OutlinedInput labelWidth={130} name="type" id="type" />}
                    >
                        <option value=""/>
                        {this.createTypeList()}
                    </Select>
                    <FormHelperText>{this.state.errors.type}</FormHelperText>
                </FormControl>
                
                <Grid xs={12} sm={6}>
                    <Box p={1}>
                        <PhotoUpload label="Ajouter une Photo" photoList={this.setPhotos} photos={this.state.product.photos === null ? [] : this.state.product.photos }/>
                    </Box>
                </Grid>
                
                {common.map( field => (
                    FIELDS[field].field === "textfield" &&
                    <Grid item xs={12} sm={6}>
                        <TextField
                            style={{width:"100%"}}
                            required={FIELDS[field].required}
                            margin="normal"
                            variant="outlined"  
                            type={FIELDS[field].type}
                            name={field} 
                            id={field} 
                            value={this.state.product[field]} 
                            label={FIELDS[field].label} 
                            placeholder={FIELDS[field].label} 
                            error={!!this.state.errors[field]} 
                            helperText={this.state.errors[field]} 
                            onChange={this.handleChange}
                        />
                    </Grid>
                ))}
                        
                {this.state.product.type === "panneaux" &&

                <Fragment>
                    {panels.map( field => (
                        FIELDS[field].field === "textfield" &&
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    style={{width:"100%"}}
                                    required={FIELDS[field].required}
                                    margin="normal"
                                    variant="outlined"  
                                    type={FIELDS[field].type}
                                    name={field} 
                                    id={field} 
                                    value={this.state.product[field]} 
                                    label={FIELDS[field].label} 
                                    placeholder={FIELDS[field].label} 
                                    error={!!this.state.errors[field]} 
                                    helperText={this.state.errors[field]} 
                                    onChange={this.handleChange}
                                />
                            </Grid>
                    ))}

                    <FormControl variant="outlined"  error={!!this.state.errors.pan_cell} style={{width: "100%"}}>
                        <InputLabel htmlFor="pan_cell">Cellules</InputLabel>
                        <Select
                            native
                            onChange={this.handleChange}
                            error={!!this.state.errors.pan_cell}
                            value={this.state.product.pan_cell}
                            input={<OutlinedInput labelWidth={130} name="pan_cell" id="pan_cell" />}
                        >
                            <option value=""/>
                            <option value="polycristalline">Polycristalline</option>
                            <option value="monocristalline">Monocristalline</option>
                        </Select>
                        <FormHelperText>{this.state.errors.pan_cell}</FormHelperText>
                    </FormControl>                     

                </Fragment>
                
                }
                {(this.state.product.type).startsWith("convertisseurs") &&
                <Fragment>
                    {this.state.product.type !== "convertisseurs_dc-dc" &&
                    <Fragment>
                        <Grid item xs={12} sm={6}>
                            <FormControl component="fieldset" style={{ display: "flex"}}>
                            {/* <FormControl component="fieldset" className={classes.formControl}> */}
                                <FormLabel component="legend">Tension Entrée</FormLabel>
                                <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox 
                                        checked={this.state.product.conv_tension.includes(12)} 
                                        value={12} 
                                        onChange={this.setCheckbox} 
                                        name="conv_tension" />}
                                    label="12V"
                                />
                                <FormControlLabel
                                    control={<Checkbox 
                                        checked={this.state.product.conv_tension.includes(24)} 
                                        value={24} 
                                        onChange={this.setCheckbox} 
                                        name="conv_tension" />}
                                    label="24V"
                                />
                                <FormControlLabel
                                    control={<Checkbox 
                                        checked={this.state.product.conv_tension.includes(48)} 
                                        value={48} 
                                        onChange={this.setCheckbox} 
                                        name="conv_tension" />}
                                    label="48V"
                                />
                                </FormGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl variant="outlined"  error={!!this.state.errors.conv_signal} style={{width: "100%"}}>
                                <InputLabel htmlFor="conv_signal">Type Signal</InputLabel>
                                <Select
                                    native
                                    onChange={this.handleChange}
                                    error={!!this.state.errors.conv_signal}
                                    value={this.state.product.conv_signal}
                                    input={<OutlinedInput labelWidth={130} name="conv_signal" id="conv_signal" />}
                                >
                                    <option value=""/>
                                    <option value="Sinus Pur">Sinus pur</option>
                                    <option value="Quasi Sinus">Quasi sinus</option>
                                </Select>
                                <FormHelperText>{this.state.errors.conv_signal}</FormHelperText>
                            </FormControl>
                        </Grid>
                    
                        {cdc.map( field => (
                            FIELDS[field].field === "textfield" &&
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    style={{width:"100%"}}
                                    required={FIELDS[field].required}
                                    margin="normal"
                                    variant="outlined"  
                                    type={FIELDS[field].type}
                                    name={field} 
                                    id={field} 
                                    value={this.state.product[field]} 
                                    label={FIELDS[field].label} 
                                    placeholder={FIELDS[field].label} 
                                    error={!!this.state.errors[field]} 
                                    helperText={this.state.errors[field]} 
                                    onChange={this.handleChange}
                                />
                            </Grid>
                        ))}                          
                                                      
                        <Grid xs={12} sm={6}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Triphasé ?</FormLabel>
                                <RadioGroup aria-label="conv_triphase" name="conv_triphase" value={this.state.product.conv_triphase} onChange={this.handleChange}>
                                    <FormControlLabel value="oui" control={<Radio />} label="Oui" />
                                    <FormControlLabel value="non" control={<Radio />} label="Non" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Fragment>
                    }
                    
                </Fragment>
                }
                {this.state.product.type === "convertisseurs_chargeurs" && 
                <Fragment>
                    {conv_ch.map( field => (
                        FIELDS[field].field === "textfield" &&
                        <Grid item xs={12} sm={6}>
                            <TextField
                                style={{width:"100%"}}
                                required={FIELDS[field].required}
                                margin="normal"
                                variant="outlined"  
                                type={FIELDS[field].type}
                                name={field} 
                                id={field} 
                                value={this.state.product[field]} 
                                label={FIELDS[field].label} 
                                placeholder={FIELDS[field].label} 
                                error={!!this.state.errors[field]} 
                                helperText={this.state.errors[field]} 
                                onChange={this.handleChange}
                            />
                        </Grid>
                    ))}
                </Fragment>
                               
                }
                {this.state.product.type === "convertisseurs_chargeurs_MPPT" && 
                <Fragment>
                    
                    {conv_MPPT.map( field => (
                        FIELDS[field].field === "textfield" &&
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    style={{width:"100%"}}
                                    required={FIELDS[field].required}
                                    margin="normal"
                                    variant="outlined"  
                                    type={FIELDS[field].type}
                                    name={field} 
                                    id={field} 
                                    value={this.state.product[field]} 
                                    label={FIELDS[field].label} 
                                    placeholder={FIELDS[field].label} 
                                    error={!!this.state.errors[field]} 
                                    helperText={this.state.errors[field]} 
                                    onChange={this.handleChange}
                                />
                            </Grid>
                    ))}
                </Fragment>
                               
                }

                {this.state.product.type === "batteries" &&
                <Fragment>
                    <Grid item xs={12} sm={6}>
                        <FormControl variant="outlined"  error={!!this.state.errors.bat_type} style={{width: "100%"}}>
                            <InputLabel htmlFor="bat_type">Type Batterie</InputLabel>
                            <Select
                                native
                                onChange={this.handleChange}
                                error={!!this.state.errors.bat_type}
                                value={this.state.product.bat_type}
                                input={<OutlinedInput labelWidth={130} name="bat_type" id="bat_type" />}
                            >
                                <option value=""/>
                                <option value="carbone">Carbone</option>
                                <option value="GEL">GEL</option>
                                <option value="lithium">Lithium</option>
                                <option value="acide">Acide</option>
                                <option value="AGM">AGM</option>
                            </Select>
                            <FormHelperText>{this.state.errors.bat_type}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box style={{ display: "flex"}}>
                            <FormControl component="fieldset" >
                                <FormLabel component="legend">Tension</FormLabel>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Checkbox 
                                        checked={this.state.product.bat_tension.includes(2)} 
                                        value={2} 
                                        onChange={this.setCheckbox} 
                                        name="bat_tension" />}
                                        label="2V"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox 
                                        checked={this.state.product.bat_tension.includes(6)} 
                                        value={6} 
                                        onChange={this.setCheckbox} 
                                        name="bat_tension" />}
                                        label="6V"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox 
                                        checked={this.state.product.bat_tension.includes(12)} 
                                        value={12} 
                                        onChange={this.setCheckbox} 
                                        name="bat_tension" />}
                                        label="12V"
                                    />
                                </FormGroup>
                            </FormControl>

                        </Box>
                    </Grid>
                    {bat.map( field => (
                        FIELDS[field].field === "textfield" &&
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    style={{width:"100%"}}
                                    required={FIELDS[field].required}
                                    margin="normal"
                                    variant="outlined"  
                                    type={FIELDS[field].type}
                                    name={field} 
                                    id={field} 
                                    value={this.state.product[field]} 
                                    label={FIELDS[field].label} 
                                    placeholder={FIELDS[field].label} 
                                    error={!!this.state.errors[field]} 
                                    helperText={this.state.errors[field]} 
                                    onChange={this.handleChange}
                                />
                            </Grid>
                    ))}
                                                        
                </Fragment>
                }
                {this.state.product.type === "regulateurs" &&
                <Fragment>
                    <Grid item xs={12} sm={6}>
                        <FormControl variant="outlined"  error={!!this.state.errors.reg_type} style={{width: "100%"}}>
                            <InputLabel htmlFor="reg_type">Type Regulateur</InputLabel>
                            <Select
                                native
                                onChange={this.handleChange}
                                error={!!this.state.errors.reg_type}
                                value={this.state.product.reg_type}
                                input={<OutlinedInput labelWidth={130} name="reg_type" id="reg_type" />}
                            >
                                <option value=""/>
                                <option value="MPPT">MPPT</option>
                                <option value="PWM">PWM</option>
                            </Select>
                            <FormHelperText>{this.state.errors.reg_type}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl variant="outlined"  error={!!this.state.errors.reg_affichage} style={{width: "100%"}}>
                            <InputLabel htmlFor="reg_affichage">Type Regulateur</InputLabel>
                            <Select
                                native
                                onChange={this.handleChange}
                                error={!!this.state.errors.reg_affichage}
                                value={this.state.product.reg_affichage}
                                input={<OutlinedInput labelWidth={130} name="reg_affichage" id="reg_affichage" />}
                            >
                                <option value=""/>
                                <option value="numerique">Numérique</option>
                                <option value="led">LED</option>
                            </Select>
                            <FormHelperText>{this.state.errors.reg_affichage}</FormHelperText>
                        </FormControl>
                    </Grid>
                    
                    <Grid item xs={12} sm={6}>
                        <FormControl component="fieldset" style={{ display: "flex"}}>
                        {/* <FormControl component="fieldset" className={classes.formControl}> */}
                            <FormLabel component="legend">Tension</FormLabel>
                            <FormGroup>
                            <FormControlLabel
                                control={<Checkbox 
                                    checked={this.state.product.bat_tension.includes(12)} 
                                    value={12} 
                                    onChange={this.setCheckbox} 
                                    name="bat_tension" />}
                                label="12V"
                            />
                            <FormControlLabel
                                control={<Checkbox 
                                    checked={this.state.product.bat_tension.includes(24)} 
                                    value={24} 
                                    onChange={this.setCheckbox} 
                                    name="bat_tension" />}
                                label="24V"
                            />
                            <FormControlLabel
                                control={<Checkbox 
                                    checked={this.state.product.bat_tension.includes(48)} 
                                    value={48} 
                                    onChange={this.setCheckbox} 
                                    name="bat_tension" />}
                                label="48V"
                            />
                            </FormGroup>
                        </FormControl>
                    </Grid>

                    {reg.map( field => (
                        FIELDS[field].field === "textfield" &&
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    style={{width:"100%"}}
                                    required={FIELDS[field].required}
                                    margin="normal"
                                    variant="outlined"  
                                    type={FIELDS[field].type}
                                    name={field} 
                                    id={field} 
                                    value={this.state.product[field]} 
                                    label={FIELDS[field].label} 
                                    placeholder={FIELDS[field].label} 
                                    error={!!this.state.errors[field]} 
                                    helperText={this.state.errors[field]} 
                                    onChange={this.handleChange}
                                />
                            </Grid>
                    ))}

                </Fragment>
                }
                {this.state.product.type === "cables" &&
                <Fragment>
                    <Grid item xs={12} sm={6}>
                        <FormControl variant="outlined"  error={!!this.state.errors.cab_type} style={{width: "100%"}}>
                            <InputLabel htmlFor="cab_type">Type Conduits</InputLabel>
                            <Select
                                native
                                onChange={this.handleChange}
                                error={!!this.state.errors.cab_type}
                                value={this.state.product.cab_type}
                                input={<OutlinedInput labelWidth={130} name="cab_type" id="cab_type" />}
                            >
                                <option value=""/>
                                <option value="rigide">Rigide</option>
                                <option value="souple">Souple</option>
                            </Select>
                            <FormHelperText>{this.state.errors.cab_type}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl variant="outlined"  error={!!this.state.errors.cab_nb_conducteurs} style={{width: "100%"}}>
                            <InputLabel htmlFor="cab_nb_conducteurs">Nombre de conducteurs</InputLabel>
                            <Select
                                native
                                onChange={this.handleChange}
                                error={!!this.state.errors.cab_nb_conducteurs}
                                value={this.state.product.cab_nb_conducteurs}
                                input={<OutlinedInput labelWidth={130} name="cab_nb_conducteurs" id="cab_nb_conducteurs" />}
                            >
                                <option value=""/>
                                <option value={1}>1 fil</option>
                                <option value={2}>2 fils</option>
                                <option value={3}>3 fils</option>
                                <option value={4}>4 fils</option>
                            </Select>
                            <FormHelperText>{this.state.errors.cab_nb_conducteurs}</FormHelperText>
                        </FormControl>
                    </Grid>
                    
                    
                    {cab.map( field => (
                        FIELDS[field].field === "textfield" &&
                        <Grid item xs={12} sm={6}>
                            <TextField
                                style={{width:"100%"}}
                                required={FIELDS[field].required}
                                margin="normal"
                                variant="outlined"  
                                type={FIELDS[field].type}
                                name={field} 
                                id={field} 
                                value={this.state.product[field]} 
                                label={FIELDS[field].label} 
                                placeholder={FIELDS[field].label} 
                                error={!!this.state.errors[field]} 
                                helperText={this.state.errors[field]} 
                                onChange={this.handleChange}
                            />
                        </Grid>
                    ))}
                </Fragment>
                }

                {this.state.product.type === "pompes" &&
                <Fragment>
                    {pompes.map( field => (
                     FIELDS[field].field === "textfield" &&
                     <Grid item xs={12} sm={6}>
                        <TextField
                            style={{width:"100%"}}
                            required={FIELDS[field].required}
                            margin="normal"
                            variant="outlined"  
                            type={FIELDS[field].type}
                            name={field} 
                            id={field} 
                            value={this.state.product[field]} 
                            label={FIELDS[field].label} 
                            placeholder={FIELDS[field].label} 
                            error={!!this.state.errors[field]} 
                            helperText={this.state.errors[field]} 
                            onChange={this.handleChange}
                        />
                     </Grid>
                 ))}

                </Fragment>
                }
                {this.state.product.type === "lampes" &&
                <Fragment>
                   {lam.map( field => (
                        FIELDS[field].field === "textfield" &&
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    style={{width:"100%"}}
                                    required={FIELDS[field].required}
                                    margin="normal"
                                    variant="outlined"  
                                    type={FIELDS[field].type}
                                    name={field} 
                                    id={field} 
                                    value={this.state.product[field]} 
                                    label={FIELDS[field].label} 
                                    placeholder={FIELDS[field].label} 
                                    error={!!this.state.errors[field]} 
                                    helperText={this.state.errors[field]} 
                                    onChange={this.handleChange}
                                />
                            </Grid>
                    ))}

                    <Grid item xs={12} sm={6}>
                        <FormControl variant="outlined"  error={!!this.state.errors.lam_tension} style={{width: "100%"}}>
                            <InputLabel htmlFor="lam_tension">Tension d'alimentation</InputLabel>
                            <Select
                                native
                                onChange={this.handleChange}
                                error={!!this.state.errors.lam_tension}
                                value={this.state.product.lam_tension}
                                input={<OutlinedInput labelWidth={130} name="lam_tension" id="lam_tension" />}
                            >
                                <option value=""/>
                                <option value={12}>12V</option>
                                <option value={220}>220V</option>
                            </Select>
                            <FormHelperText>{this.state.errors.lam_tension}</FormHelperText>
                        </FormControl>
                    </Grid>

                
                </Fragment>
                }
                {this.state.product.type === "menager" &&
                <Fragment>
                    <Grid item xs={12} sm={6}>
                        <FormControl variant="outlined"  error={!!this.state.errors.men_type} style={{width: "100%"}}>
                            <InputLabel htmlFor="men_type">Type</InputLabel>
                            <Select
                                native
                                onChange={this.handleChange}
                                error={!!this.state.errors.men_type}
                                value={this.state.product.men_type}
                                input={<OutlinedInput labelWidth={130} name="men_type" id="men_type" />}
                                disabled={this.state.update}
                            >
                                <option value=""/>
                                <option value="TV">Télévision</option>
                                <option value="Ventilateur">Ventilateur</option>
                                <option value="Autre">Autre</option>
                            </Select>
                            <FormHelperText>{this.state.errors.men_type}</FormHelperText>
                        </FormControl>
                    </Grid>

                    
                    {this.state.product.men_type === "TV" &&
                    <Fragment>
                        {tv.map( field => (
                            FIELDS[field].field === "textfield" &&
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    style={{width:"100%"}}
                                    required={FIELDS[field].required}
                                    margin="normal"
                                    variant="outlined"  
                                    type={FIELDS[field].type}
                                    name={field} 
                                    id={field} 
                                    value={this.state.product[field]} 
                                    label={FIELDS[field].label} 
                                    placeholder={FIELDS[field].label} 
                                    error={!!this.state.errors[field]} 
                                    helperText={this.state.errors[field]} 
                                    onChange={this.handleChange}
                                />
                            </Grid>
                        ))}

                        <Grid xs={12} sm={6}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Tuner inclus ?</FormLabel>
                                <RadioGroup aria-label="tv_tuner" name="tv_tuner" value={this.state.product.tv_tuner} onChange={this.handleChange}>
                                    <FormControlLabel value="oui" control={<Radio />} label="Oui" />
                                    <FormControlLabel value="non" control={<Radio />} label="Non" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Fragment>

                    }
                    {this.state.product.men_type === "Ventilateur" &&
                    <Fragment>
                        {vent.map( field => (
                            FIELDS[field].field === "textfield" &&
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    style={{width:"100%"}}
                                    required={FIELDS[field].required}
                                    margin="normal"
                                    variant="outlined"  
                                    type={FIELDS[field].type}
                                    name={field} 
                                    id={field} 
                                    value={this.state.product[field]} 
                                    label={FIELDS[field].label} 
                                    placeholder={FIELDS[field].label} 
                                    error={!!this.state.errors[field]} 
                                    helperText={this.state.errors[field]} 
                                    onChange={this.handleChange}
                                />
                            </Grid>
                        ))}

                    </Fragment>
                    }
                    <Grid item xs={12} sm={6}>
                        <FormControl component="fieldset" style={{ display: "flex"}}>
                            <FormLabel component="legend">Tension d'Alimentation</FormLabel>
                            <FormGroup>
                            <FormControlLabel
                                control={<Checkbox 
                                    checked={this.state.product.tv_tension_alim.includes(12)} 
                                    value={12} 
                                    onChange={this.setCheckbox} 
                                    name="tv_tension_alim" />}
                                label="12V"
                            />
                            <FormControlLabel
                                control={<Checkbox 
                                    checked={this.state.product.tv_tension_alim.includes(220)} 
                                    value={220} 
                                    onChange={this.setCheckbox} 
                                    name="tv_tension_alim" />}
                                label="220V"
                            />
                            
                            </FormGroup>
                        </FormControl>
                    </Grid>

                    {menager.map( field => (
                        FIELDS[field].field === "textfield" &&
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    style={{width:"100%"}}
                                    required={FIELDS[field].required}
                                    margin="normal"
                                    variant="outlined"  
                                    type={FIELDS[field].type}
                                    name={field} 
                                    id={field} 
                                    value={this.state.product[field]} 
                                    label={FIELDS[field].label} 
                                    placeholder={FIELDS[field].label} 
                                    error={!!this.state.errors[field]} 
                                    helperText={this.state.errors[field]} 
                                    onChange={this.handleChange}
                                />
                            </Grid>
                    ))}
                    
                </Fragment>
                }
                {/* {this.state.product.type === "autres" &&
                    <div className={"form-group "+ (this.state.errors.desc ? "has-error" : "") }>
                        <label htmlFor="desc" className="col-sm-3 control-label">Description</label>
                        <div className="col-sm-9">
                            <textarea className="form-control" id="desc" 
                                onChange={this.handleChange}
                                 value={this.state.product.desc} name="desc" rows="5" placeholder="Taille, description, couleur... "></textarea>
                            <small className="help-block">{this.state.errors.desc}</small>
                        </div>
                    </div>

                <Grid item xs={12} sm={6}>
                    <TextField
                        style={{width:"100%"}}
                        required={FIELDS[field].required}
                        margin="normal"
                        variant="outlined"  
                        type={FIELDS[field].type}
                        name={field} 
                        id={field} 
                        value={this.state.product[field]} 
                        label={FIELDS[field].label} 
                        placeholder={FIELDS[field].label} 
                        error={!!this.state.errors[field]} 
                        helperText={this.state.errors[field]} 
                        onChange={this.handleChange}
                    />
                </Grid>
                }
                */}
                                    
            </Grid>
        )
        // const submit = (
        //     <button disabled={this.state.sending || this.props.STATE.products.fetching} className="confirm btn btn-lg btn-primary m-5"
        //     style={{ display: "inline-block" }} 
        //     onClick={()=>{
        //         this.setState({
        //             sending:true
        //         }, this.handleSubmit);
                
        //     }}
        //     >
        //         Ajouter
        //     </button>
        // )
        // const submitUpdate = (
        //     <button 
        //         disabled={this.state.sending || this.props.STATE.products.fetching}     
        //         className="confirm btn btn-lg btn-primary m-5" 
        //         style={{ display: "inline-block" }} 
        //         onClick={()=>{
        //             this.setState({
        //                 sending:true
        //             }, this.handleSubmitUpdate);  
        //         }}
        //     >
        //         Modifier
        //     </button>
        // )
        
        return (
            <Box p={1}>
                {prodForm}

            </Box>
        );
    }
}

export default FormProduct;