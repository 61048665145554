export function userRequest(state = {
    fetching:false,
    fetched: false,
    error:null,
},{
    type,
    payload
}){
    
    switch(type){
        case "CREATE_USER_START":           
        case "FETCH_USERS_START":           
        case "UPDATE_USER_ROLE_START":           
        case "CREDIT_USER_START":           
            return {
                ...state, 
                fetching:true,
            }        
        case "CREATE_USER_FULFILLED":           
        case "FETCH_USERS_FULFILLED":           
        case "UPDATE_USER_ROLE_FULFILLED":           
        case "CREDIT_USER_FULFILLED":           
            return {
                ...state,
                fetching:false, 
                fetched:true, 
                error: null
            }    
        case "CREATE_USER_ERROR":
        case "FETCH_USERS_ERROR":
        case "UPDATE_USER_ROLE_ERROR":
        case "CREDIT_USER_ERROR":
            return {
                ...state,
                fetching:false, 
                error:payload
            }
        default:
            return state;
    }
    
}