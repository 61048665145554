export function stockReducer(state = {},{
    type,
    payload
}){
    
    switch(type){
        case "GET_STOCKS":
            return payload;

        default:
            return state;
    }

}