export function productRequest(state = {
    fetching:false,
    fetched: false,
    error:null,
},{
    type,
    payload
}){
    
    switch(type){
        case "CREATE_PRODUCT_START":           
        case "FETCH_PRODUCTS_START":                                 
        case "UPDATE_PRODUCT_START":                                 
            return {
                ...state, 
                fetching:true,
            }        
        case "CREATE_PRODUCT_FULFILLED":           
        case "FETCH_PRODUCTS_FULFILLED":                                 
        case "UPDATE_PRODUCT_FULFILLED":                                 
            return {
                ...state,
                fetching:false, 
                fetched:true, 
                error: null
            }    
        case "CREATE_PRODUCT_ERROR":
        case "FETCH_PRODUCTS_ERROR":
        case "UPDATE_PRODUCT_ERROR":
            return {
                ...state,
                fetching:false, 
                error:payload
            }
        default:
            return state;
    }
    
}