import React, { Component, Fragment } from 'react';
import { FormattedMessage } from "react-intl";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import { newCompany } from '../../../store/actions/companyAction'
import { isEmpty } from "lodash";
import Notif from "../../common/Notif";

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';

import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';

import COUNTRIES from '../../../utils/countries'
import DatePicker from '../../common/DatePicker';
import PhotoUpload from '../../common/PhotoUpload';
import axios from 'axios';
import CompanyForm from './CompanyForm';

class Info extends Component {
    state = {
        uri: "",
    }
    // componentDidMount= async () => {
    //     // fetch logo
    //     let res = await axios.post("/company/getPhoto", {path:this.props.company.company_logo})
    //     let uri = res.data
    //     this.setState({uri})
    // }
    render() {
        const {company} = this.props;
        return (
            <Grid container spacing={2}>
                <Grid item sm={9} xs={12}>
                <h2>{company.company_name}</h2>
                <Typography>{company.company_address} {company.company_address2}</Typography>
                <Typography>{company.company_zipcode} - {company.company_city}</Typography>
                <Typography>{company.company_country}</Typography>


                </Grid>
                <Grid item sm={3} xs={12} style={{maxHeight: "100px", textAlign: "center"}}>
                    <img style={{maxHeight: "100px"}}src={(company.company_logo ? `/photos/shop/app/${company.company_logo}` : "")} alt={company.company_name} title={company.company_name} />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">{company.company_presentation}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">
                        <a href={company.company_website} target="_blank" style={{textDecoration: "underline", color: "blue"}}>{company.company_website}</a> |  {company.company_email}
                    </Typography>
                </Grid>

            </Grid>
        )
    }
}


class CompanyInfo extends Component {
    state = { 
        view: "",
        company:{}
    }
    componentDidMount = () => {
        window.scrollTo(0, 0);
        this.setPage()
    }
    setPage = async () => {
        try {
            let res = await axios.post("/company/getCompany", {account_id: this.props.USER.account_id})
            console.log('============CDM COMPANY INFO================');
            console.log({res});
            console.log('====================================');
            if (!res.data.company_id){
                this.setState({
                    view:"form"
                })
            } else {
                this.setState({
                    company: res.data,
                    view: "infos"
                })
            }
        } catch (error) {
            console.log('====CDM ERROR================================');
            console.log(error);
            console.log('====================================');
        }
    }
    handleClickOpen = () => {
        this.setState({ open: true });
    };
    
    handleClose = () => {
        this.setState({ 
            open: false,
            deleteDialog: false,
            deleteID: ""
        });
    };

    notif = (openNotif, variant, message) => {
        this.setState({
            notif:{
                openNotif,
                variant,
                message
            }
        })
    }

    render(){
        const { view, company } = this.state;
        return(
            <div>

                {view === "infos" &&
                    // <h3>{company.company_name}</h3>
                    <Info company={this.state.company} />
                }
                {view === "form" &&
                    <CompanyForm success={this.setPage} />
                }
            
            </div>
        )
    }
}


function mapStateToProps(state){// transform elements from store's state into props in this component
    return {
        
        STATE: state.request.company,
        USER: state.auth.user,
        isSuper: state.auth.isSuperAdmin
    }
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({
        newCompany,
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyInfo)