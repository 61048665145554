import React, { Component } from "react";

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

class Login extends Component{
    state = {
        open: false
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render(){
        console.log('[INFO], Login, State,',this.state);
        console.log(this.props);
        
        return(
            <div>
                
                <div> 
                    <TextField
                        style={{width:"100%"}}
                        required
                        margin="normal"
                        variant="outlined"  
                        name="email" 
                        id="email" 
                        value={this.props.email} 
                        label="Email" 
                        placeholder="Email ici" 
                        error={!!this.props.errors.email} 
                        helperText={this.props.errors.email} 
                        onChange={this.props.handleChange} 
                    />
                    <TextField
                        style={{width:"100%"}}
                        required
                        margin="normal"
                        variant="outlined"  
                        type="password"
                        name="password" 
                        id="password" 
                        value={this.props.password} 
                        label="Mot de Passe" 
                        placeholder="Mot de Passe ici" 
                        error={!!this.props.errors.password} 
                        helperText={this.props.errors.password} 
                        onChange={this.props.handleChange} 
                    />
                </div>
                
                <div style={{textAlign: "center"}} >
                    <Button color="secondary" variant="contained" size="large" onClick={()=>this.props.handleSubmit("login")} >
                    Se Connecter
                    </Button>
                </div>
                <hr/>
                {/* <p className="a-like" onClick={this.props.openForgetPassword}>Mot de Passe oublié ?</p> */}
            </div>
        )
    }
}

export default Login;