import React, { Component } from 'react';
import { Box, Typography } from '@material-ui/core';

class SellerSettings extends Component {
    state = {  }
    render() {
        return (
            <Box>
                <Typography variant="h6" id="tableTitle">
                    Paramètres du Compte
                </Typography>
                <Box p={2}>
                    Form infos ...
                </Box>
            </Box>
        );
    }
}

export default SellerSettings;