export function userReducer(state = "",{
    type,
    payload
}){
    
    switch(type){
        case "NEW_USER":            
                
            return {[payload.user_id] : payload, ...state};
        case "GET_USERS":
            let list = state === "" ? payload : Object.assign(payload, state);
            return list
        case "GET_RESUME_USERS":
            
            return payload;
        case "UPDATE_USER":
            let user = state[payload.user_id]
            if( user ) {
                state[payload.user_id][payload.field] = payload.newData
            }
            return state;
        case "CREDIT_USER":
            user = state[payload.user_id]
            if( user ) {
                state[payload.user_id].account += payload.amount
            }
            return state;
        
        default:
            return state;
    }
    
}