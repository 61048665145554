import React, { Component, Fragment } from 'react';
import { Link } from "react-router-dom";

import { withStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from "@material-ui/icons/Menu";

import { Typography, Box, IconButton } from '@material-ui/core';

const styles = theme => ({
    list: {
        width: 250,
    },
  });

class SideBarMenu extends Component {
    state = {
        openDrawer: false,
    }
    
    toggleDrawer = (open) => {
        this.setState({ openDrawer: open });
    }

   list = () => ( // this.props.menuList
        <div
            className={this.props.classes.list}
            role="presentation"
            onClick={() => this.toggleDrawer(false)}
            onKeyDown={() => this.toggleDrawer(false)}
        >
            <List>
                {this.props.links.map( link => (
                    <Link to={link.to}>
                        <ListItem button key={link.default}>
                            <ListItemText primary={link.default} />
                        </ListItem>
                    </Link>
                ))}
            </List>
            <Divider />
            <List>

                <ListItem button key="Paramètres">
                    <ListItemText primary="Paramètres" />
                </ListItem>
                <ListItem button key="Déconnexion" onClick={() => {
                        this.props.logout();
                    }}
                >
                    <ListItemText primary="Déconnexion" />
                </ListItem>
            </List>
        </div>
   )
    render () {
        return (
          <Fragment>        
                <IconButton 
                    onClick={() => this.toggleDrawer(true)} 
                    color="inherit" 
                    aria-label="Menu"
                >
                    <MenuIcon />
                </IconButton>
                <SwipeableDrawer
                    anchor={"left"}
                    open={this.state.openDrawer}
                    onClose={() => this.toggleDrawer(false)}
                    onOpen={() => this.toggleDrawer(true)}
                >
                    <Box p={2}>
                        <Typography variant="h5">Solar Africa</Typography>
                    </Box>
                    {this.list()}
                </SwipeableDrawer>
          </Fragment>
        )
    }
}

export default withStyles(styles)(SideBarMenu)