import React,{ Component } from "react";

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';

import { logUser, register } from '../store/actions/authAction'
import Notif from "./common/Notif";

import Register from "./Auth/Register";
import Login from "./Auth/Login";
import axios from "axios";
import FullPage from "../container/layouts/FullPage";



class LoginPage extends Component{
    state = {
        active: "login",
        email:"",
        username:"",
        lastname:"",
        password:"",
        password2:"",
        resetEmail: "",
        errors:{
            email:"",
            username:"",
            lastname:"",
            password:"",
            password2:"",
            resetEmail: "",
        },
        success:{
            email:"",
            username:"",
            password:"",
            password2:"",
        },
        notif: {
            open: false,
            variant:"",
            message: "",
        },
        openPassword: false,
    }   

    handleChange = (e) =>{
        let field = e.target.name
        let value = e.target.value
        if(field === "email" || field === "resetEmail" ){
            value = value.toLowerCase().replace(/\s/g,"");
        }
        if(field === "password" && this.state.form === "register"){
            this.validPassword(value)
        }
        this.setState({
            [field]: value
        })
    }
    validEmail = (email, field = "email") => {
        let validEmail =  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        console.log("in check email", email);
        
        if(validEmail.test(String(email).toLowerCase())){
            console.log("valid email !");
            
            this.setState({
                success:"super email",
                errors:{
                    ...this.state.errors,
                    [field]:""
                }
            })
            return true
        } else {
            console.log("Bad email !");
            this.setState({
                errors:{
                    ...this.state.errors,
                    [field]:"Email incorrect"
                }
            }) 
        }
    }
    validPassword = (password) => {
        let isValid = true
        let re = /[0-9]/;
        if(!re.test(password)) {
            isValid = false
        }
        re = /[a-z]/;
        if(!re.test(password)) {
            isValid = false
        }
        re = /[A-z]/;
        if(!re.test(password)) {
            isValid = false
        }
        if(password.length < 6){
            isValid = false
        }
        let errMsg = isValid ? "" : "Le mot de passe doit contenir au minimum 6 caractères dont un chiffre, une majuscule et une minuscule"
        
        this.setState({
            errors:{
                ...this.state.errors,
                password:errMsg
            }
        }) 
        return isValid;
    }
    validate = (form) => {
        console.log('[Info],LoginPage,validate, entrer function,',{form});
        let noError = true
        let errors = {}
        let required = [
            "email", 
            "password",
        ]  
        if(form === "register"){
            required.push(
                "username",
                "lastname",
            )
            if(this.state.password2 !== this.state.password){
                console.log('[ERROR],LoginPage,validate, password are not identique :');
                errors.password2 = "Le mot de Passe et sa confirmation doivent être identiques" 
                noError =  false
            } else {
                let isValid = this.validPassword(this.state.password);
                if (!isValid){
                    noError =  false
                }
                errors.password2 = "" 
            }
        }
        required.map(field => {
            if(!this.state[field]){
                noError =  false
                errors[field] = "Veuillez remplir ce Champ" 
            } else {
                errors[field] = "" 
            }
        })
        console.log('[Info],LoginPage,validate, entrer function,',{errors}, ' - ', noError);
        this.setState({
            errors
        })
        return noError
    }
    handleSubmit = async(form) => {
        if(this.validate(form)){

            if (form === "login"){
                const resp = await this.props.logUser({email:this.state.email, password:this.state.password});
                if (resp === "ok"){
                    return
                }
                console.log('[ERROR],LoginPAge,handleSubmit,  err',{ resp });
                let msg = "Un Problème est survenu  : "+resp.data.error;
                console.log('[ERROR],LoginPAge,handleSubmit,  err',resp.data.error , ' - ',this.props.lang);
                if (this.props.lang === "fr" && resp.data.error === "user and password don't match"){
                    msg = "Un Problème est survenu  : Le mot de passe et l'email ne correspondent pas"
                }
                
                if (this.props.lang === "fr" && resp.data.error === "account is not activated - new email send"){
                    msg = "Votre compte n'est pas activé. Un nouveau email de validation vient de vous être envoyé. Cliquez ensuite sur le lien de l'email. En cas de problème contactez nous par email à l'adresse: contact@mysolarlending.com"
                }
                this.notif(true,"error",msg)
                return;
            } else {
                if(this.state.newsletter){
                    this.handleSubmitNewsletter()
                }
                await this.props.register({
                    email:this.state.email, 
                    password:this.state.password,
                    username:this.state.username,
                    lastname:this.state.lastname,
                })
                if(this.props.STATE.error){
                    console.log('[ERROR], LoginPAge, handleSubmit, Error Register',this.props.STATE.error)
                    //Describe Error
                    let msg = "Un Problème technique est survenu, veillez contacter l'administrateur : contact@mysolarlending.com";
                    if (this.props.lang === "fr" && this.props.STATE.error === "User already exists"){
                        msg = "Un Problème est survenu  : L'utilisateur a déjà un compte"
                    }
                    this.notif(true,"error",msg)
                } else{
                    this.notif(true,"info","Inscription réussie, un email vous a été envoyé pour valider votre compte")
                    this.clearForms()
                    this.setState({
                        active: "login"
                    })
                }
            }
        } else {

            this.notif(true,"error","Le Formulaire contient des erreurs")
        }
    }

    handleResetPassword = async() => {
        if(this.validEmail(this.state.resetEmail, "resetEmail")){
            let res = await axios.post("/auth/reset/password",{email: this.state.resetEmail});
			console.log({ res });
            
            if(res.data !== "OK"){
                this.notif(true,"error","Un Problème est survenu")
            } else {
                this.notif(true,"success","Un email vous a été envoyé pour réinitialiser votre mot de passe")
                this.clearForms()
            }
        } else {
            this.notif(true,"error","Le Formulaire contient des erreurs")
        }
    }
    clearForms = () => {
        this.setState({
            email:"",
            username:"",
            lastname:"",
            password:"",
            password2:"",
            resetEmail: "",
            errors:{
                email:"",
                username:"",
                lastname:"",
                password:"",
                password2:"",
                resetEmail: "",
            },
            openPassword: false,
        })
    }

    notif = (open, variant, message) => {
        this.setState({
            notif:{
                open,
                variant,
                message
            }
        })
    }
    
    render(){
        const { active } = this.state
        const { open, variant, message } = this.state.notif
        return(
            <FullPage page="login" noBanner>
                {(open && variant && message) && 
                <Notif
                    open={open}
                    onClose={()=>this.notif(false)}
                    variant={variant}
                    message={message}
                />
                }
              
                <Card className="login-form-box">
                    <CardHeader title={(this.state.active === "login" ? "Connexion" : "Inscription")}/>
                    <CardContent className="login-form">
                        {active === "login" && 
                            <Login 
                                handleChange={this.handleChange} 
                                handleSubmit={this.handleSubmit} 
                                handleReset={this.handleResetPassword} 
                                openForgetPassword={() => this.setState({
                                    openPassword: true
                                })}
                                closeForgetPassword={() => this.setState({
                                    openPassword: false
                                })}
                                {...this.state}
                            /> 
                        }
                        {active === "register" && 
                            <Register handleChange={this.handleChange} handleSubmit={this.handleSubmit} {...this.state} />}
                        
                    </CardContent>
                    
                        
                    {active === "login" &&
                        <CardActions>
                            <span>Pas encore de Compte ?</span>
                            <Button 
                                size="large" 
                                color="secondary" 
                                onClick={
                                    () => this.setState({
                                        active:"register"
                                    })
                                } 
                                style={{marginLeft: "auto"}} 
                            >
                                S'inscrire
                            </Button>
                        </CardActions>
                    }
                    {active === "register" &&
                        <CardActions>
                            <span>Vous avez deja un Compte ?</span>
                            <Button 
                                size="large" 
                                color="secondary" 
                                onClick={
                                    () => this.setState({
                                        active:"login"
                                    })
                                } 
                                style={{marginLeft: "auto"}} 
                            >
                                Se Connecter
                            </Button>
                        </CardActions>
                    }
                </Card>
            
            </FullPage >
        )
    }
}

// LoginPage.contextTypes = {
//     router: React.PropTypes.object.isRequired
// }

function mapStateToProps(state){// transform elements from store's state into props in this component
    return {
        page: state.page,
        STATE: state.request.users,
        // lang: state.locale.lang
    }
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({
        
        logUser,
        register,

    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoginPage))