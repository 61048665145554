export function cartRequest(state={
    fetching:false,
    fetched: false,
    error:null,
},{
    type,
    payload
}){
    
    switch(type){
        case "ADD_IN_CART_START":           
        case "REFRESH_CART_START":           
        case "POST_CART_START":           
            return {
                ...state, 
                fetching:true
            }        
        case "ADD_IN_CART_FULFILLED":           
        case "REFRESH_CART_FULFILLED":           
        case "POST_CART_FULFILLED":           
            return {
                ...state,
                fetching:false, 
                fetched:true, 
                error: null
            }    
        case "ADD_IN_CART_ERROR":
        case "REFRESH_CART_ERROR":
        case "POST_CART_ERROR":
            return {
                ...state,
                fetching:false, 
                error:payload
            }
        default:
            return state;
    }
    
}