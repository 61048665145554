import React, { Component } from 'react';

import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import { P } from '../common/Typo';

export class SuccessPaymentCB extends Component {
    state = {  }
    render() {
        return (
            <main>
                <Typography variant="h5" gutterBottom>Commande enregistré</Typography>
                    <P>
                        Votre Paiement a été accepté.
                    </P>
                    <P>
                        Vous allez recevoir un email de confirmation dans quelques minutes,
                    </P>
                    
            </main>
        );
    }
}
const RIB = () => (
    <div className="table-responsive" style={{maxWidth: "500px", margin: "auto"}}>
        <Card style={{padding: "8px"}} gutterBottom>
            <table className="table table-bordered">
                <tbody>
                    <tr>
                        <td>Code Banque </td>
                        <th>18206</th>
                    </tr>
                    <tr>
                        <td>Code guichet </td>
                        <th>00203</th>
                    </tr>
                    <tr>
                        <td>Numéro de compte </td>
                        <th>65036979091</th>
                    </tr>
                    <tr>
                        <td>Clé RIB </td>
                        <th>54</th>
                    </tr>
                    <tr><td colSpan={2}></td></tr>
                    <tr>
                        <td>IBAN (International bank account number)</td>
                        <th> FR76 18206 00203 65036979091 54</th>
                    </tr>
                    <tr><td colSpan={2}></td></tr>
                    <tr>
                        <td>Code BIC (Bank Identification Code) - Code SWIFT</td>
                        <th>AGRIFRPP882</th>
                    </tr>
                    <tr><td colSpan={2}></td></tr>
                    <tr>
                        <td>Domiciliation</td>
                        <th>PARIS SAINT-GERMAIN</th>
                    </tr>
                    
                    
                </tbody>
            </table>
        </Card>
    </div>
)

export class SuccessPaymentTransfer extends Component {
    state = {  }
    render() {
        return (
            <main>
                <Typography variant="h5" gutterBottom>
                Paiement enregistré
                </Typography>
                <P> Votre investissement est actuellement en attente. Un email de confirmation vous sera envoyé dans quelques minutes  </P>
                <P>Voici les étapes à suivre pour finaliser votre paiement:</P>
                <ol>
                    <li><P>Faire un virement de : <b color="secondary">245 EUR</b> sur le compte Titulaire :  S.A.S. MYJOULEBOX </P>
                    <RIB />
                    </li>
                    <li><P>
                    Indiquer dans l'intitulé du virement le numéro de dossier suivant : <b color="secondary" style={{textDecoration: "underline"}}>{this.props.trID && this.props.trID.slice(0,8)}</b>
                    </P></li>
                    
                    <li><P>
                        Envoyer un email indiquant la date du virement et votre numéro de dossier à l'adresse : <b><a href="mailto:contact@mysolarlending.com" className="email">contact@mysolarlending.com </a></b> 
                    </P></li>
                </ol>
                <P><b>Sans virement de votre part au bout de 10 jours, nous serons au regret d'annuler votre participation au financement du projet.</b></P>
                <br/>
                <P><i>En cas de problème technique ou autre : n'hésitez pas à nous contacter par email : <b><a href="mailto:contact@mysolarlending.com" className="email">contact@mysolarlending.com </a></b>  en mentionnant votre numéro de dossier.</i>
                </P>
                <p className="text-center">
                    <i> Vous remerciant pour votre confiance <br/>
                    L'équipe MySolarLending
                    </i>
                </p>
            </main>
        );
    }
}

export class SuccessPaymentCheck extends Component {
    state = {  }
    render() {
        return (
            <main>
                <Typography variant="h5" gutterBottom>
                Paiement enregistré
                </Typography>
                <P> Votre investissement est actuellement en attente. Un email de confirmation vous sera envoyé dans quelques minutes  </P>
                <P>Voici les étapes à suivre pour finaliser votre paiement:</P>
                <ol>
                    <li>
                        <P>
                        Faire un chèque bancaire de : <b color="secondary">{this.props.restToPay} EUR</b> à l'ordre de S.A.S. MYJOULEBOX en indiquant au dos du chèque le numéro de dossier suivant : <b color="secondary" style={{textDecoration: "underline"}}>{this.props.trID && this.props.trID.slice(0,8)}</b>
                    
                    </P>
                    </li>
                    <li><P>
                    Envoyer le chèque à l'adresse : MyJouleBox SAS, 40 rue Laffitte 75009 PARIS 
                    </P></li>
                    
                    <li><P>
                        Envoyer un email indiquant la date de dépôt du chèque et votre numéro de dossier à l'adresse : <b><a href="mailto:contact@mysolarlending.com" className="email">contact@mysolarlending.com </a></b> 
                    </P></li>
                </ol>
                <P><b>Sans notification d'envoi du chèque de votre part au bout de 10 jours, nous serons au regret d'annuler votre participation au financement du projet.</b></P>
                <br/>
                <p><i>En cas de problème technique ou autre : n'hésitez pas à nous contacter par email : <b><a href="mailto:contact@mysolarlending.com" className="email">contact@mysolarlending.com </a></b>  en mentionnant votre numéro de dossier.</i>
                </p>
                <P className="text-center">
                    <i> Vous remerciant pour votre confiance <br/>
                    L'équipe MySolarLending
                    </i>
                </P>
            </main>
        );
    }
}

