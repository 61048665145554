import React, { Component, Fragment } from 'react';
import { FormattedMessage } from "react-intl";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import { newCompany } from '../../../store/actions/companyAction'
import { isEmpty } from "lodash";
import Notif from "../../common/Notif";

import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';

import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';

import COUNTRIES from '../../../utils/countries'
import DatePicker from '../../common/DatePicker';
import PhotoUpload from '../../common/PhotoUpload';

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        width: '100%',
    },
    root: {
        display: 'flex',
    },
    formControl: {
        width: "100%",
    },
    group: {
        flexDirection: 'row',
    },
    selectOption: {
        marginTop: theme.spacing(1),
    },
    logoBtn: {
        margin: "auto",
        textAlign: "center",
    },
});

class CompanyForm extends Component {
    state = { 
        view: "",
        notif: {
            openNotif: false,
            variant:"",
            message: "",
        },
        errors:{
            company_logo: "",
            company_name: "",
            company_address:"",
            company_city:"",
            company_country:"",
            company_creation_date:"",
            company_presentation:"",
            company_zipcode:"",
            company_team:"",
            company_email:"",
            company_website:"",
        },
        infos:{
            company_logo: null,
            company_name: "",
            company_address:"",
            company_address2:"",
            company_city:"",
            company_country:"",
            company_creation_date:new Date(),
            company_presentation:"",
            company_zipcode:"",
            company_team:"",
            company_email:"",
            company_website:"",
        },
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        
    }

    handleClickOpen = () => {
        this.setState({ open: true });
    };
    
    handleClose = () => {
        this.setState({ 
            open: false,
            deleteDialog: false,
            deleteID: ""
        });
    };

    handleChange = (e) => {
        let field = e.target.name
        let value = e.target.value
        
        this.setState({
            ...this.state,
            infos:{
                ...this.state.infos,
                [field]: value
            }
        })
        
    }
    handleChangeDate = (date) => {
        console.log('====================================');
        console.log("CHANGE DATE",{date});
        console.log('====================================');
        
        this.setState({
            ...this.state,
            infos:{
                ...this.state.infos,
                company_creation_date: date
            }
        })
    }

    setLogo = (logo, error = "") => {
        console.log("[INFO] SET_FILES", logo);
        this.setState({
            ...this.state,
            infos:{
                ...this.state.infos,
                company_logo:logo
            },
            errors:{
                ...this.state.errors,
                company_logo:error
            }
        })
    }

    notif = (openNotif, variant, message) => {
        this.setState({
            notif:{
                openNotif,
                variant,
                message
            }
        })
    }
    validate = () => {
        let errors = {}
        let noErrors = true

        let required = [
            "company_logo",
            "company_name",
            "company_address",
            "company_city",
            "company_country",
            "company_creation_date",
            "company_presentation",
            "company_zipcode",
            "company_team",
            "company_email",
            "company_website",
        ]
        required.map( field => {
            if(isEmpty(this.state.infos[field]) && !this.state.infos[field] ){
                noErrors = false
                errors[field] = "Veuillez remplir ce champ"
            } else {
                errors[field] = ""
            }
        })
        this.setState({errors})
        return noErrors
    }
    submitInfo = async () => {
        let data = Object.assign({}, this.state.infos)
        if ( this.validate() ){
            data.company_team = parseInt(data.company_team)
            data.company_admin_id = this.props.USER.account_id
            this.props.newCompany(data)
    
            if( this.props.STATE.error ){
                this.notif(true, "error", "Un probleme est survenu")
                console.log('====================================');
                console.log("[ERROR], newCompany", this.props.STATE.error);
                console.log('====================================');
            } else {
                this.notif(true, "success", "Infos mises à jour")
                this.setState({view: "success"})
                this.timeout = setTimeout(() => {
                    this.props.success()
                }, 2500)

            }
        } else {
            this.notif(true, "warning", "Le formulaire contient des erreurs")
        }
    }
    componentWillUnmount = async () => {
        clearTimeout(this.timeout) 
    }

    render(){
        const { view } = this.state
        const { variant, openNotif, message} = this.state.notif
        const { classes } = this.props;
        console.log('====================================');
        console.log(this.state);
        console.table(this.state.infos)
        console.table(this.state.errors)
        console.log('====================================');
        return(
            <div>
                {view === "success" && 
                    <h4>Informations enregistrées</h4>
                }
                {view !== "success" &&
                <div>
                    {(openNotif && variant && message) && 
                        <Notif
                            open={openNotif}
                            onClose={()=>this.notif(false)}
                            variant={variant}
                            message={message}
                        />
                    }
                    
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                onChange={this.handleChange}
                                required
                                id="company_name"
                                error={!!this.state.errors.company_name}
                                helperText={this.state.errors.company_name}
                                value={this.state.infos.company_name}
                                name="company_name"
                                label="Nom de l'Entreprise"
                                className={classes.textField}
                                margin="normal"
                                variant="outlined"                               
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} className={classes.logoBtn}>
                            <FormControl error={!!this.state.errors.company_logo} >
                                <PhotoUpload 
                                    setPhoto={this.setLogo} 
                                    setErrPhoto={this.setErrLogo} 
                                    photo={this.state.infos.company_logo || {}} 
                                />
                                <FormHelperText >{this.state.errors.company_logo}</FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <FormControl required variant="outlined" error={!!this.state.errors.company_creation_date} className={classes.formControl}>
                                <DatePicker  
                                    id="company_creation_date" 
                                    label="Date de Création" 
                                    name="company_creation_date" 
                                    value={this.state.infos.company_creation_date} 
                                    handleChange={this.handleChangeDate} 
                                    error={!!this.state.errors.company_creation_date}
                                    />
                                    <FormHelperText >{this.state.errors.company_creation_date}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                onChange={this.handleChange}
                                required
                                id="company_team"
                                error={!!this.state.errors.company_team}
                                helperText={this.state.errors.company_team}
                                value={this.state.infos.company_team}
                                name="company_team"
                                label="Nombre d'employés"
                                className={classes.textField}
                                margin="normal"
                                variant="outlined"
                                type="number"
                            />
                        
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                onChange={this.handleChange}
                                required
                                id="company_email"
                                error={!!this.state.errors.company_email}
                                helperText={this.state.errors.company_email}
                                value={this.state.infos.company_email}
                                name="company_email"
                                label="Email"
                                className={classes.textField}
                                margin="normal"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                onChange={this.handleChange}
                                required
                                id="company_website"
                                error={!!this.state.errors.company_website}
                                helperText={this.state.errors.company_website}
                                value={this.state.infos.company_website}
                                name="company_website"
                                label="Site Web"
                                className={classes.textField}
                                margin="normal"
                                variant="outlined"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                onChange={this.handleChange}
                                required
                                id="address1"
                                error={!!this.state.errors.company_address}
                                helperText={this.state.errors.company_address}
                                value={this.state.infos.company_address}
                                name="company_address"
                                label="Adresse"
                                className={classes.textField}
                                margin="normal"
                                variant="outlined"                               
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                onChange={this.handleChange}
                                id="address2"
                                error={!!this.state.errors.company_address2}
                                helperText={this.state.errors.company_address2}
                                value={this.state.infos.company_address2}
                                name="company_address2"
                                label="Complément d'Adresse"
                                className={classes.textField}
                                margin="normal"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                onChange={this.handleChange}
                                id="zip"
                                required
                                error={!!this.state.errors.company_zipcode}
                                helperText={this.state.errors.company_zipcode}
                                value={this.state.infos.company_zipcode}
                                name="company_zipcode"
                                label="Code Postal"
                                className={classes.textField}
                                margin="normal"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                onChange={this.handleChange}
                                required
                                id="city"
                                error={!!this.state.errors.company_city}
                                helperText={this.state.errors.company_city}
                                value={this.state.infos.company_city}
                                name="company_city"
                                label="Ville"
                                className={classes.textField}
                                margin="normal"
                                variant="outlined"
                            />
                        </Grid>
                        
                        <Grid item xs={12} sm={6}>
                            <FormControl required variant="outlined" className={classes.textField} error={!!this.state.errors.company_country}>
                                <InputLabel htmlFor="company_country">Pays</InputLabel>
                                <Select
                                    native
                                    onChange={this.handleChange}
                                    required
                                    error={!!this.state.errors.company_country}
                                    value={this.state.infos.company_country}
                                    input={<OutlinedInput labelWidth={120} name="company_country" id="company_country" />}
                                >
                                    <option value="" />
                                    { Object.values(COUNTRIES).map( country  => (
                                        <option value={country} key={country} className={classes.selectOption}>{country}</option>
                                    )) }
                                </Select>
                                <FormHelperText>{this.state.errors.company_country}</FormHelperText>
                            </FormControl>
                            
                        </Grid>

                        
                        <Grid item xs={12}>
                            <TextField
                                onChange={this.handleChange}
                                required
                                id="presentation"
                                error={!!this.state.errors.company_presentation}
                                helperText={this.state.errors.company_presentation}
                                value={this.state.infos.company_presentation}
                                name="company_presentation"
                                label="Présentation de l'entreprise"
                                className={classes.textField}
                                margin="normal"
                                variant="outlined"
                                multiline
                                rows={5}
                            />
                        </Grid>
                    </Grid>
                                
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={this.submitInfo}
                    >
                        Valider
                    </Button>
                </div>
                }

            </div>
        )
    }
}


function mapStateToProps(state){// transform elements from store's state into props in this component
    return {
        PROJECTS: state.projects,
        STATE: state.request.company,
        USER: state.auth.user,
        isSuper: state.auth.isSuperAdmin
    }
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({
        newCompany,
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CompanyForm))