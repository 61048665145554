export default {
    AFG: "Afghanistan",
    ALB: "Albania",
    ALG: "Algeria",
    ASA: "American Samoa",
    AND: "Andorra",
    ANG: "Angola",
    ANT: "Antigua and Barbuda",
    ARG: "Argentina",
    ARM: "Armenia",
    ARU: "Aruba",
    AUS: "Australia",
    AUT: "Austria",
    AZE: "Azerbaijan",

    BAH: "Bahamas",
    BRN: "Bahrain",
    BAN: "Bangladesh",
    BAR: "Barbados",

    BLR: "Belarus",
    BEL: "Belgium",
    BIZ: "Belize",
    BEN: "Benin",
    BER: "Bermuda",
 
    BHU: "Bhutan",

    BOL: "Bolivia",
    BIH: "Bosnia and Herzegovina",
    BOT: "Botswana",

    BRA: "Brazil",
    BRU: "Brunei",

    BUL: "Bulgaria",
    BUR: "Burkina Faso",
    BDI: "Burundi",

    CAM: "Cambodia",
    CMR: "Cameroon",
    CAN: "Canada",
    CPV: "Cape Verde",
    CAY: "Cayman Islands",

    CAF: "Central African Republic",

    CHA: "Chad",
    CHI: "Chile",
    CHN: "China",
    
    COL: "Colombia",
    COM: "Comoros",
    CGO: "Congo",
    COD: "DR Congo",
    COK: "Cook Islands",
    CRC: "Costa Rica",
    CIV: "Cote d'Ivoire",
    
    CRO: "Croatia",
    
    CUB: "Cuba",
    
    CYP: "Cyprus",
    CZE: "Czech Republic",
    
    DEN: "Denmark",
    DJI: "Djibouti",
    DMA: "Dominica",
    DOM: "Dominican Republic",
    
    ECU: "Ecuador",
    EGY: "Egypt",
    ESA: "El Salvador",
    ERI: "Eritrea",
    EST: "Estonia",
    ETH: "Ethiopia",

    FIJ: "Fiji",
    FIN: "Finland",
    FRA: "France",
    
    FSM: "Micronesia",
    GAB: "Gabon",
    GAM: "Gambia",
    GBR: "Great Britain",
    GBS: "Guinea-Bissau",
    GEO: "Georgia",
    GEQ: "Equatorial Guinea",
    GER: "Germany",
    GHA: "Ghana",
    GRE: "Greece",
    GRN: "Grenada",
    GUA: "Guatemala",
    GUI: "Guinea",
    GUM: "Guam",
    GUY: "Guyana",
    HAI: "Haiti",
    HKG: "Hong Kong",
    HON: "Honduras",
    HUN: "Hungary",
    INA: "Indonesia",
    IND: "India",
    IRI: "Iran",
    IRL: "Ireland",
    IRQ: "Iraq",
    ISL: "Iceland",
    ISR: "Israel",
    ISV: "Virgin Islands",
    ITA: "Italy",
    IVB: "British Virgin Islands",
    JAM: "Jamaica",
    JOR: "Jordan",
    JPN: "Japan",
    KAZ: "Kazakhstan",
    KEN: "Kenya",
    KGZ: "Kyrgyzstan",
    KIR: "Kiribati",
    KOR: "South Korea",
    KSA: "Saudi Arabia",
    KUW: "Kuwait",
    LAO: "Laos",
    LAT: "Latvia",
    LBA: "Libya",
    LBR: "Liberia",
    LCA: "Saint Lucia",
    LES: "Lesotho",
    LIB: "Lebanon",
    LIE: "Liechtenstein",
    LTU: "Lithuania",
    LUX: "Luxembourg",
    MAD: "Madagascar",
    MAR: "Morocco",
    MAS: "Malaysia",
    MAW: "Malawi",
    MDA: "Moldova",
    MDV: "Maldives",
    MEX: "Mexico",
    MGL: "Mongolia",
    MHL: "Marshall Islands",
    MKD: "Macedonia",
    MLI: "Mali",
    MLT: "Malta",
    MNE: "Montenegro",
    MON: "Monaco",
    MOZ: "Mozambique",
    MRI: "Mauritius",
    MTN: "Mauritania",
    MYA: "Myanmar",
    NAM: "Namibia",
    NCA: "Nicaragua",
    NED: "Netherlands",
    NEP: "Nepal",
    NGR: "Nigeria",
    NIG: "Niger",
    NOR: "Norway",
    NRU: "Nauru",
    NZL: "New Zealand",
    OMA: "Oman",
    PAK: "Pakistan",
    PAN: "Panama",
    PAR: "Paraguay",
    PER: "Peru",
    PHI: "Philippines",
    PLE: "Palestine",
    PLW: "Palau",
    PNG: "Papua New Guinea",
    POL: "Poland",
    POR: "Portugal",
    PRK: "North Korea",
    PUR: "Puerto Rico",
    QAT: "Qatar",
    ROU: "Romania",
    RSA: "South Africa",
    RUS: "Russia",
    RWA: "Rwanda",
    SAM: "Samoa",
    SEN: "Senegal",
    SEY: "Seychelles",
    SIN: "Singapore",
    SKN: "Saint Kitts and Nevis",
    SLE: "Sierra Leone",
    SLO: "Slovenia",
    SMR: "San Marino",
    SOL: "Solomon Islands",
    SOM: "Somalia",
    ESP: "Spain",

    SRB: "Serbia",
    SRI: "Sri Lanka",
    STP: "Sao Tome and Principe",
    SUD: "Sudan",
    SUI: "Switzerland",
    SUR: "Suriname",
    SVK: "Slovakia",
    SWE: "Sweden",
    SWZ: "Swaziland",
    SYR: "Syria",
    TAN: "Tanzania",
    TGA: "Tonga",
    THA: "Thailand",
    TJA: "Tajikistan",
    TKM: "Turkmenistan",
    TLS: "Timor-Leste",
    TOG: "Togo",
    TPE: "Chinese Taipei",
    TRI: "Trinidad and Tobago",
    TUN: "Tunisia",
    TUR: "Turkey",
    TUV: "Tuvalu",
    UAE: "United Arab Emirates",
    UGA: "Uganda",
    UKR: "Ukraine",
    URU: "Uruguay",
    USA: "United States",
    UZB: "Uzbekistan",
    VAN: "Vanuatu",
    VEN: "Venezuela",
    VIE: "Vietnam",
    VIN: "Saint Vincent and the Grenadines",
    YEM: "Yemen",
    ZAM: "Zambia",
    ZIM: "Zimbabwe"
}



const COUNTRIES_ARR = [
    "Afghanistan", 
    "Aland Islands", 
    "Albania", 
    "Algeria", 
    "American Samoa", 
    "Andorra", 
    "Angola", 
    "Anguilla", 
    "Antarctica", 
    "Antigua", 
    "Argentina", 
    "Armenia", 
    "Aruba", 
    "Australia", 
    "Austria", 
    "Azerbaijan", 
    "Bahamas", 
    "Bahrain", 
    "Bangladesh", 
    "Barbados", 
    "Barbuda", 
    "Belarus", 
    "Belgium", 
    "Belize", 
    "Benin", 
    "Bermuda", 
    "Bhutan", 
    "Bolivia", 
    "Bosnia", 
    "Botswana", 
    "Bouvet Island", 
    "Brazil", 
    "British Indian Ocean Trty.", 
    "Brunei Darussalam", 
    "Bulgaria", 
    "Burkina Faso", 
    "Burundi", 
    "Caicos Islands", 
    "Cambodia", 
    "Cameroon", 
    "Canada", 
    "Cape Verde", 
    "Cayman Islands", 
    "Central African Republic", 
    "Chad", 
    "Chile", 
    "China", 
    "Christmas Island", 
    "Cocos (Keeling) Islands", 
    "Colombia", 
    "Comoros", 
    "Congo", 
    "Congo, Democratic Republic of the", 
    "Cook Islands", 
    "Costa Rica", 
    "Cote d'Ivoire", 
    "Croatia", 
    "Cuba", 
    "Cyprus", 
    "Czech Republic", 
    "Denmark", 
    "Djibouti", 
    "Dominica", 
    "Dominican Republic", 
    "Ecuador", 
    "Egypt", 
    "El Salvador", 
    "Equatorial Guinea", 
    "Eritrea", 
    "Estonia", 
    "Ethiopia", 
    "Falkland Islands (Malvinas)", 
    "Faroe Islands", 
    "Fiji", 
    "Finland", 
    "France", 
    "French Guiana", 
    "French Polynesia", 
    "French Southern Territories", 
    "Futuna Islands", 
    "Gabon", 
    "Gambia", 
    "Georgia", 
    "Germany", 
    "Ghana", 
    "Gibraltar", 
    "Greece", 
    "Greenland", 
    "Grenada", 
    "Guadeloupe", 
    "Guam", 
    "Guatemala", 
    "Guernsey", 
    "Guinea", 
    "Guinea-Bissau", 
    "Guyana", 
    "Haiti", 
    "Heard", 
    "Herzegovina", 
    "Holy See", 
    "Honduras", 
    "Hong Kong", 
    "Hungary", 
    "Iceland", 
    "India", 
    "Indonesia", 
    "Iran (Islamic Republic of)", 
    "Iraq", 
    "Ireland", 
    "Isle of Man", 
    "Israel", 
    "Italy", 
    "Jamaica", 
    "Jan Mayen Islands", 
    "Japan", 
    "Jersey", 
    "Jordan", 
    "Kazakhstan", 
    "Kenya", 
    "Kiribati", 
    "Korea", 
    "Korea (Democratic)", 
    "Kuwait", 
    "Kyrgyzstan", 
    "Lao", 
    "Latvia", 
    "Lebanon", 
    "Lesotho", 
    "Liberia", 
    "Libyan Arab Jamahiriya", 
    "Liechtenstein", 
    "Lithuania", 
    "Luxembourg", 
    "Macao", 
    "Macedonia", 
    "Madagascar", 
    "Malawi", 
    "Malaysia", 
    "Maldives", 
    "Mali", 
    "Malta", 
    "Marshall Islands", 
    "Martinique", 
    "Mauritania", 
    "Mauritius", 
    "Mayotte", 
    "McDonald Islands", 
    "Mexico", 
    "Micronesia", 
    "Miquelon", 
    "Moldova", 
    "Monaco", 
    "Mongolia", 
    "Montenegro", 
    "Montserrat", 
    "Morocco", 
    "Mozambique", 
    "Myanmar", 
    "Namibia", 
    "Nauru", 
    "Nepal", 
    "Netherlands", 
    "Netherlands Antilles", 
    "Nevis", 
    "New Caledonia", 
    "New Zealand", 
    "Nicaragua", 
    "Niger", 
    "Nigeria", 
    "Niue", 
    "Norfolk Island", 
    "Northern Mariana Islands", 
    "Norway", 
    "Oman", 
    "Pakistan", 
    "Palau", 
    "Palestinian Territory, Occupied", 
    "Panama", 
    "Papua New Guinea", 
    "Paraguay", 
    "Peru", 
    "Philippines", 
    "Pitcairn", 
    "Poland", 
    "Portugal", 
    "Principe", 
    "Puerto Rico", 
    "Qatar", 
    "Reunion", 
    "Romania", 
    "Russian Federation", 
    "Rwanda", 
    "Saint Barthelemy", 
    "Saint Helena", 
    "Saint Kitts", 
    "Saint Lucia", 
    "Saint Martin (French part)", 
    "Saint Pierre", 
    "Saint Vincent", 
    "Samoa", 
    "San Marino", 
    "Sao Tome", 
    "Saudi Arabia", 
    "Senegal", 
    "Serbia", 
    "Seychelles", 
    "Sierra Leone", 
    "Singapore", 
    "Slovakia", 
    "Slovenia", 
    "Solomon Islands", 
    "Somalia", 
    "South Africa", 
    "South Georgia", 
    "South Sandwich Islands", 
    "Spain", 
    "Sri Lanka", 
    "Sudan", 
    "Suriname", 
    "Svalbard", 
    "Swaziland", 
    "Sweden", 
    "Switzerland", 
    "Syrian Arab Republic", 
    "Taiwan", 
    "Tajikistan", 
    "Tanzania", 
    "Thailand", 
    "The Grenadines", 
    "Timor-Leste", 
    "Tobago", 
    "Togo", 
    "Tokelau", 
    "Tonga", 
    "Trinidad", 
    "Tunisia", 
    "Turkey", 
    "Turkmenistan", 
    "Turks Islands", 
    "Tuvalu", 
    "Uganda", 
    "Ukraine", 
    "United Arab Emirates", 
    "United Kingdom", 
    "United States", 
    "Uruguay", 
    "US Minor Outlying Islands", 
    "Uzbekistan", 
    "Vanuatu", 
    "Vatican City State", 
    "Venezuela", 
    "Vietnam", 
    "Virgin Islands (British)", 
    "Virgin Islands (US)", 
    "Wallis", 
    "Western Sahara", 
    "Yemen", 
    "Zambia", 
    "Zimbabwe"
]