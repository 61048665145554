import React, { Component } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { fetchUsers, newUser } from '../../store/actions/userAction'
import { fetchProducts, completeProduct } from '../../store/actions/productAction'

import SideBar from "../../container/layouts/SideBar";
import UsersList from "./Users/UsersList";
import ProductsList from "./Products/ProductsList";
import { Typography } from '@material-ui/core';
import { Home, People, ListAltRounded, SettingsRounded} from '@material-ui/icons';
import Notif from '../common/Notif';
import SellerSettings from './Settings/SellerSettings';
import CompanyInfo from './Company/CompanyInfo';

class Dashboard extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	render() {
		
		return (
			<div className="min-view">
                <Typography variant="h5" style={{textAlign: "center", margin: "25px 0 40px"}}>Dashboard</Typography>
            </div>
		);
	}
}

class AdminDashboard extends Component {
    state = { 
        notif: {
            openNotif: false,
            variant:"",
            message: "",
        }
    }
    notif = (variant, message) => {
        console.log('==================NOTIF==================');
        console.log(variant, message);
        console.log('====================================');
        this.setState({
            notif:{
                openNotif: true,
                variant,
                message
            }
        })
    }
    handleCloseNotif = (event, reason) => {
        this.setState({ 
            notif: {
                openNotif: false,
                variant:"",
                message: "",
            }
        });
    };

    render() {
        const { page } = this.props.match.params;
        const { variant, message, openNotif } = this.state.notif;
		let menuList = [
			{ page: "dashboard", default: "Dashboard", icon:<Home />, to: "/admin/dashboard" },
            { page: "users", default: "Utilisateurs", icon:<People />, to: "/admin/users" },
            { page: "products", default: "Produits", icon:<ListAltRounded />, to: "/admin/products" },
            { page: "settings", default: "Paramètres", icon:<SettingsRounded />, to: "/admin/settings" },
		];

		return (
			<SideBar page={"admin"} menu={menuList} {...this.props}>
                {openNotif && variant && message &&
                    <Notif open={true} {...this.state.notif} onClose={this.handleCloseNotif} />
                }
				{page === "dashboard" && <Dashboard /> }
				{page === "users" && <UsersList {...this.props} notif={this.notif} />}
				{page === "products" && <ProductsList {...this.props} notif={this.notif} />}
				{page === "settings" && <CompanyInfo {...this.props} notif={this.notif} />}
			</SideBar>
		);
    }
}


function mapStateToProps(state){// transform elements from store's state into props in this component
    return {
        isSuper: state.auth.isSuperAdmin,
        USERS: state.users,
        PRODUCTS: state.products,
        STOCKS: state.stocks,
        STATE: state.request,
    }
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({
        fetchUsers,
        newUser,
        fetchProducts,
        completeProduct,
        // newProduct,
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboard); // RequireAdmin
