export function productCatReducer(state = {
    "panneaux":[],
    "regulateurs":[],
    "convertisseurs":[],
    "convertisseurs_chargeurs":[],
    "convertisseurs_chargeurs_MPPT":[],
    "convertisseurs_dc-dc":[],
    "batteries":[],
    "cables":[],
    "lampes":[],
    "supervision":[],
    "kit":[],
    "menager":[],
    "pompes":[],
    "picos":[],
    "accesoires":[],
    "protection":[],
    "autres":[]
    
}, {
    type,
    payload
}){
    
    
    switch(type){
        case "GET_PRODUCTS_CAT":
            let list = Object.assign({}, state)
            list = payload
            return list

        default:
            return state;
    }
    
}
