import axios from 'axios'
import  { checkError } from './authAction'

export function newCompany(data) {
    return async function(dispatch){
        try{
            console.log('====================================');
            console.log({data});
            console.log('====================================');

            dispatch({ type: "CREATE_COMPANY_START", payload: "" })
            console.log("new Company START")
            data.company_logo = data.company_logo.data_uri
            const res = await axios.post('/company/new',data)
            console.log({res});
            
            if (!res.data.error){
                // fetched true
                let company = {[res.data.company_id]: res.data}
                dispatch({type: "NEW_COMPANY", payload: company})
                dispatch({ type: "CREATE_COMPANY_FULFILLED", payload: "" })
            }
        } catch(err) {
            console.log("[ERROR] new company ", err);
            dispatch({
                type: "CREATE_COMPANY_ERROR",
                payload: err
                
            })
        }
    }
}

// export function fetchCompanies() {
//     return async function(dispatch){
//         try{
//             dispatch({ type: "FETCH_COMPANY_START", payload: "" })
//             console.log("fetch Company START")
//             const res = await axios.get('/company/all')
//             console.log('[DEBUG],action,store,fetchCompanies,',{res});
            
//             if (!res.data.error){
//                 dispatch({
//                     type: "GET_COMPANY",
//                     payload: formatList(res.data)
//                 })
//                 dispatch({ type: "FETCH_COMPANY_FULFILLED", payload: "" })
//             } else {

//                 dispatch({
//                     type: "FETCH_COMPANY_ERROR",
//                     payload: res.data.error
                    
//                 })
//             }
//         } catch(err) {
//             console.log("[ERROR] get company list ", err);
//             dispatch({
//                 type: "FETCH_COMPANY_ERROR",
//                 payload: err
//             })
//         }
//     }
// }

export function getCompanyLogo(companyID,filename) {
    return async function(dispatch){   
        dispatch({ type: "GET_COMPANY_LOGO_START", payload: "" })     
        let res = await axios.post("/company/getPhoto",{
                path:filename
            })
        
            if(res.data.error){
                console.log("ERROR", res.data.error);
                dispatch({ type: "GET_COMPANY_LOGO_ERROR", payload: res.data.error.message })
            } else {
                dispatch({
                    type: "SET_COMPANY_LOGO",
                    payload: {companyID, URI:res.data}
                    
                })
                dispatch({ type: "GET_COMPANY_LOGO_FULFILLED", payload: "" })
            }
            
        
    }
}
