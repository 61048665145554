
import React, { Component, Fragment } from 'react';
import {
	CssBaseline,Paper,Button,Typography,Grid,Card,CardContent,Box
} from "@material-ui/core";

class PaymentsMethods extends Component {
    state = {  }
    render() {
        const { classes } = this.props;

        return (
            
            <div>
                <Typography component="p" variant="h6" align="center" gutterBottom>
                    Choisissez un mode de paiement
                </Typography>
                
                <Grid container spacing={3}>
                    <Grid item xs={12} >
                        <Card className={(this.props.paymentType === "CB" ? classes.cardSelected : classes.card )} onClick={() => this.setState({paymentType: "CB"}) }>
                            <CardContent>
                                <Typography variant="h5">Carte Bleue</Typography>
                                <Typography>Payer par Carte Visa, MasterCard ou CB</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card className={(this.props.paymentType === "transfer" ? classes.cardSelected : classes.card )} onClick={() => this.setState({paymentType: "transfer"}) }>
                            <CardContent>
                                <Typography variant="h5">Virement bancaire</Typography>
                                <Typography>Payer par virement bancaire.</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card className={(this.props.paymentType === "check" ? classes.cardSelected : classes.card )} onClick={() => this.setState({paymentType: "check"}) }>
                            <CardContent>
                                <Typography variant="h5">Chèque</Typography>
                                <Typography>Payer par chèque à envoyer par courrier.</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>                                
                
            </div>
                    
        );
    }
}

export default PaymentsMethods;