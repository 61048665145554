import React, { Component } from 'react';
import { isEmpty } from "lodash";

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    FormHelperText,
    FormControl,
    TextField,
    InputLabel,
    Select,
    OutlinedInput,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import Transition from '../../../container/MUI/Transistion';

class UserForm extends Component {
    state = { 
        email: "",
        password: "",
        user_role: 1,
        confirm_password: "",
        account_id: "",
        errors:{
            email: "",
            password: "",
            user_role: "",
            confirm_password: "",
            account_id: "",
        }
    }
    handleChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        
        if(name === "password"){
            this.validPassword(value)
        }
        this.setState({
            [name]: value
        })
        
    }

    validPassword = (password) => {
        let isValid = true
        let re = /[0-9]/;
        if(!re.test(password)) {
            isValid = false
        }
        re = /[a-z]/;
        if(!re.test(password)) {
            isValid = false
        }
        re = /[A-z]/;
        if(!re.test(password)) {
            isValid = false
        }
        if(password.length < 6){
            isValid = false
        }

        return isValid;
    }
    
    validate = () => {
        let required = [
            "email",
            "password",
            "confirm_password",
            "user_role"
        ]
        
        let noError = true
        let errors = {}

       
        required.map(field => {
            if(!this.state[field]){
                console.log(`${field} is empty`)
                noError =  false
                errors[field] = "Veuillez remplir ce Champ" 
            } else {
                errors[field] = "" 
            }
        })

        if (this.state.password) {
            let isValid = this.validPassword(this.state.password);
            if (!isValid){
                errors.password =  "Le mot de passe doit contenir au minimum 6 caractères dont un chiffre, une majuscule et une minuscule"
                noError = false
            } else if (this.state.password !== this.state.confirm_password){
                errors.confirm_password =  "Le mot de passe et sa confirmation doivent être identiques"
                noError = false
            } else {
                errors.password = ""   
            }
        }

        if(parseInt(this.state.user_role) === 2 && isEmpty(this.state.account_id)){
                console.log(`account is empty`)
                noError =  false
                errors.account_id = "Veuillez remplir ce champ" 
        } else {
            errors.account_id = "" 
        
        }
        this.setState({
            errors
        })
    
        return noError
    }
    handleSubmit = async () => {
        let data = {
            email : this.state.email,
            password : this.state.password,
            user_role : parseInt(this.state.user_role) || 1,
            account_id : this.state.account_id,
        }

        console.log('====================================');
        console.log({data});
        console.log('====================================');
        if(this.validate()){
            // data.admin_account_id = 
            data.user_creation_date = new Date()
            data.user_update_date = data.user_creation_date 

            await this.props.newUser(data)  
            if(this.props.STATE.users.error){
                this.props.notif("error",`Un problème est survenu: ${this.props.STATE.users.error}`)
            } else{
                this.props.notif("success","Le compte a été créé avec succès")
                this.props.handleClose()
                // this.clearForms()
            }
        } else {
            this.props.notif("error", "Le formulaire contient des erreurs" )
        }
    }
    render() {
        return(
            <div>
                <Dialog
                    disableBackdropClick
                    open={this.props.open}
                    onClose={this.props.handleClose}
                    aria-labelledby="form-dialog-title"
                    fullScreen
                    TransitionComponent={Transition}
                    scroll="body"
                    >
                    <AppBar style={{position: 'relative'}} color="secondary">
                        <Toolbar>
                            <IconButton color="inherit" onClick={this.props.handleClose} aria-label="Close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" color="inherit">
                                Créer un nouvel Utilisateur
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <DialogContent  style={{overflow: "hidden"}}>
                        <div >
                            <TextField
                                style={{width:"100%"}}
                                required
                                margin="normal"
                                variant="outlined"  
                                name="email" 
                                id="email" 
                                value={this.state.email} 
                                label="Email de l'utilisateur" 
                                placeholder="Email" 
                                error={!!this.state.errors.email} 
                                helperText={this.state.errors.email} 
                                onChange={this.handleChange}/>
                            <TextField
                                style={{width:"100%"}}
                                margin="normal"
                                type="password"
                                required
                                variant="outlined"  
                                name="password" 
                                id="password" 
                                value={this.state.password} 
                                label="Mot de passe" 
                                placeholder="Mot de passe de l'utilisateur" 
                                error={!!this.state.errors.password} 
                                helperText={this.state.errors.password} 
                                onChange={this.handleChange}/>
                            <TextField
                                style={{width:"100%"}}
                                margin="normal"
                                type="password"
                                required
                                variant="outlined"  
                                name="confirm_password" 
                                id="confirm_password" 
                                value={this.state.confirm_password} 
                                label="Confirmer le Mot de Passe" 
                                placeholder="Confirmer le Mot de Passe" 
                                error={!!this.state.errors.confirm_password} 
                                helperText={this.state.errors.confirm_password} 
                                onChange={this.handleChange}/>

                            <FormControl variant="outlined"  error={!!this.state.errors.user_role} style={{width: "100%"}}>
                                <InputLabel htmlFor="user_role">Role</InputLabel>
                                <Select
                                    native
                                    onChange={this.handleChange}
                                    error={!!this.state.errors.user_role}
                                    value={this.state.user_role}
                                    input={<OutlinedInput labelWidth={50} name="user_role" id="user_role" />}
                                    
                                >
                                    <option value={1} >USER</option>
                                    <option value={2} >ADMIN</option>
                                    
                                </Select>
                                <FormHelperText>{this.state.errors.user_role}</FormHelperText>
                            </FormControl>

                            {parseInt(this.state.user_role) === 2 &&
                                <TextField
                                    style={{width:"100%"}}
                                    required
                                    margin="normal"
                                    variant="outlined"  
                                    name="account_id" 
                                    id="account_id" 
                                    value={this.state.account_id} 
                                    label="ID Compte Magasin" 
                                    placeholder="ID Compte Magasin" 
                                    error={!!this.state.errors.account_id} 
                                    helperText={this.state.errors.account_id} 
                                    onChange={this.handleChange} 
                                />
                            }
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={this.handleSubmit} color="secondary">
                            Créer
                        </Button>
                        <Button onClick={this.props.handleClose} color="default">
                            Annuler
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )

    }
}

export default UserForm;