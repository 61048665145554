import React, { Component } from 'react';
import { isEmpty } from "lodash";
import axios from "axios"
import { Grid, Box, Table, TableBody, TableRow, TableCell, Typography, Button, TextField, Paper, Divider, FormControl, InputLabel, Select, OutlinedInput, IconButton } from '@material-ui/core';
import Currency from '../common/Currency';
import { Add, FavoriteBorderRounded, FavoriteRounded } from '@material-ui/icons';
import SwipeableTabs from '../../container/MUI/SwipeableTabs';
import noImage from "../../images/no_image.png"
import Spinner from '../common/Spinner';
import { TopBlock, TopBlockEmpty } from './SingleBlocks/TopBlock';
import DescBlockEmpty from './SingleBlocks/DescBlock';
import SellerBlockEmpty, { SellerBlock } from './SingleBlocks/SellerBlock';
import { StrippedTable } from '../../container/MUI/StrippedTable';

class SingleProduct extends Component {
    state = { 
        product: {},
        company: {},
        quantity: 1,
        errors:{
            quantity: ""
        }
    }
    componentDidMount = async () => {
		const { PRODUCTS, STATE, productID } = this.props;

        
        if (!isEmpty(PRODUCTS[productID])){
            this.setState({
                product: PRODUCTS[productID]
            })
            let res = await axios.post("/company/getCompany", {account_id: PRODUCTS[productID].account_id})
            if (res.data.company_id){
                this.setState({
                    company: res.data,
                })
            }
        } else {
            // redirect to list
        }

    };
    
    displayFields(){
		const { PRODUCTS, productID } = this.props;
        console.log("[INFO] displayFields");

        const prod = PRODUCTS[productID] || {}
        const match = {
            "Garantie (Années)": prod.garantie,
            // "Marque": prod.marque,
            // "Nom": prod.name,
            // "Type": prod.product_type,
            // "Prix HT": prod.prix_venteHT,
            // "Prix TTC": prod.prix_venteTTC,
            // "Référence": prod.ref,
            // " ": prod.photos,
            "Poids (Kg)": prod.poids,   
            "Largeur (mm)": prod.largeur,   
            "Longueur (mm)": prod.longueur,   
            "Hauteur (mm)": prod.hauteur, 

            "Auto décharge": prod.bat_auto_decharge,
            "Capacité_Max(Ah)": prod.bat_max_capacity,
            "Type de batterie" : prod.bat_type,
            "Tension (V)": prod.bat_tension,
            "Nb cycles": prod.bat_nb_cycles,
            "Nb cycles à  30% de DOD": prod.bat_nb_cycles30,
            "Nb cycles à  50% de DOD": prod.bat_nb_cycles50,
            "Nb cycles à  80% de DOD": prod.bat_nb_cycles80,
            "Rendement énergétique (%)": prod.bat_rendement,            
            "Température d'exploitation": prod.bat_temperature,
            "Puissance (W)": prod.pan_puissance,            
            "Cellules": prod.pan_cell,
            "Tension (Voc)" : prod.pan_tensionVoc || prod.pan_tension,
            "Courant (Ioc)": prod.pan_courantIoc || prod.pan_courant,
            // "Tension(Voc)" : prod.pan_tensionVoc,
            // "Courant(Ioc)": prod.pan_courantIoc,
            "Tension (Vmpp)" : prod.pan_tensionVmpp,
            "Courant (Impp)": prod.pan_courantImpp,
            // "Hauteur (mm)": prod.pan_height,
            // "Largeur (mm)": prod.pan_width,
            "Profondeur (mm)": prod.pan_profondeur,
            "Rendement (%)": prod.pan_rendement,
            "Nombre de Conducteurs ": prod.cab_nb_conducteurs,
            "Section des conducteurs": prod.cab_section,
            "Type de conduits": prod.cab_type,
            "Tension de charge 'absorption'": prod.conv_absorption,
            "Courant de de charge de batterie de service (A)": prod.conv_courant_service,
            "Courant de de charge de batterie de démarrage (A)": prod.conv_courant_start,
            "Efficacité maximale": prod.conv_eff_max,
            "Tension de charge 'float' (VCC)": prod.conv_float,
            "Puissance de sortie (VA) ": prod.conv_puissance,
            "Puissance à 25°C (W) ": prod.conv_puissance25,
            "Puissance à 40°C (W) ": prod.conv_puissance40,
            "Puissance à 65°C (W)": prod.conv_puissance65,
            "Puissance crête": prod.conv_puissance_crete,
            "Type de signal": prod.conv_signal,
            "Puissance de sortie continue à 25°C (W)": prod.conv_sortie25,
            "Puissance de sortie continue à 40°C (W)": prod.conv_sortie40,
            "Tension entrée": prod.conv_tension,
            "Plage de tension d'entrée (VDC) max": prod.conv_tension_max,
            "Plage de tension d'entrée (VDC) min": prod.conv_tension_min,
            "Tension sortie nominale": prod.conv_tension_sortie,
            "Courant de Sortie Max": prod.conv_MPPT_courant_sortie,
            "Tension PV Max": prod.conv_MPPT_tensionPV_max,
            "Puissance PV Max": prod.conv_MPPT_puissancePV_max,

            "Fonctionnement en triphasé": prod.conv_triphase,
            "Flux Lumineux (lm)": prod.lam_flux,
            "Puissance (W)": prod.lam_puissance || prod.pomp_puissance,
            "Tension d'alimentation": prod.lam_tension,
            "Affichage": prod.reg_affichage,
            "Courant de charge nominal (V)": prod.reg_courant,
            "Puissance nominale PV 12V ": prod.reg_puissance_pv12,
            "Puissance nominale PV 24V ": prod.reg_puissance_pv24,
            "Puissance nominale PV 48V ": prod.reg_puissance_pv48,
            "Tension PV maximale en circuit ouvert": prod.reg_tension_max,
            "Type de Régulateur": prod.reg_type,
            "Type Equipement": prod.men_type,
            "Puissance à vide (W)": prod.tv_puissance_vide,
            "Puissance en Fonctionnement": prod.tv_puissance_fonc,
            "Taille (pouces)": prod.tv_taille,
            "Puissance (W)": prod.vent_puissance,
            "Autres": prod.men_infos,            
            "Description": prod.desc,            
        }
        
        return Object.keys(match).map( field => {            
            if(match[field]){
                let data = match[field]
                if(Array.isArray(data)){
                    data.sort( (a, b) => {
                        return a - b;
                    })
                    data = data.join(' / ')
                }
                return(  
                    <StrippedTable key={field}>
                        <TableCell>{field} :</TableCell>
                        <TableCell>{data}</TableCell>
                    </StrippedTable>
                )
                
            }
        })
    }
    
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    addCart = async (product, qty) => {
        qty = parseInt(qty)
        if(qty > 0) {
            await this.props.addInCart(product, qty)
            if (this.props.STATE.cart.fetched) {
                this.props.notif("success", "Article ajouté au panier" )
            }
        } else {
            this.setState({
                errors:{
                    quantity: "Quantité invalide"
                }
            })
        }
    }
    
    render() {
        const { productID, PRODUCTS, STOCKS } = this.props;
        let product = PRODUCTS[productID] || {}
        console.log('====================================');
        console.log({product});
        console.log('====================================');
        let product_title = product.product_title || product.name
        
        let available = STOCKS[productID] || 0
        let max = available >= 20 ? 20 : available
        let qtyList = []
        for (let index = 1; index <= max; index++) {
            qtyList.push(
                <option value={index}>{index}</option>
            )
            
        }
        return (
            <Box>
                <Grid container spacing={2}>
                    <Grid sm={12} md={5} item style={{ height: "400px" }}>
                        <Box 
                            variant="outlined" 
                            style={{ position: "relative", height: "100%"}}
                        >
                            {isEmpty(product.photos) && 
                                <img 
                                    className="img-miniature"
                                    src={noImage} 
                                    alt={product_title} 
                                    title={product_title} 
                                />
                            }
                            {!isEmpty(product.photos) && isEmpty(product.mainPhoto) &&
                            <Paper elevation={0} square style={{ position: "relative", height: "400px"}}>
                                <Spinner noOverlay/>
                            </Paper>
                            }
                            {!isEmpty(product.mainPhoto) &&
                                <img 
                                    className="img-miniature"
                                    src={product.mainPhoto} 
                                    alt={product_title} 
                                    title={product_title} 
                                />
                            }
                        </Box> 
                    </Grid>
                            
                    <Grid sm={12} md={7} item>
                        {!isEmpty(product) &&
                            <Box>
                                <TopBlock product={product} title={product_title} />
                                {available > 0 &&
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <FormControl variant="outlined" style={{width: "50%"}} size="small">
                                                <InputLabel htmlFor="quantity">Quantité</InputLabel>
                                                <Select
                                                    native
                                                    onChange={this.handleChange}
                                                    value={this.state.quantity}
                                                    name="quantity"
                                                    input={<OutlinedInput labelWidth={80} name="quantity" id="quantity" />}
                                                >
                                                    {qtyList}
                                                </Select>
                                            </FormControl>
                                            </Grid>
                                                
                                            <Grid item xs={12}>
                                            <Button 
                                                variant="contained" 
                                                color="secondary"
                                                onClick={() => this.addCart(product, this.state.quantity)}
                                                title="Ajouter au panier"
                                                style={{margin: "0 5px"}}
                                            >
                                                <Add /> Ajouter au panier
                                            </Button>
                                            {/* <IconButton color="primary">
                                                <FavoriteBorderRounded />
                                            </IconButton> */}
                                            {/* <IconButton>
                                                <FavoriteRounded color="error" />
                                            </IconButton> */}
                                            {available < 5 &&
                                                <Box >
                                                    <Typography variant="button" style={{color: "orange", fontWeight: "bold", fontStyle:"italic"}}>Bientot épuisé</Typography>
                                                </Box>
                                            }

                                        </Grid>
                                    </Grid>
                                }
                                {available < 1 &&
                                    <Box p={2}>
                                        <Typography variant="button" style={{color: "red", fontWeight: "bold", fontStyle:"italic"}}>Épuisé</Typography>
                                    </Box>
                                }
                                {!isEmpty(this.state.company) &&
                                    <SellerBlock {...this.state.company} />
                                }
                                {/* {isEmpty(this.state.company) &&
                                    <SellerBlockEmpty />
                                } */}
                            </Box>
                        }
                        {isEmpty(product) &&
                            <TopBlockEmpty />
                        }
                        
                    </Grid>

                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <SwipeableTabs
                            labels={["Caractéristiques", "Description"]}
                        >
                            <Box>
                                {!isEmpty(product) &&
                                    <Table>
                                        <TableBody>
                                            {this.displayFields()}
                                        </TableBody>
                                    </Table>
                                }
                                {isEmpty(product) &&
                                    <DescBlockEmpty />
                                }

                            </Box>
                            <Box>
                                {/* <h4>Détails</h4> */}
                                <p style={{ whiteSpace: "pre-line"}}>
                                    {product.product_desc}
                                </p>
                                {/* <h4>Autres</h4>
                                {product.datasheets &&
                                    <img 
                                        src={product.mainPhoto} 
                                        alt={product_title} 
                                        title={product_title} 
                                    />
                                } */}
                            </Box>

                        </SwipeableTabs>
                    </Grid>
                </Grid>
            </Box>
        );
    }
}

export default SingleProduct;