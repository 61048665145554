import React, { Component } from 'react';
import { Link } from "react-router-dom";

import block1 from "../images/block1.jpg"
import block2 from "../images/block2.jpg"
import block4 from "../images/block4.jpg"
import block5 from "../images/block3.jpeg"

import cloud1 from "../images/bckg-yellow-black-lblue.png"
import cloud2 from "../images/bckg-black-yellow.png"
import cloud3 from "../images/bckg-black-purple.png"
import cloud4 from "../images/cloud6.jpg"

import { Grid, Typography, Box, Paper } from '@material-ui/core';
import FullPage from '../container/layouts/FullPage';
import CountryList from './Country/CountryList';

class HomePage extends Component {
    state = { 
        diapo: block1
    }

    componentDidMount = () => {

        let diapos = [block1, block2, block4]
        let number = 0

        this.timer = setInterval(() => {
            console.log('====================================');
            console.log("change diapo", diapos[number]);
            console.log('====================================');
            this.setState({
                diapo: diapos[number]
            })
            if (number < diapos.length - 1) {
                number++
            } else {
                number = 0
            }
        }, 10000);
    }
    
    componentWillUnmount = () => {
        clearInterval(this.timer) 

    }
    render() {
        const { CLASSES } = this.props;
        const { diapo } = this.state;
        return (
            
            <FullPage>
                <div>
                    <Grid container className="jumbotron-cover" style={{ backgroundImage: `url(${[diapo]})` }}>
                        <Grid item xs={12} sm={4}>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <Typography variant="h4" className={CLASSES.transparentBckgText} style={{marginTop: "30%"}}>Solar Africa Shop</Typography>
                            <Typography variant="body1" className={CLASSES.transparentBckgText} >by MyJouleBox</Typography>
                        </Grid>
                    </Grid>
                    <Box p={2} className="container">
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={6} lg={3}  style={{ overflow: "hidden"}}>
                                <Link to={"/products/type/panneaux"}>
                                    <Paper style={{ padding: "0px", backgroundImage: `url(${cloud1})`}} className="homeSection" >
                                        <Box className={CLASSES.transparentBckg}>
                                            <Typography variant="h4" className={CLASSES.transparentBckgText} >Panneaux</Typography>
                                        </Box>
                                    </Paper>
                                </Link>

                            </Grid>
                            <Grid item xs={12} sm={6} lg={3}  style={{ overflow: "hidden"}}>
                                <Link to={"/products/type/batteries"}>
                                    <Paper style={{ padding: "0px", backgroundImage: `url(${cloud2})`}} className="homeSection" >
                                        <Box className={CLASSES.transparentBckg}>
                                            <Typography variant="h4" className={CLASSES.transparentBckgText} >Batteries</Typography>
                                        </Box>
                                    </Paper>
                                </Link>

                            </Grid>
                            <Grid item xs={12} sm={6} lg={3}  style={{ overflow: "hidden"}}>
                                <Link to={"/products/type/convertisseurs"}>
                                    <Paper style={{ padding: "0px", backgroundImage: `url(${cloud4})`}} className="homeSection" >
                                        <Box className={CLASSES.transparentBckg}>
                                            <Typography variant="h4" className={CLASSES.transparentBckgText} >Convertisseurs</Typography>
                                        </Box>
                                    </Paper>
                                </Link>

                            </Grid>
                            
                            <Grid item xs={12} sm={6} lg={3}  style={{ overflow: "hidden"}}>
                                <Link to={"/products/type/regulateurs"}>
                                    <Paper style={{ padding: "0px", backgroundImage: `url(${cloud3})`}} className="homeSection" >
                                        <Box className={CLASSES.transparentBckg}>
                                            <Typography variant="h4" className={CLASSES.transparentBckgText} >Régulateurs</Typography>
                                        </Box>
                                    </Paper>
                                </Link>
                            </Grid>
                            <Grid item xs={12} sm={4}  style={{ overflow: "hidden"}}>
                                <Link to={"/products/type/occasion"}>
                                    <Paper style={{ padding: "0px", backgroundImage: `url(${block5})`}} className="homeSection" >
                                        <Box className={CLASSES.transparentBckg}>
                                            <Typography variant="h4" className={CLASSES.transparentBckgText} >Occasion</Typography>
                                        </Box>
                                    </Paper>
                                </Link>

                            </Grid>
                            {/* <Grid item xs={12} sm={4}  style={{ overflow: "hidden"}}>
                                <Link to={"/products/type/accessoires"}>
                                    <Paper style={{ padding: "0px", backgroundImage: `url(${block6})`}} className="homeSection" >
                                        <Box className={CLASSES.transparentBckg}>
                                            <Typography variant="h4" className={CLASSES.transparentBckgText} >Accessoires</Typography>
                                        </Box>
                                    </Paper>
                                </Link>
                            </Grid> */}

                        </Grid>

                    </Box>
                </div>
            </FullPage>
        );
    }
}

export default HomePage;