import { isEmpty } from "lodash";

export function cartReducer( state = {}, {
    type,
    payload
}){
    switch(type){

        case "UPDATE_CART":
            console.log('====================================');
            console.log("UPDATE CART", {payload});
            console.log('====================================');
            let cartList = payload.list
            let key = `MJBSALE_cart_${payload.userID}`
            localStorage[key] = JSON.stringify(cartList)

            return cartList

        case "SET_CART": 
            return payload

        case "DELETE_FROM_CART": 
            console.log('====================================');
            console.log("DELETE FROM CART", {payload});
            console.log('====================================');
            let items = Object.assign({}, state)
            delete items[payload.productID] // old
            delete items[payload.accountID][payload.productID]

            if(isEmpty(items[payload.accountID])){
                delete items[payload.accountID]
            }
            let key2 = `MJBSALE_cart_${payload.userID}`
            localStorage[key2] = JSON.stringify(items)

            return items
            
        case "CLEAN_CART": 
            let key3 = `MJBSALE_cart_${payload.userID}`
            localStorage[key3] = JSON.stringify({})

            return {}

        default: 
            return state
    }
}
