export function stockRequest(state = {
    fetching:false,
    fetched: false,
    error:null,
},{
    type,
    payload
}){
    
    switch(type){
        case "FETCH_STOCKS_START":           
            return {
                ...state, 
                fetching:true,
            }        
        case "FETCH_STOCKS_FULFILLED":           
            return {
                ...state,
                fetching:false, 
                fetched:true, 
                error: null
            }    
        case "FETCH_STOCKS_ERROR":
            return {
                ...state,
                fetching:false, 
                error:payload
            }
        default:
            return state;
    }
    
}