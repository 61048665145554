import axios from "axios";
import setAuthToken from "../../utils/setAuthToken";
import jwt from "jsonwebtoken";

export function register(data) {
	return async dispatch => {
		try {
			dispatch({ type: "CREATE_USER_START", payload: "" });
			const res = await axios.post("/auth/register", data);
			console.log({ res });
			// const token = res.data.token

			if (!res.data.error) {
				dispatch({ type: "CREATE_USER_FULFILLED", payload: "" });
			}
			dispatch({ type: "CREATE_USER_ERROR", payload: res.data.error });
		} catch (err) {
			console.log("[ERROR] register ", err);
			dispatch({ type: "CREATE_USER_ERROR", payload: err });
		}
	};
}
export function activateClient(data) {
	return async dispatch => {
		try {
			dispatch({ type: "ACTIVATE_CLIENT_START", payload: "" });
			const res = await axios.post("/auth/client/activate", data);
			console.log({ res });

			if (!res.data.error) {
				dispatch({ type: "ACTIVATE_CLIENT_FULFILLED", payload: "" });
			}
			dispatch({ type: "ACTIVATE_CLIENT_ERROR", payload: res.data.error });
		} catch (err) {
			console.log("[ERROR] activate ", err);
			dispatch({ type: "ACTIVATE_CLIENT_ERROR", payload: err });
		}
	};
}
export function logUser(data) {
	return async dispatch => {
		try {
			// dispatch({ type: "LOG_USER_START", payload: "" });
			const res = await axios.post("/auth/login", data);
			console.log('[INFO],authAction,logUser,  info',{ res });

			const token = res.data.token; // PUBLIC TOKEN created with userID, email, private_token ...

			if (token) {
				localStorage.setItem("jwtToken_sale_app", token);
				setAuthToken(token); // set token in axios header requests

				let currentUser = jwt.decode(token);
				delete currentUser.API_token; // GENERATED BY API ON LOGIN

				console.log("====================================");
				console.log({ currentUser });
				console.log("====================================");

				dispatch(setCurrentUser(currentUser));
				// dispatch({ type: "GET_USER_INFO", payload: res.data.user });				
				
				return "ok";
			}			
			console.log('[ERROR],authAction, logUser,  error',res.data.error);
			return res;
		} catch (err) {
			console.log("[ERROR] login action ", err);
			return err;
		}
	};
}

export function setCurrentUser(user) {
	return async dispatch => {
		dispatch({ 
			type: "SET_CURRENT_USER", 
			payload: user 
		});
		dispatch(getUserInfo())
	}
}


export function getUserInfo() {
	return async dispatch => {
		try {
			let res = await axios.get("/users/userInfo");
			console.log({ res });

			if (res.data.error) {
				console.log("ERROR", res.data.error);
				dispatch(checkError(res.data.error))
				
			} else {
				dispatch({ type: "GET_USER_INFO", payload: res.data });
				// dispatch({ type: "USER_INFO_FULFILLED", payload: "" })
			}
		} catch (error) {
			console.log("ERROR", error);
		}
	};
}

export function logout() {
	return dispatch => {
		localStorage.removeItem("jwtToken_sale_app");
		setAuthToken(false);
		dispatch({
			type: "USER_LOGOUT",
			payload: {}
		})

	};
}
export function checkError(error) {
	return dispatch => {
		console.log('====================================');
		console.log({error});
		console.log('====================================');
		if( !error ){
			return
		}
		switch(error.statusCode){
			case 403: 
				dispatch(logout())
				break
			default:
				return

		}
	}
}

export function checkErrorData(res, type, dispatch) {
	let noError = false
	
	
	if( !res.data ){
		dispatch({
			type,
			payload: "pas de réponse"
		})
	} else if (res.data.error){

		if (res.data.error.statusCode === 403){
			dispatch(logout())
		} else {
			dispatch({
				type,
				payload: res.data.error.error
			})
		}
		
	} else {
		noError = true
	}
	return noError
	
}


export function resetPassword(email) {
	return async dispatch => {
		try {
			let res = await axios.post("/auth/reset/password",{email});
			console.log({ res });

			// if (res.data.error) {
			// 	console.log("ERROR", res.data.error);
			// 	// dispatch(checkError(res.data.error))
				
			// } else {
			// 	dispatch({ type: "GET_USER_INFO", payload: res.data.user_info });
			// 	// dispatch({ type: "USER_INFO_FULFILLED", payload: "" })
			// }
		} catch (error) {
			console.log("ERROR", error);
		}
	};
}
export function resetPasswordUpdate(data) {
	return async dispatch => {
		try {
			let res = await axios.post("/auth/reset/password/update",{data});
			console.log({ res });

			// if (res.data.error) {
			// 	console.log("ERROR", res.data.error);
			// 	// dispatch(checkError(res.data.error))
				
			// } else {
			// 	dispatch({ type: "GET_USER_INFO", payload: res.data.user_info });
			// 	// dispatch({ type: "USER_INFO_FULFILLED", payload: "" })
			// }
		} catch (error) {
			console.log("ERROR", error);
		}
	};
}

