import React, { Component, Fragment } from 'react';
import { Box, Typography, Divider, Table, TableBody, TableRow, TableCell, Paper, TableFooter } from '@material-ui/core';
import Currency from '../common/Currency';

import OrderStepper from './Orders/OrderStepper';

const ORDERSTATUS = {
    "WAITING":{text: "En attente de validation", progress: 0},
    "VALIDATE":{text: "En cours de préparation", progress: 1},
    "SHIPPED":{text: "Commande Expédiée", progress: 2},
    "DELIVERED":{text: "Commande Livrée", progress: 3},
}

class UserOrderList extends Component {
    state = {  }
    componentDidMount = () => {
        this.props.fetchUserOrders()
    }
    render() {
        const { PRODUCTS, ORDERS } = this.props;
        return (
            <Box>
                <Typography variant="h5">Mes Commandes</Typography>
                <Divider />
                <Box p={2} />
                {/* WAIT FOR PRODUCTS */}
                {Object.values(ORDERS).map(order => ( 
                    <Box p={1}>
                    <Paper elevation={0}>
                        <Box p={1}>
                            <Typography variant="body1">Commande du {new Date(order.date).toLocaleString("fr-FR")}</Typography>
                            <Typography variant="button">N° {order.order_id}</Typography><br /> 
                        </Box>

                        <Box>
                           <OrderStepper status={ORDERSTATUS[order.order_status].progress} ORDERSTATUS={ORDERSTATUS} />
                        </Box>

                        <Box p={1} >
                            <Table>
                                <TableBody>
                                    {order.products.map( prod => (
                                        <Fragment>

                                        <TableRow>
                                            <TableCell>
                                                <Box p={1}  style={{position: "relative"}}>
                                                    <img className="img-miniature" style={{ maxHeight: "200%" }} src={PRODUCTS[prod.product_id] && PRODUCTS[prod.product_id].mainPhoto} />
                                                </Box>
                                            </TableCell>
                                            <TableCell>{prod.quantity} x {PRODUCTS[prod.product_id] && PRODUCTS[prod.product_id].name}</TableCell>
                                        </TableRow>
                                        
                                        </Fragment>

                                    ))}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell style={{textAlign: "right"}}>
                                            <Typography variant="button">
                                                Total : <Currency value={order.total_price} />
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </Box>
                    </Paper>
                    </Box>
                     
                    ))
                }
            </Box>
        );
    }
}

export default UserOrderList;