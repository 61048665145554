export function photoRequest(state = {
    fetching:false,
    fetched: false,
    error:null,
},{
    type,
    payload
}){
    
    switch(type){
        case "PRODUCT_PHOTOS_START":           
            return {
                ...state, 
                fetching:true,
            }        
        case "PRODUCT_PHOTOS_FULFILLED":           
            return {
                ...state,
                fetching:false, 
                fetched:true, 
                error: null
            }    
        case "PRODUCT_PHOTOS_ERROR":
            return {
                ...state,
                fetching:false, 
                error:payload
            }
        default:
            return state;
    }
    
}