import React, { Component } from 'react';
import { PropTypes } from "prop-types";
import { connect } from 'react-redux';

export default function(ComposedComponent) {

    class MustNotBeAuth extends Component {
        state = {  }
        componentWillMount() {
            if(this.props.isAuthenticated) {
                this.props.history.push('/')
            }
        }
        componentWillUpdate(nextProps) {
            if(nextProps.isAuthenticated) {
                this.props.history.push('/')
            }
        }
        render() {
            return (
                <ComposedComponent {...this.props} />
            );
        }
    }
    
    MustNotBeAuth.propTypes = {
        isAuthenticated : PropTypes.bool.isRequired
    }

    function mapStateToProps(state){
        return {
            isAuthenticated : state.auth.isAuthenticated
        }
    }
    return connect(mapStateToProps)(MustNotBeAuth);
}