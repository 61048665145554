import React, { Component } from 'react';
import CurrencyFormat from 'react-currency-format';

class Currency extends Component {
    state = {  }
    render() {
        return (
            <CurrencyFormat 
                value={this.props.value} 
                displayType={'text'} 
                thousandSeparator={" "} 
                suffix={this.props.suffix || " FCFA"}
            />
        );
    }
}

export default Currency;