import React, { Component } from 'react';

import { isEmpty, size } from "lodash";

import CardProduct from './CardProduct';
import { 
    Grid, 
    TablePagination,
    Box,
} from '@material-ui/core';
import Pagination from '../../container/MUI/Pagination';

import Spinner from '../common/Spinner';
import FilterProducts from './FilterProducts';

class GridProducts extends Component {
    state = { 
        page: 0,
        rowsPerPage: 40,
        openForm: false,

        search: "",
        brand: "",
        pan_cell: "",
        bat_type: "",
        reg_type: "",
        reg_affichage: "",
        conv_type: "",
        conv_signal: "",
        cab_type: "",

    }

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ page: 0, rowsPerPage: event.target.value });
    };

    componentWillUpdate(nextProps) {
        console.log('====================================');
        console.log(nextProps.type, this.props.type);
        console.log('====================================');
        if (nextProps.type !== this.props.type) {
            this.setState({ 
                page: 0,
                rowsPerPage: 40,
                pan_cell: "",
                bat_type: "",
                reg_type: "",
                reg_affichage: "",
                conv_signal: "",
                conv_type: "",
                cab_type: "",
            })
        }
    }
    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        })
    }
    filterProducts = (list , search) => {
        
        const {PRODUCTS, STOCKS} = this.props;
        
        if(!isEmpty(list) ){
            list = list.filter(prodID => STOCKS[prodID] > 0)

            if(!isEmpty(search)){
                
                let searching = search.toLowerCase()
                
                list = list.filter( ID => {
                    let product = PRODUCTS[ID]
                    let name = product.name ? product.name.toLowerCase() : ""
                    let type = product.product_type ? product.product_type.toLowerCase() : ""
                    let marque = product.marque ? product.marque.toLowerCase() : ""
                    let product_title = product.product_title ? product.product_title.toLowerCase() : ""

                    return(
                        name.includes(searching) ||
                        product_title.includes(searching) ||
                        type.includes(searching) ||
                        marque.includes(searching)
                    )
                })
                console.log('====================================');
                console.log("list 2:", list.length);
                console.log('====================================');
            }

        }
        return list
    }

    filterBrand = (list, search) => {
        const {PRODUCTS} = this.props;

        if(!isEmpty(list) && !isEmpty(search)){
            let searching = search.toLowerCase()
            
            list = list.filter( ID => {
                let brand = PRODUCTS[ID].marque.toLowerCase()
                return (
                    brand.includes(searching)
                )
            })
        }
        return list
    }
    
    filterType = (list, field, search) => {
        const {PRODUCTS} = this.props;

        if(!isEmpty(list) && !isEmpty(search)){
            let searching = search.toLowerCase()
            
            list = list.filter( ID => {
                let value = PRODUCTS[ID][field] ? PRODUCTS[ID][field].toLowerCase() : ""
                return (
                    value === searching
                )
            })

        }
        return list
    }

    filterConverter = (list, type) => {
        const {PRODUCTS} = this.props;

        if(!isEmpty(list) && !isEmpty(type)){
            
            list = list.filter( ID => PRODUCTS[ID].product_type === type )

            console.log('====================================');
            console.log("filterConverter:", type, list.length);
            console.log('====================================');
        }

        return list
    }
    
    filterAll = () => {
        console.log('====================================');
        console.log("FILTER LIST START");
        console.log('====================================');

        const { search, brand, conv_type } = this.state;
        
        const { CAT, type } = this.props;
        let list = CAT[type]
        let fields = ["pan_cell", "bat_type", "reg_type", "reg_affichage", "conv_signal", "cab_type"]

        if(type === "convertisseurs"){
            let convCat = Object.keys(CAT).filter( catName => catName.startsWith("convertisseurs"))
            
            let newList = []
            convCat.forEach( cat => newList = [...newList, ...CAT[cat]])
            list = newList 
        }

        list = this.filterProducts(list, search)
        list = this.filterBrand(list, brand)
        list = this.filterConverter(list, conv_type)
        
        fields.forEach( field => {
            let searchingValue = this.state[field]
            if( !isEmpty(searchingValue) ){
                list = this.filterType(list, field, searchingValue)
            }
        })

        return list
    }

    render() {
        const { PRODUCTS, CAT, type } = this.props;
        const { page, rowsPerPage } = this.state;

        console.log('====================================');
        console.log("STATE", this.state);
        console.log("PROPS", this.props);
        console.log("PRODUCTS", PRODUCTS);
        console.log('====================================');
        let prodList = this.filterAll()
        return (
            <div >
                <FilterProducts 
                    handleChange={this.handleChange}
                    {...this.state}
                    type={type}
                />
                <Box p={1} />

                {/* FILTER TYPES HERE */}
                <Grid container spacing={2}>
                    {this.props.STATE.products.fetching && <Spinner noOverlay/>}

                    {!isEmpty(PRODUCTS) && !isEmpty(CAT[type]) &&

                        prodList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map( prodID => (
                            // <Grid item xs={12} sm={6} md={3}>
                            <Grid item xs={12} sm={4} md={3} lg={2}>
                                <CardProduct product={PRODUCTS[prodID]} {...this.props} />
                            </Grid>
                        ))
                    }
                </Grid>

                {size(prodList) > 40 &&
                    <div style={{textAlign: "center"}}>
                        <TablePagination
                            rowsPerPageOptions={[40, 60]}
                            count={size(prodList)}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                native: true,
                            }}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            ActionsComponent={Pagination}
                            labelRowsPerPage="Produits par page"
                        />
                    </div>
                }
            </div>
        );
    }
}

export default GridProducts ;