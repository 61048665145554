export function formatList(list, field = "id"){
    let newList = {}
    if (list) {
        list = list.reverse()
        list.map( item => {
            newList[item[field]] = item
        })
    }
    return newList
}
export function formatListProduct(list, field = "id"){
    let newList = {}
    let catList = {}
    if (list) {
        list = list.reverse()
        list.map( item => {
            if(!catList[item.product_type]){
                catList[item.product_type] = []
            }
            catList[item.product_type].push(item[field])
            newList[item[field]] = item
        })
    }
    console.log('====================================');
    console.log({newList});
    console.log('====================================');
    return {newList, catList}
}
export function orderByAsc(list, field = "name"){
    list.sort( (a, b) => {
        if (a[field] > b[field]) return -1;
        if (b[field] > a[field]) return 1;

        return 0;
    })
    return list
}


export function capitalize(list, field) {
    let newList = list.map( item => {
        let words = item[field].split(" ")
        words = words.map( word => word && word[0].toUpperCase() + word.slice(1).toLowerCase())
        item[field] = words.join(" ")
        return item
    })
    return newList
}
export function capitalizeWord(name) {
    let words = name.split(" ")
    words = words.map( word => word && word[0].toUpperCase() + word.slice(1).toLowerCase())
    name = words.join(" ")
        
    return name
}