import React, { Component, Fragment } from 'react';
import { Box, Typography, Paper } from '@material-ui/core';
import Currency from '../../common/Currency';
import Skeleton from '@material-ui/lab/Skeleton'

export class TopBlock extends Component {
    state = {  }
    render() {
        const {product, title} = this.props;
        return (
            <Fragment>
                <Box p={1}>
                    <Typography variant="h5">{title}</Typography>
                    <Typography variant="button">{product.marque}</Typography>
                    <Box>
                        <Typography variant="h6"><Currency value={product.prix_venteTTC} /></Typography>
                    </Box>
                </Box>
                <Box p={1}>
                </Box>   
            </Fragment>
        );
    }
}

export class TopBlockEmpty extends Component {
    state = {  }
    render() {
        return (
            <Box>
                
                
                
                <Box p={1}>
                    <Skeleton variant="rect" style={{ width: "70%"}} height={30} animation="wave" />
                </Box>
                <Box p={1}>
                    <Skeleton variant="rect" style={{ width: "50%"}} height={80} animation="wave" />
                </Box>
                <Box p={1}>
                    <Skeleton variant="rect" style={{ width: "60%"}} height={40} animation="wave" />
                </Box>
                <Box p={1}>
                    <Skeleton variant="rect" style={{ width: "80%"}} height={180} animation="wave" />
                </Box>
            </Box>
        );
    }
}
