import React, { Component, Fragment } from 'react';
import { isEmpty } from "lodash"

import { 
    Box, 
    TextField, 
    Grid,
    FormHelperText,
    FormControl,
    InputLabel,
    Select,
    OutlinedInput, 
    FormLabel,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Radio,
    RadioGroup,

} from '@material-ui/core';

import PhotoUpload from '../../common/PhotoUpload';

const FIELDS = [
    {name: "product_title", rows: 1, label: "Titre", required: true },
    {name: "product_desc", rows: 6, label: "Description", required: true },
]
class FormProduct extends Component {
    state = {
        sending: false,
        photos:{},
        
    }

    setPhotos = (list) => {
        console.log("[INFO] SETPHOTOS", list);
        
        this.setState({
            ...this.state,
            product:{
                ...this.state.product,
                photos:list
            }
        })
    }

    render() {
        console.log('====================================');
        console.log(this.props);
        console.log('====================================');
        const prodForm = (
            <Grid container spacing={2} >
                
                {/* <Grid xs={12} sm={6}>
                    <Box p={1}>
                        <PhotoUpload label="Ajouter une Photo" photoList={this.setPhotos} photos={this.state.product.photos === null ? [] : this.state.product.photos }/>
                    </Box>
                </Grid> */}

                <Grid item xs={12} sm={6}>
                    {FIELDS.map( field => (
                        <Grid item xs={12}>
                            <TextField
                                style={{ width:"100%" }}
                                required={field.required}
                                margin="normal"
                                variant="outlined" 
                                multiline 
                                rows={field.rows}

                                onChange={this.props.handleChange}
                                
                                id={field.name} 
                                value={this.props.product[field.name]} 
                                name={field.name} 
                                label={field.label} 
                                placeholder={field.label} 
                                error={!!this.props.errors[field.name]} 
                                helperText={this.props.errors[field.name]} 
                            />
                        </Grid>
                    ))         
                    }
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Grid item xs={12}>
                        <TextField
                            style={{ width:"100%" }}
                            disabled
                            margin="normal"
                            variant="outlined" 
                            label="Nom" 
                            placeholder="Nom" 
                            value={this.props.oldProduct.name}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            style={{ width:"100%" }}
                            disabled
                            margin="normal"
                            variant="outlined" 
                            label="Prix de Vente HT" 
                            placeholder="Prix de Vente HT" 
                            value={this.props.oldProduct.prix_venteHT}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            style={{ width:"100%" }}
                            disabled
                            margin="normal"
                            variant="outlined" 
                            label="Prix de Vente TTC" 
                            placeholder="Prix de Vente TTC" 
                            value={this.props.oldProduct.prix_venteTTC}
                        />
                    </Grid>
                </Grid>
                
            </Grid>
        )

        
        return (
            <Box p={1}>
                {prodForm}

            </Box>
        );
    }
}

export default FormProduct;