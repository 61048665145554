import React from 'react';

import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';

import COUNTRIES from '../../../utils/countries'
import DatePicker from '../../common/DatePicker';

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { Box, Button, Typography, Divider } from '@material-ui/core';

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        width: '100%',
    },
    root: {
        display: 'flex',
    },
    formControl: {
        width: "100%",
    },
    group: {
        flexDirection: 'row',
    },
    selectOption: {
        marginTop: theme.spacing(1),
    },
});

// class UpdatePassword extends Component {

// }
class UpdateUserForm extends React.Component {
    state = {        
        user_address: this.props.USER.user_address || "",
        user_address2: this.props.USER.user_address2 || "",
        user_zipcode: this.props.USER.user_zipcode || "",
        user_city: this.props.USER.user_city || "",
        user_country: this.props.USER.user_country || "",
        birthdate: this.props.USER.birthdate ? new Date(this.props.USER.birthdate) : new Date("01/01/86"),
        gender: this.props.USER.gender || "",
        phone: this.props.USER.phone || "",

        errors:{
            user_address:"",
            user_address2:"",
            user_city:"",
            user_zipcode:"",
            user_country:"",
            birthdate:"",
            gender:"",
            phone:"",
        },
    }
    
    handleChange = (e) => {
        let field = e.target.name
        let value = e.target.value
                
        this.setState({
            [field]: value
        })
        
    }
    handleChangeDate = (date) => {
        console.log('====================================');
        console.log("CHANGE DATE",{date});
        console.log('====================================');
        
        this.setState({
            birthdate: date
            
        })
    }
    handleChangePhone = (phone) => {
        console.log('====================================');
        console.log("CHANGE PHONE",{phone});
        console.log('====================================');
        this.setState({
            phone
            
        })
    }
    validateInfos = () => {
        const required = [
            "user_address",
            "user_city",
            "user_zipcode",
            "user_country",
            "birthdate",
            "gender",
            "phone",
        ]

        let errors = {}
        let noErrors = true

        required.map(key => {
            let value = this.state[key]
            if((key === "phone" && !isValidPhoneNumber(value)) ){
                errors[key] = "Le format n'est pas valide"
                noErrors = false

            } else if( !value || value === "") {
                errors[key] = "Veuillez remplir ce champ"
                noErrors = false

            } else {
                errors[key] = ""
            }

        })

        this.setState({
            errors
        })

        return noErrors
    }

    handleSubmit = () => {
        if( this.validateInfos()){
            let data = Object.assign(this.props.USER, this.state)
    
            delete data.errors
            this.props.updateUser(data)
        } else {
            this.props.notif("error", "Le formulaire contient des erreurs")
        }
    }
    render() {
        console.log("USERFORM PROPS", this.state)
        const { classes } = this.props;

        return (
            
            <Box>    
                
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <FormControl required component="fieldset" error={!!this.state.errors.gender}>
                            <FormLabel component="legend">Genre</FormLabel>
                            <RadioGroup
                                className={classes.group}
                                onChange={this.handleChange}
                                required
                                id="gender"
                                error={!!this.state.errors.gender}
                                helperText={this.state.errors.gender}
                                value={this.state.gender}
                                name="gender"
                                label="Genre"
                            >
                                <FormControlLabel value="female" control={<Radio />} label="Femme" />
                                <FormControlLabel value="male" control={<Radio />} label="Homme" />
                                <FormControlLabel value="other" control={<Radio />} label="Autre" />
                            </RadioGroup>
                            <FormHelperText>{this.state.errors.gender}</FormHelperText>
                        </FormControl>
                    </Grid>
                    
                    <Grid item xs={12} sm={6}>
                        <FormControl required  error={!!this.state.errors.phone} className={classes.formControl}>
                        <FormLabel >Numéro de téléphone</FormLabel>
                            <PhoneInput
                                name="phone"
                                placeholder=""
                                value={ this.state.phone }
                                onChange={this.handleChangePhone} 
                                className={classes.textField}/>
                                <FormHelperText>{this.state.errors.phone}</FormHelperText>
                        </FormControl>
                    </Grid>
                    
                    <Grid item xs={12} sm={6}>
                        <FormControl required  error={!!this.state.errors.birthdate} className={classes.formControl}>
                            <DatePicker  
                                id="birthdate" 
                                label="Date de Naissance" 
                                name="birthdate" 
                                value={this.state.birthdate} 
                                handleChange={this.handleChangeDate} 
                                error={!!this.state.errors.birthdate}
                                />
                                <FormHelperText >{this.state.errors.birthdate}</FormHelperText>
                        </FormControl>
                    </Grid>
                    
                    <Grid item xs={12}>
                        <TextField
                            onChange={this.handleChange}
                            required
                            id="address1"
                            error={!!this.state.errors.user_address}
                            helperText={this.state.errors.user_address}
                            value={this.state.user_address}
                            name="user_address"
                            label="Adresse"
                            className={classes.textField}
                            margin="normal"
                                                            
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            onChange={this.handleChange}
                            id="address2"
                            error={!!this.state.errors.user_address2}
                            helperText={this.state.errors.user_address2}
                            value={this.state.user_address2}
                            name="user_address2"
                            label="Complément d'Adresse"
                            className={classes.textField}
                            margin="normal"
                            
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            onChange={this.handleChange}
                            required
                            id="zip"
                            error={!!this.state.errors.user_zipcode}
                            helperText={this.state.errors.user_zipcode}
                            value={this.state.user_zipcode}
                            name="user_zipcode"
                            label="Code Postal"
                            className={classes.textField}
                            margin="normal"
                            
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            onChange={this.handleChange}
                            required
                            id="city"
                            error={!!this.state.errors.user_city}
                            helperText={this.state.errors.user_city}
                            value={this.state.user_city}
                            name="user_city"
                            label="Ville"
                            className={classes.textField}
                            margin="normal"
                            
                        />
                    </Grid>
                    
                    <Grid item xs={12} sm={6}>
                        <FormControl required  className={classes.textField} error={!!this.state.errors.user_country}>
                            <InputLabel htmlFor="user_country">Pays</InputLabel>
                            <Select
                                native
                                onChange={this.handleChange}
                                required
                                error={!!this.state.errors.user_country}
                                value={this.state.user_country}
                                name="user_country"
                                id="user_country"
                                // input={<OutlinedInput labelWidth={120} name="user_country" id="user_country" />}
                            >
                                <option value="" />
                                { Object.values(COUNTRIES).map( country  => (
                                    <option value={country} key={country} className={classes.selectOption}>{country}</option>
                                )) }
                            </Select>
                            <FormHelperText>{this.state.errors.user_country}</FormHelperText>
                        </FormControl>
                        
                    </Grid>
                    
                
                </Grid>
                <Box style={{textAlign: "right"}}>
                    <Button variant="contained" 
                        onClick={this.handleSubmit} 
                        color="secondary"
                        title="Sauvegarder mes infos" >Sauvegarder</Button>
                </Box>
            </Box>
        );
    }
}

export default withStyles(styles)(UpdateUserForm)

