import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './index.css';
import './App.css';

import thunk from 'redux-thunk';
import promise from "redux-promise-middleware";

import { Provider } from 'react-redux'
import { applyMiddleware, createStore, compose } from 'redux';
import { rootReducer, initialState } from './store/reducers/rootReducer';

import setAuthToken from './utils/setAuthToken';
import { setCurrentUser } from './store/actions/authAction';
import { restoreCart } from './store/actions/cartAction'
import { restoreAccounts } from './store/actions/countryAction'

import jwt from 'jsonwebtoken';

const store = createStore(
    rootReducer,
    initialState,
    compose(
      applyMiddleware(promise(), thunk),
      window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f =>f // comment on prod
    )
);

if(localStorage.jwtToken_sale_app){
    setAuthToken(localStorage.jwtToken_sale_app, jwt.decode(localStorage.jwtToken_sale_app).id)
    store.dispatch(setCurrentUser(jwt.decode(localStorage.jwtToken_sale_app) ))

    let key = `MJBSALE_cart_${jwt.decode(localStorage.jwtToken_sale_app).id}`
    if(localStorage[key]){
        store.dispatch(restoreCart( JSON.parse(localStorage[key])))
    }

}
console.log('====================================');
console.log("localStorage", localStorage.MJBSALE_accounts);
console.log('====================================');
if(localStorage.MJBSALE_accounts){

    store.dispatch(restoreAccounts( JSON.parse(localStorage.MJBSALE_accounts)))
}

ReactDOM.render(
    <Provider store={store} >
        <App />
    </Provider>
    , 
    document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
