
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
  },
}));

class SwipeableTabs extends Component {
    state = {
        value: 0,
        index: 0
    }
    handleChange = (e, value) => {
        this.setState({
            value
        })
    };
    handleChangeIndex = (index) => {
        this.setState({
            index
        })
    };

    render() {
        const {value, index} = this.state;
        return (
            <div >
            {/* <AppBar position="static" color="default"> */}
              <Tabs
                value={value}
                onChange={this.handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
              {this.props.labels.map( (label, i) => (
                  <Tab label={label} {...a11yProps(i)} />
              ))}
              </Tabs>
            {/* </AppBar> */}
            <SwipeableViews
              axis={'x'}
              index={value}
              onChangeIndex={this.handleChangeIndex}
            >
              {this.props.children}
                  
              </SwipeableViews>
          </div>
        );
    }
}

export default SwipeableTabs;
