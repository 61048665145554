import axios from 'axios'
import  { checkError } from './authAction'

export function newUser(data) {
    return async function(dispatch){
        try{
            dispatch({ type: "CREATE_USER_START", payload: "" })

            console.log("new User START", {data})
            const res = await axios.post('/users/new',{data})
            // let response = await res.json();
            console.log({res});
            // const token = res.data.token
            
            if (!res.data.error){
                // fetched true
                dispatch({
                    type: "NEW_USER",
                    payload: res.data
                })
                dispatch({ type: "CREATE_USER_FULFILLED", payload: "" })

            } else {
				console.log("ERROR", res.data.error);
                dispatch(checkError(res.data.error))
                dispatch({
                    type: "CREATE_USER_ERROR",
                    payload: res.data.error.error
                })

				
			}
        } catch(err) {
            console.log("[ERROR] new user ", err);
            dispatch({
                type: "CREATE_USER_ERROR",
                payload: err
            })
        }
    }
}
export function fetchUsers() {
    return async function(dispatch){
        try{
            //dispatch FETCH_USER_START
            console.log("fetch User START")
            const res = await axios.get('/users/all')
            console.log({res});
            
            if (!res.data.error){
                dispatch({
                    type: "GET_USERS",
                    payload: formatList(res.data, "user_id")
                })
                //dispatch FETCH_USER_FULFILLED
            } else {
				console.log("ERROR", res.data.error);
				dispatch(checkError(res.data.error))
				//dispatch FETCH_USER_ERROR
			}
        } catch(err) {
            console.log("[ERROR] get users list ", err);
        }
    }
}
export function updateUser(data) {
    return async function(dispatch){
        try{
            dispatch({ type: "UPDATE_USER_ROLE_START", payload: "" })

            console.log("update User START")
            const res = await axios.post("/users/completeProfile", {data})
            console.log({res});
            
            if (!res.data.error){
                
                dispatch({
                    type: "UPDATE_USER",
                    payload: data
                })
                dispatch({ type: "UPDATE_USER_ROLE_FULFILLED", payload: "" })
            } else {
                dispatch({
                    type: "UPDATE_USER_ROLE_ERROR",
                    payload: res.data.error.error
                })
            }
        } catch(err) {
            console.log("[ERROR] update user ", err);
            dispatch({
                type: "UPDATE_USER_ROLE_ERROR",
                payload: err
                
            })
        }
    }
}

export function deleteUser(user){
    return {
        type: "DELETE",
        payload: user
        
    }
}
function formatList(list, field = "id"){
    let newList = {}
    if (list) {
        list.forEach(item => {
            delete item.password
            newList[item[field]] = item
        })
    }
    return newList
}