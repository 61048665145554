import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
  progress: {
    margin: theme.spacing(2),
  },
}));

export default function Spinner() {
  const classes = useStyles();

  return (
    <div className="text-center" style={{
        top:"40%",
        left:"40%",
        position:"absolute",
        }}>
      {/* <CircularProgress className={classes.progress} /> */}
      
      <CircularProgress className={classes.progress} color="secondary" />
    </div>
  );
}