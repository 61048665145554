import axios from 'axios';
import {isEmpty} from "lodash";

export function addInCart(product, quantity){
    return async function(dispatch, getState){   
        console.log({product, quantity});

        dispatch({ type: "ADD_IN_CART_START", payload: "" })   
        try{
            let userID =  getState().auth.user.id
            let list =  getState().cart
            let accountID = product.account_id
            let productID = product.id
            let seller = !isEmpty(list[accountID]) ? list[accountID] : {}
            let article = seller[productID]

            if(!quantity || quantity < 1 ){
                quantity = 1
            }
            console.log('====================================');
            console.log({list, productID, article});
            console.log('====================================');
            
            if( !isEmpty(article) ){
                list[accountID][productID].quantity = article.quantity + parseInt(quantity)
            } else if( !isEmpty(seller) ){
                list[accountID][productID] = {
                    product, 
                    quantity: parseInt(quantity), 
                }
            } else {
                list[accountID] = {
                    [productID] :  {
                        product, 
                        quantity: parseInt(quantity), 
                    }
                }
            }

            dispatch({
                type: "UPDATE_CART",
                payload: {list, userID}
                
            })
            dispatch({ type: "ADD_IN_CART_FULFILLED", payload: "" })
        }  catch(err) {
            console.log('====================================');
            console.log({err});
            console.log('====================================');
            dispatch({ type: "ADD_IN_CART_ERROR", payload: err })
        }
    }
}
export function refreshCart(cart_list){ //  /!\ TO FIX to add seller account /!\
    return async function(dispatch, getState){   
        dispatch({ type: "REFRESH_CART_START", payload: "" })   
        try{
            let list =  getState().cart
            let userID =  getState().auth.user.id
            cart_list.map(item => {

                let productID = item.cart_id
                let quantity = item.cart_quantity
                let unavailable = item.cart_unavailable_item
                let article =  list[productID] 
                
                if( article ){
                    list[productID].quantity = parseInt(quantity)
                    if( unavailable ){
                        list[productID].unavailable = true
                    }
                }
                    
            })
            dispatch({
                type: "UPDATE_CART",
                payload: {list, userID}
                
            })
            dispatch({ type: "REFRESH_CART_FULFILLED", payload: "" })
            
        }  catch(err) {
            dispatch({ type: "REFRESH_CART_ERROR", payload: err })

        }
    }
}

export function restoreCart(cart){
    
    return {
        type: "SET_CART",
        payload: cart
        
    }
}


export function deleteFromCart(productID, accountID){
    return function(dispatch, getState){   
        let userID =  getState().auth.user.id
        dispatch({
            type: "DELETE_FROM_CART",
            payload: {accountID, productID, userID}
            
        })
    }
}

export function cleanCart(){
    return function(dispatch, getState){  
        let userID =  getState().auth.user.id 
        
        dispatch({
            type: "UPDATE_CART",
            payload: {list: {}, userID}
            
        })
    }
    
}
