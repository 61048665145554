import React, { Component } from 'react';
import { PropTypes } from "prop-types";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

export default function(ComposedComponent) {

    class MustBeAuth extends Component {
        state = {  }
        componentWillMount() {
            if(!this.props.isAuthenticated) {
                this.props.history.push('/login')
            }
        }
        componentWillUpdate(nextProps) {
            if(!nextProps.isAuthenticated) {
                this.props.history.push('/login')
            }
        }
        render() {
            return (
                <ComposedComponent {...this.props} />
            );
        }
    }
    
    MustBeAuth.propTypes = {
        isAuthenticated : PropTypes.bool.isRequired
    }

    function mapStateToProps(state){
        return {
            isAuthenticated : state.auth.isAuthenticated
        }
    }
    return connect(mapStateToProps)(withRouter(MustBeAuth));
}