export function companyRequest(state={
    fetching:false,
    fetched: false,
    error:null,
},{
    type,
    payload
}){
    
    switch(type){
        case "CREATE_COMPANY_START":           
        case "DELETE_COMPANY_START":           
            return {
                ...state, 
                fetching:true
            }        
        case "CREATE_COMPANY_FULFILLED":           
        case "DELETE_COMPANY_FULFILLED":           
            return {
                ...state,
                fetching:false, 
                fetched:true, 
                error: null
            }    
        case "CREATE_COMPANY_ERROR":
        case "DELETE_COMPANY_ERROR":
            return {
                ...state,
                fetching:false, 
                error:payload
            }
        default:
            return state;
    }
    
}