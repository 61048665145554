import  { checkErrorData } from './authAction'
import  { formatList, formatListProduct } from './format'

import axios from 'axios';

export function completeProduct(product){
    return async function(dispatch){
        console.log("FIRED ACTION COMPLETE PRODUCT", product )
        try{
            dispatch({ type: "COMPLETE_PRODUCT_START", payload: "" })

            const res = await axios.post('/products/complete', {data: product})
            console.log({res});
            
            if(checkErrorData(res, "COMPLETE_PRODUCT_ERROR", dispatch ) ) {
				dispatch({
                    type: "COMPLETE_PRODUCT",
                    payload: res.data
                })
				
				dispatch({ type: "COMPLETE_PRODUCT_FULFILLED", payload: "" })
			}
        } catch(err) {
            console.log("[ERROR] complete product", err);
            dispatch({
                type: "COMPLETE_PRODUCT_ERROR",
                payload: err
            })
        }
    }
}
                
export function fetchProducts(accounts){
    return async function(dispatch){
        console.log("FIRED ACTION FETCH PRODUCTS" )
        try{
            dispatch({ type: "FETCH_PRODUCTS_START", payload: "" })

            const res = await axios.post('/products/accounts', {accounts})
            console.log({res});
            
            if(checkErrorData(res, "FETCH_PRODUCTS_ERROR", dispatch ) ) {
                let lists = formatListProduct(res.data, "id")
				dispatch({
                    type: "GET_PRODUCTS",
                    payload: lists.newList
                })
				dispatch({
                    type: "GET_PRODUCTS_CAT",
                    payload: lists.catList
                })
				dispatch({ type: "FETCH_PRODUCTS_FULFILLED", payload: "" })
			}
        } catch(err) {
            console.log("[ERROR] get product list ", err);
            dispatch({
                type: "FETCH_PRODUCTS_ERROR",
                payload: err
                
            })
        }
    }
}
export function fetchProductsDetails(){
    return async function(dispatch){
        console.log("FIRED ACTION FETCH PRODUCTS" )
        try{
            dispatch({ type: "FETCH_PRODUCTS_DETAILS_START", payload: "" })

            const res = await axios.get('/products/details/all')
            console.log({res});
            
            if(checkErrorData(res, "FETCH_PRODUCTS_DETAILS_ERROR", dispatch ) ) {
                let lists = formatListProduct(res.data, "id")
				dispatch({
                    type: "GET_PRODUCTS_DETAILS",
                    payload: lists.newList
                })
				
				dispatch({ type: "FETCH_PRODUCTS_DETAILS_FULFILLED", payload: "" })
			}
        } catch(err) {
            console.log("[ERROR] get product list ", err);
            dispatch({
                type: "FETCH_PRODUCTS_DETAILS_ERROR",
                payload: err
                
            })
        }
    }
}


export function getAllPhotos() {
    return async function(dispatch){       
        try {
            dispatch({ type: "PRODUCT_PHOTOS_START", payload: "" })
            const res = await axios.get('/products/getAllPhotos')
            console.log('====================================');
            console.log({res});
            console.log('====================================');
            if(checkErrorData(res, "PRODUCT_PHOTOS_ERROR", dispatch ) ) {
                dispatch({ type: "SET_ALL_PHOTOS", payload: res.data.photoList })
                dispatch({ type: "PRODUCT_PHOTOS_FULFILLED", payload: "" })
            }
            
        } catch (error) {
            console.log('====================================');
            console.log({error});
            console.log('====================================');
            dispatch({ type: "PRODUCT_PHOTOS_ERROR", payload: error.message })
       
        }
        
    }
}
export function getPhotoProd(prodID,filename, main = false) {
    return async function(dispatch){        
        const res = await axios.post('/products/getPhotos', {path:filename})
        if(checkErrorData(res, "PRODUCT_PHOTOS_ERROR", dispatch ) ) {
            dispatch({ type: "SET_PHOTOS", payload: res.data })
            dispatch({ type: "PRODUCT_PHOTOS_FULFILLED", payload: "" })
        }
        if(main){
            dispatch(setPhotoProd(prodID, res.data.uri))
        } else {
            dispatch({
                type: "SET_PHOTOS",
                payload: {prodID, URI:res.data.uri}
                
            })
        }
    }
}

export function fetchProductsType(type){
    console.log("FIRED ACTION FETCH PRODUCTS" )
    return async function(dispatch){
        try{
            dispatch({ type: "FETCH_PRODUCTS_START", payload: "" })

            const res = await axios.post('/products/type', {type})
            console.log({res});
            
            if(checkErrorData(res, "FETCH_PRODUCTS_ERROR", dispatch ) ) {
				dispatch({
                    type: "GET_PRODUCTS_TYPE",
                    payload: {list: formatList(res.data, "id"), type: type.toUpperCase() }
                })
				dispatch({ type: "FETCH_PRODUCTS_FULFILLED", payload: "" })
			}
        } catch(err) {
            console.log("[ERROR] get product list ", err);
            dispatch({
                type: "FETCH_PRODUCTS_ERROR",
                payload: err
                
            })
        }
       
    }
}

export function setPhotoProd(prodID,URI){
    return {
        type: "SET_MAIN_PHOTO",
        payload: {prodID, URI}
        
    }
}
