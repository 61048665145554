import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import FullPage from './FullPage';
import { Link } from "react-router-dom";
import Basic from './Basic';
import { Box } from '@material-ui/core';

const drawerWidth = 240;

const styles = theme => ({
    root: {
        display: 'flex',
    },
    drawer: {
        zIndex: theme.zIndex.appBar - 1,
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        backgroundColor: "red",

    },
    drawerOpen: {
        position: 'sticky',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        position: 'sticky',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    content: {
        flexGrow: 1,
        width: '100%',
        padding: theme.spacing(3),
        overflow: 'auto',
    },
});

class CenteredSideBar extends React.Component {
    state = {
        open: false,
    };

    handleDrawer = () => {
        this.setState({ open: !this.state.open });
    };

    render() {
        const { classes, menu, page } = this.props;

        return (
            <Basic name={page}  {...this.props} >
                <div className={classes.root}>
                    <CssBaseline />
                    
                    <Drawer
                        variant="permanent"
                        className={classNames(classes.drawer, classes.drawerOpen)}
                        classes={{
                            paper: classNames(classes.drawerOpen),
                        }}
                        open={true}

                        // className={classNames(classes.drawer, {
                        //     [classes.drawerOpen]: this.state.open,
                        //     [classes.drawerClose]: !this.state.open,
                        // })}
                        // classes={{
                        //     paper: classNames({
                        //         [classes.drawerOpen]: this.state.open,
                        //         [classes.drawerClose]: !this.state.open,
                        //     }),
                        // }}
                        // open={this.state.open}
                    >
                        <div >
                            <Box style={{textAlign: "center"}}>
                                <h4>{this.props.USER.username} {this.props.USER.lastname}</h4>

                            </Box>
                        </div>
                        <Divider />
                        <List>

                        {menu.map( link => (
                            <Link className="" to={link.to}>
                                <ListItem button key={link.to}>
                                <ListItemIcon>{link.icon}</ListItemIcon>
                                <ListItemText >
                                    {link.default}
                                </ListItemText>
                                </ListItem>
                            </Link>
                        ))}
                        </List>
                        
                    </Drawer>
                    <main className={classes.content + " min-height"}>
                        {this.props.children}
                    </main>
                </div>
            </Basic>
        
        );
    }
}

CenteredSideBar.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(CenteredSideBar);