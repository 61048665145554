import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';


export const StrippedTable = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);