import React from 'react'
import Header from './Header';
import Footer from './Footer';
import Page from './Page';
import CssBaseline from '@material-ui/core/CssBaseline';
import GeneralContainer from './GeneralContainer';


class Basic extends React.Component{
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render(){
        const { page } = this.props;
        
        // console.log('===========BASIC LAYOUT PROPS====================');
        // console.log(this.props);
        // console.log('====================================');
        return(
            <GeneralContainer>
                <CssBaseline />
                <Header currentPage={page}/>
                    <Page>
                        {this.props.children}
                    </Page>
                <Footer {...this.props} />
            </GeneralContainer>
        )
    }
}

export default Basic;
