import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import FullPage from './FullPage';
import { Link } from "react-router-dom";

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    // zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    zIndex: theme.zIndex.appBar - 1,
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    position: 'sticky',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    position: 'sticky',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    width: '100%',
    padding: theme.spacing(3),
    overflow: 'auto',
  },
});

class MiniDrawer extends React.Component {
  state = {
    open: false,
  };

  handleDrawer = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    const { classes, menu, page } = this.props;

    return (
        <FullPage name={page}  {...this.props} >
          <div className={classes.root}>
          <CssBaseline />
          
          <Drawer
            variant="permanent"
            className={classNames(classes.drawer, {
              [classes.drawerOpen]: this.state.open,
              [classes.drawerClose]: !this.state.open,
            })}
            classes={{
              paper: classNames({
                [classes.drawerOpen]: this.state.open,
                [classes.drawerClose]: !this.state.open,
              }),
            }}
            open={this.state.open}
          >
            {/* <div className={classes.toolbar} lgDown/> */}
            <div >
              <IconButton onClick={this.handleDrawer}>
                {this.state.open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>
            </div>
            <Divider />
            <List>

              {menu.map((link,index) => (
              <Link className="" to={link.to}>
                <ListItem button key={link.to}>
                  <ListItemIcon>{link.icon}</ListItemIcon>
                  <ListItemText >
                   
                    {link.default}
                  </ListItemText>
                </ListItem>
              </Link>
              ))}
            </List>
            
          </Drawer>
          <main className={classes.content + " min-height"}>
            {/* <div className={classes.toolbar} /> */}
            {this.props.children}
          </main>
        </div>
      </FullPage>
      
    );
  }
}

MiniDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(MiniDrawer);


// class AdminSideBar extends Component {
//     state = { 
//         active: "users",
// 		anchorEl: null,
// 		mobileMoreAnchorEl: null
// 	};

// 	handleProfileMenuOpen = event => {
// 		this.setState({ anchorEl: event.currentTarget });
// 	};

// 	handleMenuClose = () => {
// 		this.setState({ anchorEl: null });
// 		this.handleMobileMenuClose();
// 	};

// 	handleMobileMenuOpen = event => {
// 		this.setState({ mobileMoreAnchorEl: event.currentTarget });
// 	};

// 	handleMobileMenuClose = () => {
// 		this.setState({ mobileMoreAnchorEl: null });
// 	};

//     render() {
//         let links = [
// 			{ page: "home", default: "Dashboard", icon:<HomeIcon />, to: "/" },
// 			{ page: "users", default: "Utilisateurs", icon:<WbSunny />, to: "/users" },
// 			{ page: "projects", default: "Projets", icon:<BallotIcon />, to: "/projects" },
//         ];
//         const isMobileMenuOpen = Boolean(this.state.mobileMoreAnchorEl);
//         return (
//             <Drawer 
//             variant="permanent"
//             className={classNames(classes.drawer, {
//                 [classes.drawerOpen]: {isMobileMenuOpen},
//                 [classes.drawerClose]: !{isMobileMenuOpen},
//               })}
//               classes={{
//                 paper: classNames({
//                   [classes.drawerOpen]: {isMobileMenuOpen},
//                   [classes.drawerClose]: !{isMobileMenuOpen},
//                 }),
//               }}
// 				open={isMobileMenuOpen} onClose={this.handleMenuClose}>
// 				<List>

// 				{links.map((link,index) => (
// 				<Link className="" to={`/admin${link.to}`}>
// 					<ListItem button key={link.to}>
// 						<ListItemIcon>{link.icon}</ListItemIcon>
// 						<ListItemText >
// 							<FormattedMessage
// 								id={`nav.${link.page}`}
// 								defaultMessage={link.default}
// 							/>
// 						</ListItemText>
// 					</ListItem>
// 				</Link>
// 				))}
// 				</List>

// 			</Drawer>
            
//         );
//     }
// }