import  { checkErrorData } from './authAction'
import  { formatList } from './format'

import axios from 'axios';
                
export function fetchStocks(accounts){
    return async function(dispatch){
        console.log("FIRED ACTION FETCH STOCKS" )
        try{
            dispatch({ type: "FETCH_STOCKS_START", payload: "" })

            const res = await axios.post('/stocks/all', {accounts})
            console.log({res});
            
            if(checkErrorData(res, "FETCH_STOCKS_ERROR", dispatch ) ) {
                let list = Object.assign({}, res.data)
                Object.keys(res.data).forEach( prodID => {
                    if (res.data[prodID] < 1 ){
                        delete list[prodID]
                    }
                } )
				dispatch({
                    type: "GET_STOCKS",
                    payload: list
                })
                
				dispatch({ type: "FETCH_STOCKS_FULFILLED", payload: "" })
			}
        } catch(err) {
            console.log("[ERROR] get stock list ", err);
            dispatch({
                type: "FETCH_STOCKS_ERROR",
                payload: err
                
            })
        }
    }
}