import React, { Component } from 'react';

import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

class DatePicker extends Component {
    state = {
        selectedDate: ""
    }
    
    render(){
        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    // margin="normal"
                    id={this.props.id}
                    label={this.props.label}
                    format="dd/MM/yyyy"
                    value={this.props.value}
                    onChange={this.props.handleChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    error={this.props.error}
                />
            </MuiPickersUtilsProvider>                
        );
    }
}

export default DatePicker;