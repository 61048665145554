import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { updateUser } from "../../store/actions/userAction"
import { fetchUserOrders } from "../../store/actions/orderAction"
import { Grid, Typography, Box, Paper, Divider } from '@material-ui/core';
import { HomeRounded, ListAltRounded, SettingsRounded, AccountCircleOutlined } from '@material-ui/icons';

import CenteredSideBar from '../../container/layouts/CenteredSideBar';
import Notif from '../common/Notif';
import UserInfos from './UserInfos';
import UserOrderList from './UserOrderList';

class Dashboard extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	render() {
		
		return (
			<div className="min-view">
                <Typography variant="h5" style={{textAlign: "center", margin: "25px 0 40px"}}>Dashboard</Typography>
            </div>
		);
	}
}

class Profile extends Component {
    state = { 
        notif: {
            openNotif: false,
            variant:"",
            message: "",
        }
    }
    notif = (variant, message) => {
        console.log('==================NOTIF==================');
        console.log(variant, message);
        console.log('====================================');
        this.setState({
            notif:{
                openNotif: true,
                variant,
                message
            }
        })
    }
    handleCloseNotif = (event, reason) => {
        this.setState({ 
            notif: {
                openNotif: false,
                variant:"",
                message: "",
            }
        });
    };
    render() {
        const { page } = this.props.match.params;
        const { variant, message, openNotif } = this.state.notif;

        let menuList = [
			{ page: "dashboard", default: "Dashboard", icon:<HomeRounded />, to: "/profile/dashboard" },
            { page: "account", default: "Mes Informations", icon:<AccountCircleOutlined />, to: "/profile/account" },
            { page: "orders", default: "Mes Commandes", icon:<ListAltRounded />, to: "/profile/orders" },
            // { page: "settings", default: "Paramètres", icon:<SettingsRounded />, to: "/profile/settings" },
		];
        return (
            <CenteredSideBar page={"profile"} menu={menuList} {...this.props} >
                {openNotif && variant && message &&
                    <Notif open={true} {...this.state.notif} onClose={this.handleCloseNotif} />
                }
                {page === "dashboard" && <Dashboard /> }
				{page === "account" && <UserInfos {...this.props} notif={this.notif} />}
				{page === "orders" && <UserOrderList {...this.props} notif={this.notif} />}
				{/* {page === "settings" && <SellerSettings {...this.props} notif={this.notif} />} */}
                    
            </CenteredSideBar>

        );
    }
}
function mapStateToProps(state){// transform elements from store's state into props in this component
    return {
        USER: state.auth.userInfo,
        ORDERS: state.orders,
        PRODUCTS: state.products,
    }
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({
        updateUser,
        fetchUserOrders
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);