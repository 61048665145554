import React, { Component, Fragment } from 'react';
import axios from 'axios';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Spinner from '../common/Spinner';


const methods = {
	"check": "Chèque",
	"transfer": "Virement",
	"CB": "Carte bleue"
}

class ValidPayment extends Component {
    state = { 
        url: "",
        product_list:[],
        total: 0,
        stateButtonSubmitPayment: false,
     }
    componentDidMount = async () => {
        window.scrollTo(0, 0);
        let infos = await this.props.getProductList()
        // if (infos[1] < 2000){
        //     this.props.notif("error", "Le montant minimum d'investissement est de 20€")
        //     this.props.history.push("/cart")    
        // }
        this.setState({
            product_list: infos[0],
            total: infos[1],
        })
        this.handleNext()
    }
    componentWillUnmount = () => {
        clearTimeout(this.timeout)
        this.setState({ url: "" });
    }
    handleNext = async () => {
        const { CART, USER, paymentType } = this.props;
    
        let infos = await this.props.getProductList()
        let product_list = infos[0]
        let total = infos[1]
        let amount_credit = infos[2]
    
        console.log('[INFO],Checkout,USER Info,', {USER}, " or ", {CART});
        if (typeof USER === "undefined"){
            console.log('[ERROR],Checkout,USER Info, is undefined,', {USER});
            return  
        }
    
        let data = {
            buyer: {
                id: USER.user_id,
                lastName:USER.lastname,
                firstName: USER.username,
                email: USER.email,
            },
            amount: total,
            amount_credit,
            paymentType: this.props.paymentType,
            product_list
        }
        if(paymentType === "CB"){
    
            let res = await axios.post("/payments/new/PG", data)
            console.log({res});
            if(res.data.data){
                console.log(res.data.data.id);
                this.setState({
                    url : res.data.data.url
                })
                this.handlePGPayment(res.data.data.id, product_list, amount_credit)
            } else {
                this.props.notif("error","Un Problème est survenu")
            }
        }  else if (paymentType === "credit"){
            // send to new api for "free payment"
            this.handleSubmitCreditPayment(data)
        }

    };

    handleSubmitCreditPayment = async (data) => {
        let res = await axios.post("/payments/new/credit", data)
        // check res
        console.log('===============handleSubmitCreditPayment=====================');
        console.log({res});
        console.log('====================================');
        this.successPayment(data.product_list)
       
    }
    handleSubmitOtherPayment = async (e) => {
        e.preventDefault()
        const { USER } = this.props;
    
        let infos = await this.props.getProductList()
        let product_list = infos[0]
        let restToPay = infos[1]
        let amount_credit = infos[2]

        if (typeof USER === "undefined"){
            console.log('[ERROR],Checkout,USER Info, is undefined,', {USER});
            return  
        }
    
        let data = {
            buyer: {
                id: USER.user_id,
                lastName:USER.lastname,
                firstName: USER.username,
                email: USER.email,
                // country: "FR"
            },
            amount: restToPay,
            amount_credit,
            product_list,
            paymentType: this.props.paymentType
        }
        try {
            let res = await axios.post("/payments/new", data)
            console.log({res});
            if(res.error){
                this.props.notif( 'error',"Un problème est survenu: "+ res.error);
                this.timeout = setTimeout(() => {
                    this.handleClose()
                    this.setState({stateButtonSubmitPayment: false})
                }, 2000);
            } else {
                console.log("[SUCCESS] submit other payment method ",res.data);
                
                this.successPaymentCheckORTransfer(res.data)             
            }
        } catch (error) {
            this.props.notif( 'error',"Un problème est survenu: "+ error);
            this.timeout = setTimeout(() => {
                this.handleClose()
                this.setState({stateButtonSubmitPayment: false})
            }, 2000);
        }
        
    }
    handleClose = () => {
        this.setState({ url: "" });
        this.props.setPage("CHOICE");
        
    };
    successPayment = (product_list) => {
        this.props.updateProjects(product_list)
        this.props.cleanCart()

        setTimeout(() => {
            this.props.setPage("SUCCESS_PAYMENT");
        }, 1500);
    }
    successPaymentCheckORTransfer = (trID) => {
        this.props.notif("success", "Paiement enregistré, un email vous a été envoyé avec les instructions à suivre pour finaliser le paiement")
        this.props.cleanCart()

        this.props.setPage("SUCCESS_PAYMENT", trID);
  
    }
    
    render() {
        const { paymentType } = this.props;
        const {stateButtonSubmitPayment} = this.state;
        return (
                <main>
                    Valid Payment page
                    {stateButtonSubmitPayment && <Spinner /> }
                
                </main>
        );
    }
}

export default ValidPayment;

