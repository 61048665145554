import React, { Component } from 'react';
import { isEmpty } from 'lodash'
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import AddCircle from '@material-ui/icons/AddCircle';
import SwapHorizontalCircle from '@material-ui/icons/SwapHorizontalCircle';

class PhotoUpload extends Component {
  state = {
    photo:this.props.photo,
  }

  onRemoveImage= async (e, index) => {
    e.preventDefault();
    
    this.setState({
      ...this.state,
      photo:{}
    });
    this.props.setPhoto(this.state.photo)// send photo to main component
  }

  handleAddImage= async (e) =>{
    e.preventDefault();
    let list = e.target.files;
    console.log("[INFO], Upload Image list", list);

    let photo = list[0];
    if(!photo){
      return
    }
    
    if(!photo.type.startsWith("image")){
      this.props.setPhoto(null, "Le format n'est pas valide")
      this.setState({
        photo: {}
      })
      return
    }

    let reader = new FileReader();

    reader.onloadend = async (upload) => {
      console.log("[INFO]onloadend upload reader ", reader);

      this.setState({ 
        ...this.state,  
        photo:{ 
            "data_uri": upload.target.result, 
            "filename": photo.name,
            "filetype": photo.type,
          }
      })
      this.props.setPhoto(this.state.photo) // send photo to main component
    }
    await reader.readAsDataURL(photo)// ??
  }
  
  render() {
    // console.log("[STATE] FILES", this.state.photo);
    const { photo } = this.state;
    const { saved } = this.props;
    return (
      <div className="">
          <input 
            id="photo-input"
            type="file" 
            hidden
            onChange={this.handleAddImage }/>
        {isEmpty(photo) && isEmpty(saved) &&
          <Button variant="contained" color="secondary" component="label" htmlFor="photo-input" className="photoinput-new">
            <AddCircle /> {this.props.label || "Ajouter un Logo"}
          </Button>
        }
        {!isEmpty(saved) && isEmpty(photo) &&
          <div className={"photoinput photoinput-exists"} data-provides="photoinput">
            <div 
              className="photoinput-preview thumbnail" 
              data-trigger="photoinput" 
              style={{maxWidth: "250px", margin: "auto"}}
            >
              <img alt={photo.filename} src={saved} className="image"/>
            </div>

            <div>
              <IconButton color="secondary" component="label" htmlFor="photo-input"  >
                <SwapHorizontalCircle /> 
              </IconButton>
                Modifier
            </div>
          </div>
        }
        {!isEmpty(photo) &&
          <div className={"photoinput photoinput-exists"} data-provides="photoinput">
            <div 
              className="photoinput-preview thumbnail" 
              data-trigger="photoinput" 
              style={{maxWidth: "250px", margin: "auto"}}
            >
              <img alt={photo.filename} src={photo.data_uri} className="image"/>
            </div>

            <div>
              <IconButton color="secondary" component="label" htmlFor="photo-input"  >
                <SwapHorizontalCircle /> Changer
              </IconButton>
            </div>
          </div>
        }

      </div>
    )
  }
}

export default PhotoUpload
