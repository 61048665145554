import React, { Component } from 'react';
import { Box, Typography, Paper } from '@material-ui/core';
import Spinner from '../../common/Spinner';

class DescBlockEmpty extends Component {
    state = {  }
    render() {
        return (
            <Box p={1}>
                <Paper elevation={0} square>
                    <Box style={{height: "400px"}}> <Spinner noOverlay /> </Box>
                </Paper>
            </Box>
        );
    }
}

export default DescBlockEmpty;