import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

import CurrencyFormat from 'react-currency-format';
import Currency from '../common/Currency';


const styles = theme => ({
  listItem: {
    padding: `${theme.spacing(1)}px 0`,
  },
  listItemU: {
    padding: `${theme.spacing(1)}px 0`,
    background: "lightgray"
  },
  total: {
    fontWeight: '700',
  },
  title: {
    marginTop: theme.spacing(2),
  },
  bigAvatar: {
    margin: 10,
    width: 80,
    height: 80,
  },
});

function Review(props) {
	const { CART, classes } = props;
	let SELLERS = Object.keys(CART);
	return (
		<React.Fragment>
			<Typography variant="h6" gutterBottom>
				Résumé
			</Typography>
		<List disablePadding>
			{SELLERS.map(seller => 

				Object.values(CART[seller]).map( article => {

					if ( article && !article.unavailable) {
						return (
							<ListItem className={classes.listItem} key={article.product && article.product.name}>
								<ListItemAvatar>
									<Avatar alt={article.product && article.product.name} src={article.product && article.product.image} className={classes.bigAvatar}/>
								</ListItemAvatar>
								<ListItemText primary={article.product && article.product.name}/>
								<Typography variant="body2">
								<Typography variant="body2" gutterBottom>
									<CurrencyFormat value={(article.product.prix_venteTTC * article.quantity)} displayType={'text'} thousandSeparator={" "} suffix={'FCFA'} />
								</Typography >
								</Typography>
							</ListItem>
						)
					} else if ( article && article.unavailable) {
						
						return (
							<ListItem className={classes.listItemU} key={article.product && article.product.name}>
								<ListItemAvatar>
									<Avatar alt={article.product && article.product.name} src={article.product && article.product.image} className={classes.bigAvatar}/>
								</ListItemAvatar>
								<ListItemText 
									primary={article.product && article.product.name} 
									secondary={
									<React.Fragment>
										{/* <Typography
										component="span"
										variant="body2"
										className={classes.inline}
										color="textPrimary"
										>
										Indisponible
										</Typography> */}
										{" — Produit momentanément indisponible ..."}
									</React.Fragment>
									}/>
								<Typography variant="body2" gutterBottom>
									<Currency value={(article.product.prix_venteTTC * article.quantity)} />
								</Typography >
							</ListItem>
							
						)
						}
				})
				)
			}
			<br/>
			<hr/>
			<ListItem className={classes.listItem}>
				<ListItemText primary="Total" />
				<Typography variant="subtitle1" className={classes.total}>
					<Currency value={props.subTotal} />
				</Typography>
			</ListItem>
			{/* <ListItem className={classes.listItem}>
				<ListItemText primary="Avoir" />
				<Typography variant="subtitle2" className={classes.total}>
					<CurrencyFormat value={props.avoir} />
				</Typography>
			</ListItem>
			<ListItem className={classes.listItem}>
				<ListItemText primary="Total" />
				<Typography variant="subtitle1" className={classes.total}>
					<CurrencyFormat value={props.restToPay} />
				</Typography>
			</ListItem> */}
		</List>

		</React.Fragment>
	);
}

Review.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Review);