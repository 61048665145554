import { combineReducers } from 'redux';
import { userRequest } from './userRequest'
import { productRequest } from './productRequest'
import { cartRequest } from './cartRequest'
import { photoRequest } from './photoRequest'
import { countryRequest } from './countryRequest'
import { stockRequest } from './stockRequest'
import { orderRequest } from './orderRequest'
import { companyRequest } from './companyRequest'

export const initialState = {
    users:{
        fetching:false,
        fetched: false,
        error:null,
    },
    products:{
        fetching:false,
        fetched: false,
        error:null,
    },
    cart:{
        fetching:false,
        fetched: false,
        error:null,
    },
    photos:{
        fetching:false,
        fetched: false,
        error:null,
    },
    stocks:{
        fetching:false,
        fetched: false,
        error:null,
    },
    orders:{
        fetching:false,
        fetched: false,
        error:null,
    },
    countries:{
        fetching:false,
        fetched: false,
        error:null,
    },
    company:{
        fetching:false,
        fetched: false,
        error:null,
    },
    
};
export default combineReducers({
    users : userRequest,
    products : productRequest,
    cart : cartRequest,
    photos : photoRequest,
    stocks : stockRequest,
    orders : orderRequest,
    countries : countryRequest,
    company : companyRequest,

});

